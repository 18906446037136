import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: localStorage.getItem("User")
    ? JSON.parse(localStorage.getItem("User"))
    : null,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUserSuccess: (state, action) => {
      console.log(action.payload);
      const { _id, name } = action.payload;
      state.user = { _id, name };
      state.error = null;
      localStorage.setItem("User", JSON.stringify({ _id, name }));
    },

    logoutUser: (state) => {
      state.user = null;
      state.error = null;
    },
  },
});

export const {
  loginUserRequest,
  loginUserSuccess,
  loginUserFailure,
  logoutUser,
} = userSlice.actions;

export default userSlice.reducer;
