import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../Redux/Cart/CartSlice";
import { useDispatch } from "react-redux";
import { addToRecentlyViewed } from "../Redux/Cart/RecentlyViewed";

const SellerProductCard = ({ sellerProducts, colorAttributes, cartItems }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = JSON.parse(localStorage.getItem("User"));
  const [storeInactiveMsgShown, setStoreInactiveMsgShown] = useState(false);

  const handleAddToWishList = async (item) => {
    try {
      if (isLoggedIn) {
        const res = await axios.post(
          `https://rccore-backend.cstechns.com/api/buyer/AddToWishList`,
          {
            productId: item,
            userId: isLoggedIn,
          }
        );
        if (res.data.error) {
          toast.info("Item Already in Your WishList", {
            position: "top-right",
          });
        } else {
          toast.success("Item added to WishList SuccessFuly", {
            position: "top-right",
          });
          navigate("/wishlist");
        }
      } else {
        toast.error("User Must be loggedIn", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
    setSelectedColor("");
    setSelectedSize("");
  };
  const handleAddTORecentlyViewed = (item) => {
    dispatch(addToRecentlyViewed(item));
  };

  const handleAddToCart = (item) => {
    setSelectedItem(item);
    handleOpenDialog();
  };

  const handleDialogOK = async () => {
    if (selectedColor && selectedItem) {
      const product = {
        ...selectedItem,
        selectedColor: selectedColor,
        selectedSize: selectedSize,
      };

      if (isLoggedIn) {
        try {
          let updatedCartItem = null;

          // Check if the product already exists in the cart
          for (const item of cartItems.cartItems) {
            if (
              item.productId._id === product._id &&
              item.selectedColor === product.selectedColor &&
              item.userId === isLoggedIn._id &&
              item.selectedSize === product.selectedSize
            ) {
              // Send a PUT request to update the quantity of the existing item
              const response = await axios.put(
                `https://rccore-backend.cstechns.com/api/buyer/updateCartItemQuantity/${item.productId.uniqueId}/${item.selectedColor}/${isLoggedIn._id}`,
                {
                  quantity: item.quantity + 1,
                }
              );

              updatedCartItem = response.data; // Assuming the updated cart item is returned from the API

              // Display a success toast message for quantity update
              toast.info("Item Increased Successfully", {
                position: "top-right",
              });

              break;
            }
          }

          // If the product does not exist in the cart, add it
          if (!updatedCartItem) {
            const response = await axios.post(
              "https://rccore-backend.cstechns.com/api/buyer/addToCart",
              {
                productId: product,
                userId: isLoggedIn._id,
                quantity: 1,
                selectedColor: product.selectedColor,
                selectedSize: product.selectedSize,
                uniqueId: product.uniqueId,
              }
            );

            updatedCartItem = response.data; // Assuming the added cart item is returned from the API

            // Display a success toast message for item addition
            toast.success("Item Added to Cart Successfully", {
              position: "top-right",
            });
          }

          // Dispatch the updated cart item to the store
        } catch (error) {
          console.error(error);
          // Handle the error, display an error message, or perform necessary actions
        }
      }
      dispatch(addToCart(product));
      setDialogOpen(false);
      navigate("/cart");
    } else {
      toast.error("Please Select Color And Size", {
        position: "top-right",
      });
    }
  };

  const handleColorClick = (item) => {
    setSelectedColor(item);
  };
  const handleSizeClick = (item) => {
    setSelectedSize(item);
  };
  return (
    <div>
      <Box>
        <Grid container spacing={2}>
          {sellerProducts.map((item, index) => {
            // console.log(item);
            const key = item?.Colors?.flatMap((color, colorIndex) => {
              return item?.Sizes?.flatMap((size, sizeIndex) => {
                index = colorIndex * item?.Sizes.length + sizeIndex;
                return `${color}-${size}`;
              });
            });
            if (item.active === true) {
              if (item?.createdBy?.sellerStoreActive === true) {
                return (
                  <Grid item xs={12} lg={4} key={index}>
                    <Box>
                      <Card sx={{ maxWidth: 345, border: "1px solid #ddd" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={item.MainImage}
                            alt=""
                            style={{ maxHeight: "180px" }}
                            className="cardimg"
                            onClick={() => {
                              navigate(
                                `/product-details/${item.subCategory}/${item._id}`
                              );
                              handleAddTORecentlyViewed(item);
                            }}
                          />
                        </Box>
                        <CardContent>
                          <Typography
                            gutterBottom
                            sx={{ fontWeight: "600", color: "#0099cc" }}
                          >
                            {item.Title.slice(0, 25)}....
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Lizards are a widespread group of squamate
                            reptiles...
                          </Typography>
                          <Box sx={{ display: "flex", gap: "80px", mt: 1 }}>
                            <Typography
                              sx={{ fontWeight: "600", fontSize: "16px" }}
                            >
                              ₹ {item?.mrp?.[0]?.[key?.[0]]?.offerPrice}
                            </Typography>
                            <Typography
                              sx={{
                                color: "gray",
                                textDecoration: "line-through",
                              }}
                            >
                              ₹ {item?.mrp?.[0]?.[key?.[0]]?.mrp}
                            </Typography>
                          </Box>
                        </CardContent>
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            disableElevation
                            sx={{ width: "150px" }}
                            onClick={() => handleAddToWishList(item)}
                          >
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <FavoriteIcon
                                sx={{ fontSize: "24px", mr: 0.5 }}
                              />
                              WishList
                            </Box>
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            disableElevation
                            sx={{ width: "150px" }}
                            onClick={() => handleAddToCart(item)}
                          >
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <ShoppingCartIcon
                                sx={{ fontSize: "24px", mr: 0.5 }}
                              />
                              Add To Cart
                            </Box>
                          </Button>
                        </CardActions>
                      </Card>
                    </Box>
                  </Grid>
                );
              } else if (!storeInactiveMsgShown) {
                setStoreInactiveMsgShown(true); // Update the flag to prevent showing the message multiple times
                return (
                  <Grid item xs={12} key="store-inactive-msg">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70vh",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          border: "1px solid #ddd",
                          textAlign: "center",
                          background: "#eee",
                          p: 2,
                          width: "300px",
                          mt: 3,
                        }}
                      >
                        Seller Store is In-Active
                      </Typography>
                    </Box>
                  </Grid>
                );
              }
            }
          })}
        </Grid>
        {sellerProducts.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                border: "1px solid #ddd",
                textAlign: "center",
                background: "#eee",
                p: 2,
                width: "300px",
                mt: 3,
              }}
            >
              No Products are available
            </Typography>
          </Box>
        )}
      </Box>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Select Color and Size</DialogTitle>
        <DialogContent>
          <Typography>Color:</Typography>
          <Box sx={{ display: "flex", gap: "20px" }}>
            {selectedItem?.Colors.map((item, index) => {
              const color = colorAttributes.find(
                (color) => color.name === item
              );
              if (color) {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border:
                        selectedColor === color?.name
                          ? "2px solid #000"
                          : "2px solid #ddd",
                      borderRadius: "50%",
                      padding: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleColorClick(color.name)}
                  >
                    <Tooltip title={color.name}>
                      <img
                        src={color.image}
                        alt=""
                        width="20px"
                        style={{ borderRadius: "50%" }}
                      />
                    </Tooltip>
                  </Box>
                );
              }
              return null;
            })}
          </Box>
          <Typography sx={{ mt: 2 }}>Size:</Typography>
          <Box sx={{ display: "flex", gap: "20px", mt: 1 }}>
            {selectedItem?.data?.Sizes.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border:
                      selectedSize === item
                        ? "2px solid #000"
                        : "2px solid #ddd",

                    padding: "3px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSizeClick(item)}
                >
                  <Typography>{item}</Typography>
                </Box>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDialogOK}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SellerProductCard;
