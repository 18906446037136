import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ProductRating from "../ProductDetails/Rating";
import LeftSideBar from "./Leftsidebar";
import SellerProductCard from "./SellerProductCard";
import { handleColorAttributes1 } from "../../axios";

const SellerProductList = ({}) => {
  const [data, setData] = useState({});
  const [sellerProducts, setSellerProducts] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedPriceRanges, setSelectedPriceRanges] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("Most Popular");
  const [colorAttributes, setcolorAttributes] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const { id } = useParams();

  const UTCTOIST = (data) => {
    const date = new Date(data);
    const options = { timeZone: "Asia/Kolkata" };
    return date.toLocaleDateString("en-IN", options);
  };

  const isLoggedIn = localStorage.getItem("user");

  const handleGetSellerById = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/seller/getSellerById/${id}`
      );
      if (res) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleColorAttributes = async () => {
    try {
      const res = await handleColorAttributes1();
      if (res) {
        setcolorAttributes(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCartItemsByUser = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/getCartItemsByUser/${isLoggedIn._id}`
      );
      if (res) {
        // console.log(res);
        setCartItems(res.data);
        // handleGetCartItemsByUser();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePriceRangeChange = (newPriceRanges) => {
    setSelectedPriceRanges(newPriceRanges);
  };

  const handleColorSelection = (color) => {
    if (selectedColors.includes(color)) {
      setSelectedColors(selectedColors.filter((c) => c !== color));
    } else {
      setSelectedColors([...selectedColors, color]);
    }
  };

  useEffect(() => {
    const filterResults = () => {
      let filtered = sellerProducts.filter((item) => item.active === true);

      // Filter by price range
      if (selectedPriceRanges.length > 0) {
        filtered = filtered.filter((product) => {
          const price = product.offerPrice;
          for (let range of selectedPriceRanges) {
            if (range === "20000 to Above" && price >= 20000) {
              return true;
            }
            const [min, max] = range.split(" to ");
            if (price >= Number(min) && price <= Number(max)) {
              return true;
            }
          }
          return false;
        });
      }

      // Filter by selected colors
      if (selectedColors.length > 0) {
        filtered = filtered.filter((item) =>
          item?.Colors?.some((color) => selectedColors.includes(color))
        );
      }

      setFilteredProducts(filtered);
    };

    filterResults();
  }, [data, selectedPriceRanges, selectedColors]);

  const sortedProducts = [...filteredProducts];

  if (sortBy === "Name -- A to Z") {
    sortedProducts.sort((a, b) => {
      if (a.Title && b.Title) {
        return a.Title.localeCompare(b.Title);
      }
      return 0;
    });
  } else if (sortBy === "Most Popular") {
    sortedProducts.sort((a, b) => b.popularity - a.popularity);
  } else if (sortBy === "Price -- Low to High") {
    sortedProducts.sort((a, b) => a.offerPrice - b.offerPrice);
  } else if (sortBy === "Price -- High to Low") {
    sortedProducts.sort((a, b) => b.offerPrice - a.offerPrice);
  }

  const handleGetAllProductsByThisSeller = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/seller/Products/getAllProductsByMe/${id}`
      );
      if (res) {
        setSellerProducts(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSortByChange = (event) => {
    setSortBy(event.target.value);
  };

  useEffect(() => {
    Promise.all([
      handleGetSellerById(),
      handleGetCartItemsByUser(),
      handleColorAttributes(),
    ]);
  }, []);

  useEffect(() => {
    Promise.all([handleGetAllProductsByThisSeller()]);
  }, []);

  return (
    <Box>
      <div
        style={{
          background: "#eee",
          padding: "20px",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={10}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Box
                sx={{
                  maxHeight: "250px",
                  maxWidth: "250px",
                }}
              >
                <img
                  src="https://res.cloudinary.com/bunny1705/image/upload/v1684689562/ftobzfbcxpc8nexfbyck.jpg"
                  alt=""
                  width="100%"
                  height="100%"
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
                  {data?.companyName}
                </Typography>
                <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <LocationOnIcon sx={{ color: "gray" }} />
                    {data?.city},{data?.country}
                  </Typography>
                  <Typography>|</Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    <strong>0</strong> sales(s)
                  </Typography>
                  <Typography>|</Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    Seller since {UTCTOIST(data.createdAt)}
                  </Typography>
                </Box>
                <Box sx={{ mt: 0.5 }}>
                  <ProductRating value={3} />
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} lg={2}>
            <Box>
              <Link style={{ color: "black" }}>Ask a Question</Link>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Box sx={{ ml: 2.5, mr: 2.5 }}>
        <Divider sx={{ mt: 3 }} />
      </Box>
      <Box sx={{ mt: 1 }}>
        <Grid container spacing={2} sx={{ px: 3, p: 1 }}>
          <Grid item lg={1.5} xl={1.2}>
            <Box sx={{ ml: 1 }}>
              <Typography>
                Total <strong>{filteredProducts?.length}</strong> Products
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={0.5} xl={0.1}>
            |
          </Grid>
          <Grid item lg={1.5} xl={1}>
            <Link style={{ color: "black", textDecoration: "none" }}>
              Reviews
            </Link>
          </Grid>
          <Grid item lg={0.5} xl={0.3}>
            |
          </Grid>
          <Grid item lg={5}>
            <Link style={{ color: "black", textDecoration: "none" }}>
              Contact Seller
            </Link>
          </Grid>
          <Grid item lg={3} xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>SortBy : </Typography>
              <Select
                sx={{ width: "250px" }}
                size="small"
                value={sortBy}
                onChange={handleSortByChange}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Name -- A to Z">Name -- A to Z</MenuItem>
                <MenuItem value="Most Popular">Most Popular</MenuItem>
                <MenuItem value="Price -- High to Low">
                  Price -- High to Low
                </MenuItem>
                <MenuItem value="Price -- Low to High">
                  Price -- Low to High
                </MenuItem>
              </Select>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ ml: 2.4, mr: 2.5 }} />
        <Box sx={{ m: 2.5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <LeftSideBar
                sellerProducts={filteredProducts}
                onPriceRangeChange={handlePriceRangeChange}
                onColorSelection={handleColorSelection}
                colorAttributes={colorAttributes}
                selectedColors={selectedColors}
              />
            </Grid>
            {!Loading ? (
              <Grid item xs={12} lg={10} xl={10}>
                <SellerProductCard
                  sellerProducts={sortedProducts}
                  colorAttributes={colorAttributes}
                  cartItems={cartItems}
                />
              </Grid>
            ) : (
              <Grid lg={10} xl={10}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default SellerProductList;
