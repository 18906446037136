import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";

const FeaturedBrands = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "20px" }}>
      <Box
        sx={{
          ml: 3,
          mb: 2,
          mr: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          {data?.featurednewbrandsdatas?.[0]?.Heading}
        </Typography>
        <Button
          variant="contained"
          sx={{ fontWeight: "bold" }}
          onClick={() =>
            navigate(data?.featurednewbrandsdatas?.[0]?.linktoButton)
          }
        >
          {data?.featurednewbrandsdatas?.[0]?.ButtonText}
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ px: 3 }}>
        {data?.featuredbrandsnewimages
          ?.sort((a, b) => a.sortingOrder - b.sortingOrder)
          .map((item, index) => {
            if (item.visible === true) {
              return (
                <Grid item xs={6} sm={3} md={6} lg={1.5} key={index}>
                  <Card
                    sx={{ height: "100%", width: "100%", position: "relative" }}
                  >
                    <img
                      src={item.image}
                      alt=""
                      className="cardimg"
                      height="100%"
                      width="100%"
                      onClick={() => navigate(item.url)}
                    />
                    <Box
                      elevation={2}
                      onClick={() => navigate(item.url)}
                      sx={{
                        position: "absolute",
                        bottom: "30px",
                        left: "7px",
                        right: "7px",
                        background: "#fff",
                        borderRadius: "5px",
                        cursor: "pointer",
                        p: 1,
                      }}
                      fullWidth
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {item.subtitle}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              );
            }
          })}
      </Grid>
    </div>
  );
};

export default FeaturedBrands;
