import { Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { loginUserSuccess } from "../Redux/Cart/UserSlice";
import { updateCartItems } from "../Redux/Cart/CartSlice";

const UserInformation = () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [contactNumber, setContactNumber] = useState();
  const [pinCode, setPincode] = useState();
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const FetchUserInformationById = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/fetchUserById/${id}`
      );
      if (res) {
        console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserInformation = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/buyer/userInfo/${id}`,
        {
          name: name,
          userEmail: data?.email ? data?.email : email,
          contactNumber: data?.mobile ? data?.mobile : contactNumber,
          city: city,
          state: state,
          country: country,
          pincode: pinCode,
        }
      );
      if (res) {
        toast.success("Information Updated SuccessFully", {
          position: "top-right",
        });

        navigate("/", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchUserInformationById();
  }, []);

  const handlePincodeChange = async (event) => {
    const postalCode = event.target.value;

    try {
      const isValidPincode = /^[1-9][0-9]{5}$/.test(postalCode);
      if (isValidPincode) {
        axios
          .get(`https://api.postalpincode.in/pincode/${postalCode}`)
          .then((response) => {
            const postOffice = response.data[0].PostOffice[0];
            const city = postOffice.Block;
            const state = postOffice.State;
            const country = postOffice.Country;
            setCity(city);
            setState(state);
            setCountry(country);
            setError(null);
          })
          .catch((error) => {
            console.error(error);
            setCity("");
            setState("");
            setCountry("");
          });
        setPincode(postalCode);
      } else {
        setCity("");
        setState("");
        setCountry("");
        setPincode(postalCode);
        setError("Invalid PIN code");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 5,
            }}
          >
            <Card sx={{ width: "450px", p: 2, border: "1px solid #ddd" }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Enter User Information
              </Typography>
              <Box>
                <Typography>FullName</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Full Name"
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ mt: 0.5, mb: 2 }}
                />
              </Box>
              <Box>
                <Typography>Email Id</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Email Id"
                  size="small"
                  value={data?.email ? data?.email : email}
                  sx={{ mt: 0.5, mb: 2 }}
                  onChange={(e) => {
                    if (!data.email) {
                      setEmail(e.target.value);
                    }
                  }}
                  disabled={data.email}
                />
              </Box>
              <Box>
                <Typography>contact Number</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter contact Number"
                  size="small"
                  value={data?.mobile ? data?.mobile : contactNumber}
                  sx={{ mt: 0.5, mb: 2 }}
                  onChange={(e) => {
                    if (!data.mobile) {
                      setContactNumber(e.target.value);
                    }
                  }}
                  disabled={data.mobile}
                />
              </Box>
              <Box>
                <Typography>PinCode</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Pincode"
                  size="small"
                  sx={{ mt: 0.5, mb: 2 }}
                  error={error}
                  helperText={error}
                  onChange={handlePincodeChange} // Call handlePincodeChange on change
                />
              </Box>
              <Box>
                <Typography>City</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter City"
                  size="small"
                  value={city}
                  sx={{ mt: 0.5, mb: 2 }}
                />
              </Box>
              <Box>
                <Typography>State</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter State"
                  size="small"
                  value={state}
                  sx={{ mt: 0.5, mb: 2 }}
                />
              </Box>
              <Box>
                <Typography>Country</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Country"
                  size="small"
                  value={country}
                  sx={{ mt: 0.5, mb: 2 }}
                />
              </Box>
              <Button
                variant="contained"
                fullWidth
                onClick={handleUserInformation}
              >
                SUBMIT
              </Button>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserInformation;
