import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Typography } from "@mui/material";

const HeaderDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [openItems, setOpenItems] = useState([]);

  const handleDropdownToggle = (itemName) => {
    if (openItems.includes(itemName)) {
      setOpenItems(openItems.filter((item) => item !== itemName));
    } else {
      setOpenItems([...openItems, itemName]);
    }
  };

  const data = [
    {
      name: "Accessories",
      subCategories: [
        {
          name: "Laptops",
          children: [
            {
              name: "Macbook",
            },
            {
              name: "Macbook Air",
            },
            {
              name: "Macbook Pro",
            },
            {
              name: "Macbook Air 2",
            },
          ],
        },
        {
          name: "keyBoards",
          children: [
            {
              name: "Apple Keyboard",
            },
            {
              name: "Apple Magic Keyboard",
            },
            {
              name: "Apple Magic Keyboard 2",
            },
            {
              name: "Apple Magic Keyboard 3",
            },
          ],
        },
      ],
    },
    {
      name: "Watches",
      subCategories: [
        {
          name: "Smart Watches",
          children: [
            {
              name: "Apple Watch",
            },
            {
              name: "Apple Watch Series 6",
            },
            {
              name: "Samsung z6",
            },
            {
              name: "Fire Boult ",
            },
          ],
        },
        {
          name: "Analog Waches",
          children: [
            {
              // analog watches
              name: "Titan Neo IV watch",
            },
            {
              name: "Casio Analog Black Dial",
            },
            {
              name: "Sonata Analog Blue",
            },
            {
              name: "Armani Exchange ",
            },
          ],
        },
      ],
    },
  ];

  return (
    <div>
      <MenuIcon
        onClick={handleDrawerToggle}
        sx={{ color: "#fff", ml: 1, fontSize: "35px" }}
      />

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: "240px" },
        }}
      >
        <List>
          {data.map((item) => (
            <React.Fragment key={item.name}>
              <ListItem button onClick={() => handleDropdownToggle(item.name)}>
                <ListItemText primary={item.name} sx={{ width: "100%" }} />
                {openItems.includes(item.name) ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </ListItem>
              {openItems.includes(item.name) &&
                item.subCategories.map((subCategory) => (
                  <React.Fragment key={subCategory.name}>
                    <ListItem
                      button
                      onClick={() => handleDropdownToggle(subCategory.name)}
                    >
                      <ListItemText primary={subCategory.name} />
                      {openItems.includes(subCategory.name) ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </ListItem>
                    {openItems.includes(subCategory.name) &&
                      subCategory.children.map((child) => (
                        <List key={child.name}>
                          <ListItem button>
                            <Typography sx={{ mt: -1 }}>
                              {child.name}
                            </Typography>
                          </ListItem>
                        </List>
                      ))}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default HeaderDrawer;
