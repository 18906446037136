import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import MostSimilarCard from "./MostSimilarCard";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

const MostSimilar = ({ colorAttributes, cartItems, similarProducts }) => {
  return (
    <div>
      {similarProducts && (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" gutterBottom>
                MOST SIMILAR
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  border: "2px solid #00a5d7",
                  width: "60px",
                  margin: "0 auto",
                  marginTop: "-15px",
                }}
              ></div>
              <Divider />
            </Grid>
            {similarProducts.length > 0 ? (
              <Grid item xs={12}>
                <MostSimilarCard
                  filteredProducts={similarProducts}
                  colorAttributes={colorAttributes}
                  cartItems={cartItems}
                />
              </Grid>
            ) : (
              <Box>
                <Typography sx={{ textAlign: "center", ml: 2 }}>
                  No Similar Items Found
                </Typography>
              </Box>
            )}
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default MostSimilar;
