import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useNavigate, useParams } from "react-router-dom";
import { addToCart } from "../Redux/Cart/CartSlice";

const RecentlyViewed = ({ colorAttributes }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const recentlyViewedItems = useSelector((state) => state.recentlyViewed);
  const { id } = useParams();
  const { name } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenDialog = () => {
    setDialogOpen(true);
    setSelectedColor("");
    setSelectedSize("");
  };

  const handleAddToCart = (item) => {
    setSelectedItem(item);
    handleOpenDialog();
  };

  const handleDialogOK = () => {
    if (selectedColor && selectedItem) {
      const product = {
        ...selectedItem,
        selectedColor: selectedColor,
        // size: selectedSize,
      };
      dispatch(addToCart(product));
      setDialogOpen(false);
      navigate("/cart");
    }
  };

  const handleColorClick = (item) => {
    setSelectedColor(item);
  };

  return (
    <div>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Box sx={{ mt: 5 }}>
              <Typography variant="h6" align="center" gutterBottom>
                RECENTLY VIEWED
              </Typography>
              <Typography
                sx={{
                  border: "2px solid #0099CC",
                  margin: "auto",
                  width: "60px",
                }}
              ></Typography>
              <Divider />
            </Box>
          </Grid>
          {recentlyViewedItems &&
            recentlyViewedItems.recentlyViewed.map((item, index1) => {
              let index;
              const key = item?.Colors?.flatMap((color, colorIndex) => {
                return item?.Sizes?.flatMap((size, sizeIndex) => {
                  index = colorIndex * item?.Sizes.length + sizeIndex;
                  return `${color}-${size}`;
                });
              });
              if (item._id !== id) {
                return (
                  <>
                    <Grid item xs={12} lg={3} key={index1}>
                      <Box sx={{ display: "flex", height: "100%" }}>
                        <Card
                          sx={{
                            maxWidth: 305,
                            border: "1px solid #ddd",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={item.MainImage}
                              alt=""
                              style={{ maxHeight: "180px", padding: "5px" }}
                              className="cardimg"
                              onClick={() => {
                                navigate(
                                  `/product-details/${item.subCategory}/${item._id}`
                                );
                              }}
                            />
                          </Box>
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: "600", color: "#0099cc" }}
                            >
                              {item.Title.slice(0, 25)}....
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Lizards are a widespread group of squamate
                              reptiles...
                            </Typography>
                            <Box sx={{ display: "flex", gap: "80px", mt: 1 }}>
                              <Typography
                                sx={{ fontWeight: "600", fontSize: "16px" }}
                              >
                                ₹ {item?.mrp?.[0]?.[key?.[0]]?.offerPrice}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "gray",
                                  textDecoration: "line-through",
                                }}
                              >
                                ₹ {item?.mrp?.[0]?.[key?.[0]]?.mrp}
                              </Typography>
                            </Box>
                          </CardContent>
                          <CardActions
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              disableElevation
                              sx={{ width: "150px" }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <FavoriteIcon
                                  sx={{ fontSize: "24px", mr: 0.5 }}
                                />
                                WishList
                              </Box>
                            </Button>
                            <Button
                              size="small"
                              variant="contained"
                              disableElevation
                              sx={{ width: "150px" }}
                              onClick={() => handleAddToCart(item)}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <ShoppingCartIcon
                                  sx={{ fontSize: "24px", mr: 0.5 }}
                                />
                                Add To Cart
                              </Box>
                            </Button>
                          </CardActions>
                        </Card>
                      </Box>
                      <Dialog
                        open={dialogOpen}
                        onClose={() => setDialogOpen(false)}
                      >
                        <DialogTitle>Select Color and Size</DialogTitle>
                        <DialogContent>
                          <Typography>Color:</Typography>
                          <Box sx={{ display: "flex", gap: "20px" }}>
                            {selectedItem?.Colors.map((item, index) => {
                              const color = colorAttributes.find(
                                (color) => color.name === item
                              );
                              if (color) {
                                return (
                                  <Box
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      border:
                                        selectedColor === color?.name
                                          ? "2px solid #000"
                                          : "2px solid #ddd",
                                      borderRadius: "50%",
                                      padding: "3px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleColorClick(color.name)}
                                  >
                                    <img
                                      src={color.image}
                                      alt=""
                                      width="20px"
                                      style={{ borderRadius: "50%" }}
                                    />
                                  </Box>
                                );
                              }
                              return null;
                            })}
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setDialogOpen(false)}>
                            Cancel
                          </Button>
                          <Button onClick={handleDialogOK}>OK</Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </>
                );
              }
            })}
        </Grid>
      </Box>
    </div>
  );
};

export default RecentlyViewed;
