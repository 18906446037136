import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import ProductRating from "./Rating";
import RatingAndReviewsDialog from "./RatingAndReviewModal";
import { getAllReviewsFOrProductById } from "../../axios";
import { useParams } from "react-router-dom";

const RatingsReviews = () => {
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { id } = useParams();

  const handleGetAllReviewsByProduct = async () => {
    try {
      const res = await getAllReviewsFOrProductById(id);
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllReviewsByProduct();
  }, [refresh]);

  const activeReviews = data.filter((item) => item.active === true);

  const totalQuality = activeReviews.reduce((acc, row) => acc + row.Quality, 0);
  const totalValueForMoney = activeReviews.reduce(
    (acc, row) => acc + row.valueForMoney,
    0
  );
  const totalPerformence = activeReviews.reduce(
    (acc, row) => acc + row.Performence,
    0
  );

  const averageQuality =
    activeReviews.length > 0 ? totalQuality / activeReviews.length : 0;
  const averageValueForMoney =
    activeReviews.length > 0 ? totalValueForMoney / activeReviews.length : 0;
  const averagePerformence =
    activeReviews.length > 0 ? totalPerformence / activeReviews.length : 0;

  return (
    <Box style={{ backgroundColor: "#f5f5f5" }}>
      <Grid container spacing={2} sx={{ px: 3, py: 1 }}>
        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}
          >
            Ratings & Reviews
          </Typography>
        </Grid>
        <div
          style={{
            border: "2px solid #0071c2",
            width: "60px",
            margin: "0 auto",
          }}
        ></div>
        <Grid item xs={12}>
          <Typography sx={{ textAlign: "center" }}>
            <ProductRating
              value={(
                (averageQuality + averageValueForMoney + averagePerformence) /
                3
              ).toFixed(1)}
              readOnly
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
              mt: -2,
            }}
          >
            {activeReviews.length} REVIEWS
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <RatingAndReviewsDialog setRefresh={setRefresh} />
        </Grid>

        <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
          {activeReviews.length > 0 ? (
            activeReviews.slice(0, 3).map((item, index) => (
              <div key={index}>
                <Typography variant="body1" gutterBottom>
                  {item.Name}
                </Typography>
                <Typography>
                  <ProductRating
                    value={(
                      (item?.Quality +
                        item?.valueForMoney +
                        item?.Performence) /
                      3
                    ).toFixed(1)}
                    readOnly
                  />
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  gutterBottom
                  sx={{ mb: 2 }}
                >
                  {item.Description}
                </Typography>
              </div>
            ))
          ) : (
            <Box sx={{ mt: 2, mb: 4 }}>
              <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
                No Reviews Found For This Product
              </Typography>
            </Box>
          )}
        </Grid>

        {activeReviews.length > 4 && (
          <Grid item xs={12}>
            <Typography align="center">
              <Button
                disableElevation
                variant="contained"
                style={{
                  backgroundColor: "#252f3d",
                  color: "white",
                  height: "50px",
                }}
              >
                VIEW ALL REVIEWS
              </Button>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RatingsReviews;
