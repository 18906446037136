import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, updateCartItemQuantityAPI } from "../Redux/Cart/CartSlice";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import "../../App.css";
import {
  addToRecentlyViewed,
  updateRecentlyViewed,
} from "../Redux/Cart/RecentlyViewed";

import { v4 as uuidv4 } from "uuid";
import { addToWishlist } from "../Redux/Cart/WishListSlice";
import { toast } from "react-toastify";
import ProductRating from "../ProductDetails/Rating";
import {
  IncrementViewCountOfProduct,
  getAllReviewsFOrProductById,
} from "../../axios";
import { incrementViewCount } from "../Redux/Cart/ViewCountOfProductSlice";

const Products = ({
  colorAttributes,
  filteredProducts,
  setCartItems,
  Loading,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState();
  const [Loading1, setLoading1] = useState(false);
  // const [cartItems, setCartItems] = useState({});

  const { name } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const isLoggedIn = JSON.parse(localStorage.getItem("User"));

  const [reviews, setReviews] = useState([]);

  console.log(reviews);

  // console.log(isLoggedIn);

  const handleGetCartItemsByUser = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/getCartItemsByUser/${isLoggedIn._id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
    setSelectedColor("");
    setSelectedSize("");
  };

  const handleAddToCart = (item) => {
    setSelectedItem(item);
    handleOpenDialog();
  };

  const handleDialogOK = async () => {
    if (selectedColor && selectedItem && selectedSize) {
      const product = {
        ...selectedItem,
        selectedColor: selectedColor,
        selectedSize: selectedSize,
      };

      if (isLoggedIn) {
        try {
          let updatedCartItem = null;
          let updatedCartItems = [...data.cartItems]; // Create a copy of the existing cart items

          // Check if the product already exists in the cart
          for (const item of updatedCartItems) {
            if (
              item.productId._id === product._id &&
              item.selectedColor === product.selectedColor &&
              item.userId === isLoggedIn._id &&
              item.selectedSize === product.selectedSize
            ) {
              // Send a PUT request to update the quantity of the existing item
              const response = await axios.put(
                `https://rccore-backend.cstechns.com/api/buyer/updateCartItemQuantity/${item.productId.uniqueId}/${item.selectedColor}/${isLoggedIn._id}`,
                {
                  quantity: item.quantity + 1,
                }
              );

              updatedCartItem = response.data; // Assuming the updated cart item is returned from the API

              // Display a success toast message for quantity update
              toast.info("Item Increased Successfully", {
                position: "top-right",
              });

              break;
            }
          }

          // If the product does not exist in the cart, add it
          if (!updatedCartItem) {
            const response = await axios.post(
              "https://rccore-backend.cstechns.com/api/buyer/addToCart",
              {
                productId: product,
                userId: isLoggedIn._id,
                quantity: 1,
                selectedColor: product.selectedColor,
                selectedSize: product.selectedSize,
                uniqueId: product.uniqueId,
              }
            );

            updatedCartItem = response.data; // Assuming the added cart item is returned from the API

            // Display a success toast message for item addition
            toast.success("Item Added to Cart Successfully", {
              position: "top-right",
            });
          }

          // Update the cart items in the state
          updatedCartItems.push(updatedCartItem);
          setData({
            ...data,
            cartItems: updatedCartItems,
          });

          // Dispatch the updated cart item to the store
        } catch (error) {
          console.error(error);
          // Handle the error, display an error message, or perform necessary actions
        }
      }
      dispatch(addToCart(product));
      setDialogOpen(false);
      navigate("/cart");
    } else {
      toast.error("Please Select Color And Size", {
        position: "top-right",
      });
    }
  };

  const handleColorClick = (item) => {
    setSelectedColor(item);
  };
  const handleSizeClick = (item) => {
    // console.log(item);
    setSelectedSize(item);
  };

  const handleAddTORecentlyViewed = (item) => {
    dispatch(addToRecentlyViewed(item));
    dispatch(
      incrementViewCount({ productId: item._id, userId: isLoggedIn._id })
    );
    IncrementViewCountOfProduct(item._id, isLoggedIn._id);
  };

  const handleAddToWishList = async (item) => {
    try {
      if (isLoggedIn) {
        const res = await axios.post(
          `https://rccore-backend.cstechns.com/api/buyer/AddToWishList`,
          {
            productId: item,
            userId: isLoggedIn,
          }
        );
        if (res.data.error) {
          toast.info("Item Already in Your WishList", {
            position: "top-right",
          });
        } else {
          toast.success("Item added to WishList SuccessFuly", {
            position: "top-right",
          });
          navigate("/wishlist");
        }
      } else {
        toast.error("User Must be loggedIn", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetReviews = async () => {
    try {
      const promises = filteredProducts.map(async (item) => {
        if (item) {
          const res = await getAllReviewsFOrProductById(item._id);
          if (res) {
            setReviews(res);
          }
        }
      });

      await Promise.all(promises);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetCartItemsByUser();
  }, []);

  useEffect(() => {
    handleGetReviews();
  }, [filteredProducts, name]);

  const calculateOfferPercentage = (mrp, offerPrice) => {
    const discountAmount = mrp - offerPrice;
    const offerPercentage = (discountAmount / mrp) * 100;
    return Math.round(offerPercentage);
  };

  const calculateAverageRatings = (reviews) => {
    const totalQuality = reviews.reduce(
      (acc, review) => acc + review.Quality,
      0
    );

    const totalValueForMoney = reviews.reduce(
      (acc, review) => acc + review.valueForMoney,
      0
    );
    const totalPerformance = reviews.reduce(
      (acc, review) => acc + review.Performence,
      0
    );

    const averageQuality =
      reviews.length > 0 ? totalQuality / reviews.length : 0;
    const averageValueForMoney =
      reviews.length > 0 ? totalValueForMoney / reviews.length : 0;
    const averagePerformance =
      reviews.length > 0 ? totalPerformance / reviews.length : 0;

    return {
      averageQuality,
      averageValueForMoney,
      averagePerformance,
    };
  };

  // console.log(selectedColo);
  // console.log(filteredProducts);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Box>
          <Box></Box>
          {!Loading ? (
            <Grid container spacing={2} sx={{ px: 3 }}>
              {filteredProducts?.map((item, index1) => {
                // console.log(activeReviews);

                let index;
                const key = item?.Colors?.flatMap((color, colorIndex) => {
                  return item?.Sizes?.flatMap((size, sizeIndex) => {
                    index = colorIndex * item?.Sizes.length + sizeIndex;
                    return `${color}-${size}`;
                  });
                });

                // console.log(index,key)

                return (
                  <Grid item xs={12} lg={4} xl={4} key={index1}>
                    {item.active === true &&
                    item?.createdBy?.sellerStoreActive === true ? (
                      <Box>
                        <Card sx={{ maxWidth: 345, border: "1px solid #ddd" }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={item.MainImage}
                              alt=""
                              style={{ maxHeight: "180px" }}
                              className="cardimg"
                              onClick={() => {
                                navigate(
                                  `/product-details/${name}/${item._id}`
                                );
                                handleAddTORecentlyViewed(item);
                              }}
                            />
                          </Box>
                          <CardContent>
                            <Typography
                              gutterBottom
                              sx={{
                                fontWeight: "600",
                                color: "#0099cc",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigate(
                                  `/product-details/${name}/${item._id}`
                                );
                                handleAddTORecentlyViewed(item);
                              }}
                            >
                              {item.Title.length > 25
                                ? item.Title.slice(0, 25) + `...`
                                : item.Title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {item.Description.length > 25
                                ? item.Description.slice(0, 25) + `...`
                                : item.Description}
                            </Typography>
                            {/* <Box sx={{ mt: 1, display: "flex", gap: "10px" }}>
                              <ProductRating
                                value={(
                                  (averageQuality +
                                    averageValueForMoney +
                                    averagePerformance) /
                                  3
                                ).toFixed(1)}
                                readOnly
                              />
                              <Typography>{activeReviews.length}</Typography>
                            </Box> */}
                            <Box sx={{ display: "flex", gap: "30px", mt: 1 }}>
                              <Typography
                                sx={{ fontWeight: "600", fontSize: "16px" }}
                              >
                                {/* ₹ {item?.offerPrice} */}₹{" "}
                                {item?.mrp?.[0]?.[key?.[0]]?.offerPrice}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "gray",
                                  textDecoration: "line-through",
                                }}
                              >
                                {/* ₹ {item?.mrp} */}₹{" "}
                                {item?.mrp?.[0]?.[key?.[0]]?.mrp}
                              </Typography>
                            </Box>
                          </CardContent>
                          <CardActions
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              disableElevation
                              sx={{ width: "150px" }}
                              onClick={() => handleAddToWishList(item)}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <FavoriteIcon
                                  sx={{ fontSize: "24px", mr: 0.5 }}
                                />
                                WishList
                              </Box>
                            </Button>
                            <Button
                              size="small"
                              variant="contained"
                              disableElevation
                              sx={{ width: "150px" }}
                              onClick={() => handleAddToCart(item)}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <ShoppingCartIcon
                                  sx={{ fontSize: "24px", mr: 0.5 }}
                                />
                                Add To Cart
                              </Box>
                            </Button>
                          </CardActions>
                        </Card>
                      </Box>
                    ) : (
                      <Box></Box>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
        {filteredProducts.length === 0 && !Loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                border: "1px solid #ddd",
                textAlign: "center",
                background: "#eee",
                p: 2,
                width: "300px",
                mt: 3,
              }}
            >
              No Products Available
            </Typography>
          </Box>
        )}
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          fullWidth
        >
          <DialogTitle>Select Color and Size</DialogTitle>
          <DialogContent>
            <Typography>Color:</Typography>
            <Box sx={{ display: "flex", gap: "20px" }}>
              {selectedItem?.Colors.map((item, index) => {
                const color = colorAttributes.find(
                  (color) => color.name === item
                );
                if (color) {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border:
                          selectedColor === color?.name
                            ? "2px solid #000"
                            : "2px solid #ddd",
                        borderRadius: "50%",
                        padding: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleColorClick(color.name)}
                    >
                      <Tooltip title={color.name}>
                        <img
                          src={color.image}
                          alt=""
                          width="20px"
                          style={{ borderRadius: "50%" }}
                        />
                      </Tooltip>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
            <Typography sx={{ mt: 2 }}>Size:</Typography>
            <Box sx={{ display: "flex", gap: "20px", mt: 1 }}>
              {selectedItem?.data?.Sizes.map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border:
                        selectedSize === item
                          ? "2px solid #000"
                          : "2px solid #ddd",

                      padding: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSizeClick(item)}
                  >
                    <Typography>{item}</Typography>
                  </Box>
                );
              })}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleDialogOK}>OK</Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default Products;
