import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductShippingAddress from "./ProductShippingAddress";
import CHeckoutProductDetails from "./CHeckoutProductDetails";
import axios from "axios";
import AddressesList from "./AddressesList";
import { useLocation } from "react-router-dom";

const isLoggedIn = JSON.parse(localStorage.getItem("User"));

const Checkout = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleGetShippingAddressByLoggedInUser = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/handleGetShippingAddressByUser/${isLoggedIn._id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
        // handleGetShippingAddressByLoggedInUser();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const location = useLocation();
  const cartItems = location.state?.cartItems;

  // console.log(cartItems);

  useEffect(() => {
    Promise.all([handleGetShippingAddressByLoggedInUser()]);
  }, []);
  return (
    <div>
      {!loading ? (
        <Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              SELECT DELIVERY ADDRESS
            </Typography>
            <Typography
              sx={{
                border: "2px solid #0099CC",
                width: "60px",
                margin: "auto",
              }}
            ></Typography>
          </Box>
          <Grid container spacing={2} sx={{ px: 3, mt: 1 }}>
            <Grid item xs={12} lg={8}>
              {isLoggedIn && data?.length > 0 ? (
                <AddressesList data={data} setData={setData} />
              ) : (
                <ProductShippingAddress />
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              <CHeckoutProductDetails cartItems={cartItems} />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default Checkout;
