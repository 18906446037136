import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recentlyViewed: localStorage.getItem("recentlyViewed")
    ? JSON.parse(localStorage.getItem("recentlyViewed"))
    : [],
};

const recentlyViewedSlice = createSlice({
  name: "recentlyViewed",
  initialState,
  reducers: {
    updateRecentlyViewed(state, action) {
      state.recentlyViewed = action.payload;
    },
    addToRecentlyViewed(state, action) {
      const { payload } = action;
      const itemIndex = state.recentlyViewed.findIndex(
        (item) => item._id === payload._id
      );

      if (itemIndex !== -1) {
        // If item already exists, move it to the front
        state.recentlyViewed.splice(itemIndex, 1);
      }

      // Add the new item to the front of the recently viewed array
      state.recentlyViewed.unshift(payload);

      // Limit the recently viewed array to a certain number of items (e.g., 5)
      if (state.recentlyViewed.length > 10) {
        state.recentlyViewed.pop();
      }
      localStorage.setItem(
        "recentlyViewed",
        JSON.stringify(state.recentlyViewed)
      );
    },
  },
});

export const { updateRecentlyViewed, addToRecentlyViewed } =
  recentlyViewedSlice.actions;

export default recentlyViewedSlice.reducer;
