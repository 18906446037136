import StarIcon from "@mui/icons-material/Star";
import ProductRating from "../ProductDetails/Rating";
import { Tooltip } from "@mui/material";
export const ServiceSidebarData = [
  {
    name: "PRICE",
    children: [
      {
        name: "1 to 4999",
      },
      {
        name: "5000 to 9999",
      },
      {
        name: "10000 to 14999",
      },
      {
        name: "15000 to 19999",
      },
      {
        name: "20000 to Above",
      },
    ],
  },
  {
    name: "Color",
    children: [
      {
        image:
          "https://res.cloudinary.com/bunny1705/image/upload/v1684406925/Color_images/xddmqolsy0dzcc4qxqdm.png",
      },
      {
        image:
          "https://res.cloudinary.com/bunny1705/image/upload/v1684429406/Color_images/mijzbvwthainwpqhtzyz.png",
      },
      {
        image:
          "https://res.cloudinary.com/bunny1705/image/upload/v1684429445/Color_images/rvkqxlba5ydhsg1bp4n6.png",
      },
      {
        image:
          "https://res.cloudinary.com/bunny1705/image/upload/v1684429465/Color_images/gbblmopaiwsgbfqmciwg.png",
      },
      {
        image:
          "https://res.cloudinary.com/bunny1705/image/upload/v1684472234/Color_images/uuzhfvtcalgsapdxxx9j.png",
      },
    ],
  },
  {
    name: "RATING",
    children: [
      {
        name: <ProductRating value={5} />,
      },
      {
        name: <ProductRating value={4} />,
      },
      {
        name: <ProductRating value={3} />,
      },
      {
        name: <ProductRating value={2} />,
      },
      {
        name: <ProductRating value={1} />,
      },
    ],
  },
];

export const getfilteredNavigations = (navList = [], role) => {
  return navList.reduce((array, nav) => {
    if (nav.auth && nav.auth.includes(role)) {
      array.push(nav);
    } else {
      if (nav.children) {
        nav.children = getfilteredNavigations(nav.children, role);
        array.push(nav);
      }

      array.push(nav);
    }
    return array;
  }, []);
};
