import React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";

export default function ProductRating({ value, onHandleChange, readOnly }) {
  const handleChange = (event, newValue) => {
    onHandleChange(newValue);
  };

  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
      }}
    >
      <Rating
        name="simple-controlled"
        value={value}
        onChange={handleChange}
        readOnly={readOnly}
        precision={0.5}
      />
    </Box>
  );
}
