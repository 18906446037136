import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MyOrders from "./MyOrders";
import ShippingAddress from "./ShippingAddress";
import MyProfile from "./MyProfile";

export default function MyAccountTabs() {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [value, setValue] = React.useState(2); // Set the initial value to 2

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabContent = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return (
          <div>
            <MyProfile />
          </div>
        );
      case 1:
        return (
          <div>
            <ShippingAddress />
          </div>
        );
      case 2:
        return (
          <div style={{ width: "100%" }}>
            <MyOrders />
          </div>
        );
      case 3:
        return <div>Content for MY RETURNS</div>;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: "96.5%", bgcolor: "background.paper", ml: 3, mt: 4 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant={isLargeScreen ? "fullWidth" : "scrollable"}
        scrollButtons={isLargeScreen ? "off" : "auto"}
        aria-label="scrollable auto tabs example"
        TabIndicatorProps={{
          style: { display: "none" },
        }}
        sx={{
          "& .MuiTabs-flexContainer": {
            // borderBottom: "1px solid #ddd",
          },
          "& .MuiTab-root": {
            border: "1px solid #ddd",

            "&.Mui-selected": {
              backgroundColor: "#0099cc",
              color: "white",
            },
            "&:hover": {
              backgroundColor: "#0099cc",
              color: "#fff",
            },
          },
          "& .MuiTab-textColorPrimary": {
            color: "black",
          },
        }}
      >
        <Tab label="MY PROFILE" sx={{ fontWeight: "bold" }} />
        <Tab label="SHIPPING ADDRESSES" sx={{ fontWeight: "bold" }} />
        <Tab label="MY ORDERS" sx={{ fontWeight: "bold" }} />
        <Tab label="MY RETURNS" sx={{ fontWeight: "bold" }} />
      </Tabs>
      <Box sx={{ p: 3 }}>{renderTabContent(value)}</Box>
    </Box>
  );
}
