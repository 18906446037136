import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditUserAddressDialog({ data, setData }) {
  const [open, setOpen] = React.useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [error, setError] = useState("");
  const [Loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const IsLoggedIn = JSON.parse(localStorage.getItem("User"));

  const handleClose = () => {
    setOpen(false);
  };

  const handlePincodeChange = async (event) => {
    const postalCode = event.target.value;

    try {
      const isValidPincode = /^[1-9][0-9]{5}$/.test(postalCode);
      if (isValidPincode) {
        axios
          .get(`https://api.postalpincode.in/pincode/${postalCode}`)
          .then((response) => {
            const postOffice = response.data[0].PostOffice[0];
            const city = postOffice.Block;
            const state = postOffice.State;
            const country = postOffice.Country;
            setCity(city);
            setState(state);
            setCountry(country);
            setError(null);
          })
          .catch((error) => {
            console.error(error);
            setCity("");
            setState("");
            setCountry("");
          });
        setData({ ...data, pincode: postalCode });
      } else {
        setCity("");
        setState("");
        setCountry("");
        setData({ ...data, pincode: postalCode });
        setError("Invalid PIN code");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserInformation = async () => {
    setLoading(true);
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/buyer/userInfo/${IsLoggedIn._id}`,
        {
          name: data.name,
          userEmail: data.userEmail,
          contactNumber: data.contactNumber,
          city: city ? city : data.city,
          state: state ? state : data.state,
          country: country ? country : data.country,
          pincode: data.pincode,
        }
      );
      if (res) {
        // console.log(res);
        toast.success("Information Updated SuccessFully", {
          position: "top-right",
        });
        setData({
          ...data,
          city: city ? city : data.city,
          state: state ? state : data.state,
          country: country ? country : data.country,
        });
        handleClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          style={{ backgroundColor: "#0099CC" }}
          sx={{ width: "200px" }}
          disableElevation
          onClick={handleClickOpen}
        >
          EDIT
        </Button>
      </Typography>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullScreen
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Update My ProFIle"}</DialogTitle> */}
        <Typography sx={{ textAlign: "end", mr: 4, mt: 3 }}>
          <IconButton onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </Typography>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 5,
                }}
              >
                <Card
                  sx={{ width: "450px", p: 2, border: "1px solid #ddd" }}
                  elevation={0}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Edit User Information
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography>FullName</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter Full Name"
                      size="small"
                      value={data.name}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                      sx={{ mt: 0.5, mb: 2 }}
                    />
                  </Box>
                  <Box>
                    <Typography>Email Id</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter Email Id"
                      size="small"
                      value={data?.userEmail}
                      sx={{ mt: 0.5, mb: 2 }}
                      onChange={(e) =>
                        setData({ ...data, userEmail: e.target.value })
                      }
                    />
                  </Box>
                  <Box>
                    <Typography>contact Number</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter contact Number"
                      size="small"
                      value={data.contactNumber}
                      sx={{ mt: 0.5, mb: 2 }}
                      onChange={(e) =>
                        setData({ ...data, contactNumber: e.target.value })
                      }
                    />
                  </Box>
                  <Box>
                    <Typography>PinCode</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter Pincode"
                      size="small"
                      sx={{ mt: 0.5, mb: 2 }}
                      error={error}
                      helperText={error}
                      value={data.pincode}
                      onChange={handlePincodeChange}
                    />
                  </Box>
                  <Box>
                    <Typography>City</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter City"
                      size="small"
                      value={city ? city : data.city}
                      sx={{ mt: 0.5, mb: 2 }}
                    />
                  </Box>
                  <Box>
                    <Typography>State</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter State"
                      size="small"
                      value={state ? state : data.state}
                      sx={{ mt: 0.5, mb: 2 }}
                    />
                  </Box>
                  <Box>
                    <Typography>Country</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter Country"
                      size="small"
                      value={country ? country : data.country}
                      sx={{ mt: 0.5, mb: 2 }}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleUserInformation}
                    disabled={Loading}
                  >
                    {Loading ? <CircularProgress size={24} /> : "SUBMIT"}
                  </Button>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
