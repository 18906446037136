import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Box,
  DialogActions,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import ProductRating from "./Rating";
import { createNewRating } from "../../axios";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RatingAndReviewsDialog({ setRefresh }) {
  const [open, setOpen] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  const [valueForMoney, setvalueForMoney] = useState(0);
  const [Performence, setPerformence] = useState(0);
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Description, setDescription] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { id } = useParams();
  const isLoggedIn = JSON.parse(localStorage.getItem("User"));

  const handleCreateNewRating = async () => {
    try {
      const res = await createNewRating(
        Name,
        Email,
        Description,
        ratingValue,
        valueForMoney,
        Performence,
        id,
        isLoggedIn._id,
        new Date().toLocaleDateString()
      );
      if (res) {
        setRefresh((refresh) => !refresh);
        toast.success("Review Done SuccessFully", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Typography sx={{ textAlign: "center" }}>
        <Button
          disableElevation
          variant="contained"
          style={{
            backgroundColor: "#0071c2",
            color: "white",
            height: "50px",
            fontWeight: "bold",
          }}
          onClick={handleClickOpen}
        >
          RATE THIS PRODUCT
        </Button>
      </Typography>
      <Dialog open={open} fullWidth TransitionComponent={Transition}>
        <Box sx={{ background: "#000", p: 1.5 }}>
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            Write Reviews
          </Typography>
        </Box>

        <DialogContent>
          <Box sx={{ p: 2 }}>
            <Box>
              <Typography>Name:</Typography>
              <TextField
                size="small"
                placeholder="Name"
                fullWidth
                sx={{ mt: 0.5, mb: 2 }}
                value={Name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
            <Box>
              <Typography>Email:</Typography>
              <TextField
                size="small"
                placeholder="Email"
                fullWidth
                sx={{ mt: 0.5, mb: 2 }}
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
            <Box>
              <Typography>Description:</Typography>
              <textarea
                size="small"
                placeholder="Description"
                fullWidth
                style={{
                  marginTop: "5px",
                  marginBottom: "20px",
                  width: "96%",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  resize: "vertical",
                  padding: "10px",
                }}
                value={Description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
            <Box>
              <Typography>Quality of product:</Typography>
              <ProductRating
                value={ratingValue}
                onHandleChange={setRatingValue}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography>Value for Money:</Typography>
              <ProductRating
                value={valueForMoney}
                onHandleChange={setvalueForMoney}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography>Performence:</Typography>
              <ProductRating
                value={Performence}
                onHandleChange={setPerformence}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ backgroundColor: "#000" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleCreateNewRating();
              handleClose();
            }}
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
