import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Grid } from "@mui/material";
import Breadcrumb from "../BreadCrumb";
import MainProductData from "./MainProductData";
import ProductDetailsData from "./ProductDetailsData";
import CreditBanner from "./CreditBanner";
import ProductDescription from "./ProductDescription";
import ProductSpecifications from "./ProductSpecifications";
import RatingsReviews from "./RatingAndReviews";
import QuestionsAboutProduct from "./QuestionsAboutProduct";
import MostSimilar from "./MostSimilar";
import RecentlyViewed from "./RecentlyViewed";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getAllPolicy } from "../../axios";

const ProductDetails = ({ data, colorAttributes }) => {
  const [singleProductData, setSingleProductData] = useState({});
  const [similarProducts, setSimilarProducts] = useState([]);
  const [ProductList, setProductList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [selectedColor, setSelectedColor] = useState(
    singleProductData?.Colors && singleProductData.Colors[0]
  );

  const [returnPolcy, setRetrunPolicy] = useState([]);
  const [policyDescription, setPolicyDescription] = useState("");

  // console.log(similarProducts);

  const handleGetProductsByMe = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/get-all-Products"
      );
      if (res) {
        setProductList(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetProductsByMe();
  }, []);

  const { id } = useParams();
  const dispatch = useDispatch();

  const calculateSimilarity = (productA, productB) => {
    const titleSimilarity = productA.title === productB.title ? 1 : 0;
    const priceSimilarity =
      Math.abs(productA.price - productB.price) /
      Math.max(productA.price, productB.price);

    // Calculate the overall similarity score
    const similarityScore = (titleSimilarity + priceSimilarity) / 2;

    return similarityScore;
  };

  const handleSingleProductlistingById = async () => {
    setLoading1(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/get-single-product/${id}`
      );
      if (res) {
        setSingleProductData(res.data);
        // Assuming productList is available in the response as "productList"
        const similarProducts = ProductList.filter(
          (product) =>
            product._id !== res.data._id &&
            (product.BrandName.includes(res.data.BrandName) ||
              product.Description.includes(res.data.Description) ||
              Math.abs(product.mrp - res.data.mrp) /
                Math.max(product.mrp, res.data.mrp) <
                0.2)
        );

        setSimilarProducts(similarProducts);

        // console.log(similarProducts); // Add this line to console log similar products
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    handleSingleProductlistingById();
  }, [id, refresh]);

  const handleGetAllPolicies = async () => {
    try {
      const res = await getAllPolicy();
      if (res) {
        setRetrunPolicy(res);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleGetAllPolicies();
  }, [singleProductData]);

  useEffect(() => {
    returnPolcy.map((item) => {
      if (item.Heading === singleProductData.returnPolicy) {
        setPolicyDescription(item.Description);
      }
    });
  }, [singleProductData.returnPolicy, returnPolcy]);

  const recentlyViewedItems = useSelector((state) => state.recentlyViewed);

  return (
    <div>
      {!Loading && !Loading1 ? (
        <Box sx={{ p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Breadcrumb singeProductData={singleProductData} />
            </Grid>
            <Grid item xs={12} lg={12}>
              <ProductDetailsData
                data={data}
                singeProductData={singleProductData}
                setSelectedColor={setSelectedColor}
                selectedColor={selectedColor}
                policyDescription={policyDescription}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <CreditBanner data={data} singleProductData={singleProductData} />
            </Grid>
            <Grid item xs={12} lg={12}>
              <ProductDescription
                data={data}
                singleProductData={singleProductData}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <ProductSpecifications
                data={data}
                singeProductData={singleProductData}
                policyDescription={policyDescription}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <RatingsReviews data={data} setRefresh={setRefresh} />
            </Grid>
            <Grid item xs={12} lg={12}>
              <QuestionsAboutProduct
                data={data}
                sellerId={singleProductData?.createdBy?._id}
              />
            </Grid>
            {recentlyViewedItems && (
              <Grid item xs={12} lg={12}>
                <RecentlyViewed data={data} colorAttributes={colorAttributes} />
              </Grid>
            )}

            <Grid item xs={12} lg={12}>
              <MostSimilar
                data={data}
                similarProducts={similarProducts}
                colorAttributes={colorAttributes}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ProductDetails;
