import {
  Grid,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { ServiceSidebarData } from "../ProductList/SidebarData";

const Sidebar = ({
  onPriceRangeChange,
  colorAttributes,
  onColorSelection,
  selectedColors,
}) => {
  const [show, setShow] = useState({});
  const [selectedPriceRanges, setSelectedPriceRanges] = useState([]);

  const handleShow = (index) => {
    setShow((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handlePriceRangeChange = (event) => {
    const priceRange = event.target.value;
    if (event.target.checked) {
      setSelectedPriceRanges((prev) => [...prev, priceRange]);
    } else {
      setSelectedPriceRanges((prev) =>
        prev.filter((range) => range !== priceRange)
      );
    }
  };

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    onPriceRangeChange(selectedPriceRanges);
  }, [selectedPriceRanges, onPriceRangeChange]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        {!isMediumScreen ? (
          <Box sx={{ ml: 3, mt: 2 }}>
            {ServiceSidebarData.map((item, index) => (
              <React.Fragment key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontSize: "18px", margin: "10px" }}>
                    {item.name}
                  </Typography>
                  {!show[index] ? (
                    <AddCircleOutlineOutlinedIcon
                      style={{
                        color: "gray",
                        cursor: "pointer",
                        marginLeft: "auto",
                        marginRight: "20px",
                      }}
                      onClick={() => handleShow(index)}
                    />
                  ) : (
                    <RemoveCircleOutlineOutlinedIcon
                      style={{
                        color: "gray",
                        cursor: "pointer",
                        marginLeft: "auto",
                        marginRight: "20px",
                      }}
                      onClick={() => handleShow(index)}
                    />
                  )}
                </div>
                <Divider width="200px" />
                {show[index] ? (
                  <Box
                    className="animate__animated animate__fadeIn"
                    style={{ padding: "10px" }}
                  >
                    {item.name === "Color" && (
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {colorAttributes.map((category) => (
                          <Tooltip title={category.name}>
                            <img
                              key={category.id}
                              src={category.image}
                              alt={category.name}
                              style={{
                                width: "24px",
                                height: "24px",

                                border: selectedColors.includes(category.name)
                                  ? "2px solid #000"
                                  : "2px solid #ddd",
                                borderRadius: "50%",
                                padding: "2px",
                                cursor: "pointer",
                              }}
                              onClick={() => onColorSelection(category.name)}
                            />
                          </Tooltip>
                        ))}
                      </Box>
                    )}
                    {item.name !== "Color" && (
                      <FormGroup variant="standard">
                        {item.children.map((category) => (
                          <FormControlLabel
                            key={category.id}
                            control={
                              <Checkbox
                                onChange={handlePriceRangeChange}
                                value={category.name}
                              />
                            }
                            label={category.name}
                          />
                        ))}
                      </FormGroup>
                    )}
                  </Box>
                ) : null}
              </React.Fragment>
            ))}
          </Box>
        ) : (
          //   <SideBarDrawer />
          ""
        )}
      </div>
    </ThemeProvider>
  );
};

export default Sidebar;
