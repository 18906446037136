import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import NewAddressDialog from "../checkout/NewAddressDialog";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ShippingAddress = () => {
  const isLoggedIn = JSON.parse(localStorage.getItem("User"));

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const handleGetShippingAddressByLoggedInUser = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/handleGetShippingAddressByUser/${isLoggedIn._id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
        handleGetShippingAddressByLoggedInUser();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const [selected, setSelected] = useState(data && data?.[0]?.TypeOfAddress);

  //   console.log(selected);

  useEffect(() => {
    setSelected(data?.[0]?.TypeOfAddress);
  }, []);

  const handleRemoveAddress = async (id) => {
    try {
      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/buyer/RemoveAddress/${id}`
      );
      if (res.data.message) {
        toast.success(res.data.message, {
          position: "top-right",
        });
        setData(data?.filter((address) => address._id !== id));
      } else {
        toast.error(res.data.error, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetShippingAddressByLoggedInUser();
  }, []);
  return (
    <div>
      {!Loading ? (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={7}>
              <Box>
                <Box>
                  <Box>
                    {data?.map((item) => (
                      <>
                        <Box
                          key={item.TypeOfAddress}
                          sx={{
                            border: "1px solid #ddd",
                            mb: 2,
                            borderRadius: "5px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                              p: 2,
                            }}
                          >
                            <Box sx={{ display: "flex" }}>
                              <Box>
                                <Typography
                                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                                >
                                  {item.FirstName} {item.LastName}
                                </Typography>
                                <Typography sx={{ mt: 1 }}>
                                  {item.Address}
                                </Typography>
                                <Typography sx={{ mt: 1 }}>
                                  {item.LandMark}
                                </Typography>
                                <Typography sx={{ mt: 1 }}>
                                  {item.City} - {item.PostCode}
                                </Typography>
                                <Typography sx={{ mt: 1 }}>
                                  {item.State}, {item.Country}
                                </Typography>
                                <Typography sx={{ mt: 1 }}>
                                  Mobile : {item.Mobile}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                border: "1px solid #ddd",
                                p: 0.5,

                                borderRadius: "50px",
                                background: "#eee",
                              }}
                            >
                              <Typography
                                sx={{
                                  textAlign: "center",
                                  fontSize: "14px",
                                  ml: 1,
                                  mr: 1,
                                }}
                              >
                                {" "}
                                {item.TypeOfAddress}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            sx={{
                              p: 1,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              sx={{ color: "gray" }}
                              onClick={() => handleRemoveAddress(item._id)}
                            >
                              REMOVE
                            </Button>
                            <Button
                              sx={{ color: "gray" }}
                              onClick={() =>
                                navigate(`/UpdateAddress/${item._id}`)
                              }
                            >
                              EDIT
                            </Button>
                          </Box>
                        </Box>
                      </>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Box>
                <Box
                  sx={{
                    border: "1px solid #ddd",
                    p: 2,
                    borderRadius: "5px",
                    height: "237px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "100%",
                    }}
                    //   onClick={}
                  >
                    <NewAddressDialog />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ShippingAddress;
