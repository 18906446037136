import { Route, Routes, useLocation, useParams } from "react-router-dom";
import "./App.css";
import HomePage from "./components/HomePage";
import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import Topbar from "./components/Topbar";
import Header from "./components/Header";
import Carousel from "react-material-ui-carousel";
import PreFooter from "./components/PreFooter";
import SubFooter from "./components/SubFooter";
import Footer from "./components/Footer";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import Navbar from "./components/Navbar/Navbar";
import ProductList from "./components/ProductList/ProductList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cart from "./components/Cart/Cart";
import Login from "./components/Login/Login";
import UserInformation from "./components/UserData/UserInformation";
import { useSelector } from "react-redux";
import WishList from "./components/WishList/WishList";
import SellerProductList from "./components/SellerProductList/SellerProductList";
import Checkout from "./components/checkout/Checkout";
import Payment from "./components/Payment/Payment";
import MyAccount from "./components/MyAccount/MyAccount";
import UpdateShippingAddress from "./components/checkout/UpdateShippingAddress";
import BrandShop from "./components/BrandShop/BrandShop";
import BrandDetails from "./components/BrandShop/BrandDetails";
import SearchResults from "./components/searchResults/SearchResults";
import Search from "./components/searchResults/Search";
import Success from "./components/Payment/Success";
import UpdateAddress from "./components/MyAccount/UpdateAddress";
import StoreUnderConstruction from "./StoreUnderConstruction";
import {
  getAllNavbarItems,
  handleBrandAttributes1,
  handleGetAllHomepageModules1,
  handleGetJosnDataForHomePage1,
} from "./axios";
import { useLayoutEffect } from "react";
import EditProfile from "./components/MyAccount/EditProfile";
import NewAddress from "./components/MyAccount/NewAddress";

// ...imports

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  const [storeProfile, setStoreProfile] = useState({});
  const [data, setData] = useState([]);
  const [modules, setModules] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [allNavbarItems, setAllNavbarItems] = useState([]);

  const isLoggedIn = JSON.parse(localStorage.getItem("User"));
  const { user } = useSelector((state) => state);

  const handleGetJosnDataForHomePage = async () => {
    setLoading(true);
    try {
      const res = await handleGetJosnDataForHomePage1();
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetAllHomepageModules = async () => {
    setLoading1(true);
    try {
      const res = await handleGetAllHomepageModules1();
      if (res.status === 200) {
        setModules(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  const handleGetAllNavbarItems = async () => {
    try {
      const res = await getAllNavbarItems();
      if (res.status === 200) {
        setAllNavbarItems(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    Promise.all([
      handleGetJosnDataForHomePage(),
      handleGetAllHomepageModules(),
      handleGetAllNavbarItems(),
    ]);
  }, []);

  return (
    <Wrapper>
      {Loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              position: "sticky",
              top: 0,
              zIndex: 99,
            }}
          >
            {modules.map((module) => {
              if (module.moduleType === "Topbar") {
                return <Topbar key={module._id} data={data} />;
              }
              if (module.moduleType === "Header") {
                return <Header key={module._id} data={data} />;
              }
              if (module.moduleType === "Navbar") {
                return (
                  <Navbar
                    key={module._id}
                    data={data}
                    allNavbarItems={allNavbarItems}
                  />
                );
              }

              return null;
            })}
          </Box>
          <Routes>
            <Route
              path="/"
              element={<HomePage data={data} modules={modules} />}
            />
            <Route path="/login" element={<Login />} />
            <Route
              path={`/UserInformation/:id`}
              element={<UserInformation />}
            />
            <Route
              path="/product-details/:name/:id"
              element={
                <ProductDetails
                  data={data}
                  // colorAttributes={colorAttributes}
                  // cartItems={cartItems}
                />
              }
            />
            <Route
              path={`/list/:name`}
              element={
                <ProductList
                  data={data}
                  // colorAttributes={colorAttributes}
                  // updateCartItemQuantityAPI={updateCartItemQuantityAPI}
                  // updatedCartData={updatedCartData}
                  // handleGetCartItemsByUser={handleGetCartItemsByUser}
                  // setCartItems={setCartItems}
                />
              }
            />
            <Route path={`/cart`} element={<Cart data={data} />} />

            <Route path={`/wishlist`} element={<WishList />} />
            <Route
              path={`/sellerProductList/:id`}
              element={<SellerProductList />}
            />
            <Route path={`/Checkout`} element={<Checkout />} />
            <Route path={`/payment`} element={<Payment />} />
            <Route path={`/MyAccount`} element={<MyAccount />} />
            <Route path={`/new-Address`} element={<NewAddress />} />
            <Route path={`/searchResults`} element={<Search />} />
          </Routes>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ flex: 1 }}></div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {modules.map((module) => {
                if (module.moduleName === "Pre Footer") {
                  return <PreFooter key={module._id} data={data} />;
                }
                if (module.moduleName === "Sub Footer") {
                  return <SubFooter key={module._id} data={data} />;
                }
                return null;
              })}
              <Footer />
            </Box>
          </div>
        </>
      )}
    </Wrapper>
  );
}

export default App;
