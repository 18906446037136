import { Box, Typography } from "@mui/material";
import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";

const Footer = () => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          mt: 2,
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "14px", color: "gray" }}>
          Copyright © 2023, skillShop.in, All Rights Reserved
        </Typography>

        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ display: "flex", alignItems: "flex-end", fontSize: "14px" }}
          >
            Made with
          </Typography>
          <FavoriteIcon sx={{ color: "red", fontSize: "24px" }} />
          <Typography
            sx={{ display: "flex", alignItems: "flex-end", fontSize: "14px" }}
          >
            in india
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Footer;
