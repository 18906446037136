import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { handleMeagaMenuJsonData } from "../../axios";

const Navbar = ({ allNavbarItems }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [MegaMenuData, setMegaMenuData] = useState([]);

  const handleMegaMenu = async () => {
    try {
      const res = await handleMeagaMenuJsonData();

      if (res.status === 200) {
        setMegaMenuData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Promise.all([handleMegaMenu()]);
  }, []);

  let closeTimeoutId;

  const handleHover = (index) => {
    setIsHovered(true);
    const activeItem = allNavbarItems[index];
    const matchingCategory = MegaMenuData.menu.category.find(
      (category) => category.name[0] === activeItem.Heading
    );
    clearTimeout(closeTimeoutId);
    if (matchingCategory) {
      setActiveCategory(matchingCategory);
    }
  };

  const handleMouseLeave = () => {
    closeTimeoutId = setTimeout(() => {
      setIsHovered(false);
      setActiveCategory(null);
    }, 300);
  };

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ThemeProvider theme={theme}>
      {!isMediumScreen ? (
        <Box
          sx={{
            boxShadow: 0.5,
            display: "flex",
            alignItems: "center",
            background: "#fff",
            mt: -1.3,
            p: 2,
            border: "1px solid #ddd",
            gap: "10px",
          }}
        >
          {allNavbarItems.map((item, index) => {
            return (
              <Box
                key={index}
                onMouseEnter={() => handleHover(index)}
                onMouseLeave={handleMouseLeave}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    cursor: "pointer",
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                >
                  {item.Heading}
                </Typography>
                {isHovered &&
                  activeCategory &&
                  activeCategory.name[0] === item.Heading && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "100%",
                        left: -3,
                        width: "100%",
                        background: "#fff",
                        border: "1px solid #ddd",
                        // mt: -1,
                        // p: 1.8,
                      }}
                    >
                      <Grid container spacing={1} sx={{ p: 3 }}>
                        {activeCategory.subcategory.map(
                          (subcategory, subIndex) => (
                            <Grid item xs={12} lg={2} xl={2} key={subIndex}>
                              <Box sx={{ pb: 1 }}>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    color: "#0099cc",
                                  }}
                                >
                                  {subcategory.name[0]}
                                </Typography>
                                {subcategory.item && (
                                  <Box>
                                    {subcategory.item.map((item, itemIndex) => (
                                      <Link
                                        to={item.href[0]}
                                        style={{
                                          textDecoration: "none",
                                          color: "#000",
                                        }}
                                        key={itemIndex}
                                      >
                                        <Typography
                                          sx={{ fontSize: "14px" }}
                                          className="childrenNavbar"
                                        >
                                          {item.name[0]}
                                        </Typography>
                                      </Link>
                                    ))}
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Box>
                  )}
              </Box>
            );
          })}
        </Box>
      ) : null}
    </ThemeProvider>
  );
};

export default Navbar;
