import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const isLoggedIn = JSON.parse(localStorage.getItem("User"));

// console.log(isLoggedIn._id);

const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
};

const CartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      console.log(action.payload);
      const itemIndex = state.cartItems.findIndex(
        (item) =>
          item.uniqueId === action.payload.uniqueId &&
          item.selectedColor === action.payload.selectedColor
      );

      if (itemIndex >= 0) {
        state.cartItems[itemIndex].cartQuantity += 1;
      } else {
        const tempProduct = { ...action.payload, cartQuantity: 1 };
        state.cartItems.push(tempProduct);
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    removeFromCart(state, action) {
      const { _id } = action.payload;
      console.log(action.payload);

      const nextCartItems = state.cartItems.filter(
        (cartItem) => cartItem._id !== _id
      );

      state.cartItems = nextCartItems;
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      toast.success(`Item Removed from Cart Successfully`, {
        position: "top-right",
      });
    },

    decreaseCart(state, action) {
      // const { _id, selectedColor } = action.payload.productId;

      const itemIndex = state.cartItems.findIndex(
        (item) =>
          item.uniqueId === action.payload.uniqueId &&
          item.selectedColor === action.payload.selectedColor
      );

      if (itemIndex >= 0) {
        if (state.cartItems[itemIndex].cartQuantity > 1) {
          state.cartItems[itemIndex].cartQuantity -= 1;
          // if (isLoggedIn) {
          //   const updateCartItemQuantityAPI = async () => {
          //     try {
          //       const updateCart = await axios.put(
          //         `https://rccore-backend.cstechns.com/api/buyer/updateCartItemQuantity/${action.payload.uniqueId}/${action.payload.selectedColor}/${isLoggedIn._id}`,
          //         {
          //           quantity: state.cartItems[itemIndex].cartQuantity,
          //         }
          //       );
          //       if (updateCart) {
          //         // console.log(updateCart);
          //       }
          //     } catch (error) {
          //       console.log(error);
          //     }
          //   };
          //   updateCartItemQuantityAPI();
          // }
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      }
    },

    // updateCartItems(state, action) {
    //   state.cartItems = action.payload;

    //   console.log(action.payload);

    //   const updateCartItemsAPI = async () => {
    //     try {
    //       const isLoggedIn = JSON.parse(localStorage.getItem("User")); // Replace with your method to retrieve the logged-in user

    //       if (!isLoggedIn) {
    //         return;
    //       }

    //       const existingCartItems = await Promise.all(
    //         action.payload.map(async (item) => {
    //           console.log(item);
    //           const response = await axios.get(
    //             `https://rccore-backend.cstechns.com/api/buyer/getCartItem/${isLoggedIn._id}/${item.uniqueId}/${item.selectedColor}`
    //           );
    //           return response.data;
    //         })
    //       );

    //       console.log(existingCartItems);

    //       const newCartItems = action.payload.filter(
    //         (item, index) => !existingCartItems[index]
    //       );

    //       if (newCartItems.length > 0) {
    //         const addToCartPromises = newCartItems.map((item) =>
    //           axios.post("https://rccore-backend.cstechns.com/api/buyer/addToCart", {
    //             productId: item.productId,
    //             userId: isLoggedIn._id,
    //             quantity: item.quantity,
    //             selectedColor: item.selectedColor,
    //             uniqueId: item.uniqueId,
    //           })
    //         );

    //         await Promise.all(addToCartPromises);
    //         toast.success("Items Pushed Successfully", {
    //           position: "top-right",
    //         });
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   };

    //   updateCartItemsAPI();

    //   localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    // },
  },
});

export const { addToCart, removeFromCart, decreaseCart } = CartSlice.actions;

export default CartSlice.reducer;
