import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";

const FooterBanners = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "20px" }}>
      <Grid container spacing={2} sx={{ px: 3 }}>
        {data?.footerbannerimages
          ?.sort((a, b) => a.sortingOrder - b.sortingOrder)
          .map((item, index) => {
            if (item.visible === true) {
              return (
                <Grid item xs={6} sm={3} md={6} lg={2} key={index}>
                  <Card sx={{ height: "100%", width: "100%" }}>
                    <img
                      src={item.image}
                      alt=""
                      className="cardimg"
                      height="100%"
                      width="100%"
                      onClick={() => navigate(item.url)}
                    />
                  </Card>
                </Grid>
              );
            }
          })}
      </Grid>
    </div>
  );
};

export default FooterBanners;
