import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const isLoggedIn = JSON.parse(localStorage.getItem("User"));

const UpdateShippingAddress = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const { id } = useParams();
  const getShippingAddressById = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/getShippingAddressById/${id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateAddress = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/buyer/updateShippingAddress/${id}`,
        {
          FirstName: data.FirstName,
          LastName: data.LastName,
          CompanyName: data.CompanyName,
          Address: data.Address,
          GSTIN: data.GSTIN,
          City: city ? city : data.City,
          PostCode: data.PostCode,
          State: state ? state : data?.State,
          Country: country ? country : data.Country,
          Mobile: data.Mobile,
          LandMark: data.LandMark,
          TypeOfAddress: data.TypeOfAddress,
          defaultAddress: data.defaultAddress,
        }
      );
      if (res) {
        toast.success("Address Updated Successfully", {
          position: "top-right",
        });
        navigate("/Checkout");
      } else {
        toast.error("Address Update Failed", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getShippingAddressById();
  }, []);

  const handlePincodeChange = async (event) => {
    const postalCode = event.target.value;

    try {
      const isValidPincode = /^[1-9][0-9]{5}$/.test(postalCode);
      if (isValidPincode) {
        axios
          .get(`https://api.postalpincode.in/pincode/${postalCode}`)
          .then((response) => {
            const postOffice = response.data[0].PostOffice[0];
            const city = postOffice.Block;
            const state = postOffice.State;
            const country = postOffice.Country;
            setCity(city);
            setState(state);
            setCountry(country);
            // setError(null);
          })
          .catch((error) => {
            console.error(error);
            setCity("");
            setState("");
            setCountry("");
          });
        setData((e) => ({ ...data, PostCode: postalCode }));
      } else {
        setCity("");
        setState("");
        setCountry("");
        setData((e) => ({ ...data, PostCode: postalCode }));
        // setError("Invalid PIN code");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} lg={8}>
          <Card sx={{ border: "1px solid #ddd", mt: 3 }} elevation={0}>
            <Box sx={{ p: 2 }}>
              <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                UPDATE SHIPPING ADDRESS
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px", mt: 2 }}>
              <Grid item xs={12} lg={5.8}>
                <Box>
                  <Typography>First Name :</Typography>
                  <TextField
                    placeholder="First Name"
                    fullWidth
                    size="small"
                    value={data?.FirstName}
                    onChange={(e) =>
                      setData({ ...data, FirstName: e.target.value })
                    }
                    sx={{ mb: 2, mt: 0.5 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={5.8}>
                <Box>
                  <Typography>Last Name :</Typography>
                  <TextField
                    placeholder="Last Name"
                    fullWidth
                    size="small"
                    value={data?.LastName}
                    onChange={(e) =>
                      setData({ ...data, LastName: e.target.value })
                    }
                    sx={{ mb: 2, mt: 0.5 }}
                  />
                </Box>
              </Grid>
            </Box>
            <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px" }}>
              <Grid item xs={12} lg={5.8}>
                <Box>
                  <Typography>Company Name :</Typography>
                  <TextField
                    placeholder="Company Name"
                    fullWidth
                    size="small"
                    value={data?.CompanyName}
                    onChange={(e) =>
                      setData({ ...data, CompanyName: e.target.value })
                    }
                    sx={{ mb: 2, mt: 0.5 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={5.8}>
                <Box>
                  <Typography>GSTIN :</Typography>
                  <TextField
                    placeholder="GSTIN"
                    fullWidth
                    size="small"
                    value={data?.GSTIN}
                    onChange={(e) =>
                      setData({ ...data, GSTIN: e.target.value })
                    }
                    sx={{ mb: 2, mt: 0.5 }}
                  />
                </Box>
              </Grid>
            </Box>
            <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px" }}>
              <Grid item xs={12} lg={12}>
                <Box>
                  <Typography>Address :</Typography>
                  <TextField
                    placeholder="Address"
                    multiline
                    fullWidth
                    size="small"
                    value={data?.Address}
                    onChange={(e) =>
                      setData({ ...data, Address: e.target.value })
                    }
                    sx={{ mb: 2, mt: 0.5 }}
                  />
                </Box>
              </Grid>
            </Box>
            <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px" }}>
              <Grid item xs={12} lg={5.8}>
                <Box>
                  <Typography>PostCode :</Typography>
                  <TextField
                    placeholder="PostCode"
                    fullWidth
                    size="small"
                    value={data?.PostCode}
                    onChange={handlePincodeChange}
                    sx={{ mb: 2, mt: 0.5 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={5.8}>
                <Box>
                  <Typography>City :</Typography>
                  <TextField
                    placeholder="City"
                    fullWidth
                    size="small"
                    value={city ? city : data?.City}
                    sx={{ mb: 2, mt: 0.5 }}
                  />
                </Box>
              </Grid>
            </Box>
            <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px" }}>
              <Grid item xs={12} lg={5.8}>
                <Box>
                  <Typography>State :</Typography>
                  <TextField
                    placeholder="State"
                    fullWidth
                    size="small"
                    value={state ? state : data?.State}
                    sx={{ mb: 2, mt: 0.5 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={5.8}>
                <Box>
                  <Typography>Country :</Typography>
                  <TextField
                    placeholder="Country"
                    fullWidth
                    size="small"
                    value={country ? country : data?.Country}
                    sx={{
                      mb: 2,
                      mt: 0.5,
                    }}
                  />
                </Box>
              </Grid>
            </Box>
            <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px" }}>
              <Grid item xs={12} lg={5.8}>
                <Box>
                  <Typography>Mobile :</Typography>
                  <TextField
                    placeholder="Mobile"
                    fullWidth
                    size="small"
                    value={data?.Mobile}
                    onChange={(e) =>
                      setData({ ...data, Mobile: e.target.value })
                    }
                    sx={{ mb: 2, mt: 0.5 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={5.8}>
                <Box>
                  <Typography>LandMark :</Typography>
                  <TextField
                    placeholder="LandMark"
                    fullWidth
                    size="small"
                    value={data?.LandMark}
                    onChange={(e) =>
                      setData({ ...data, LandMark: e.target.value })
                    }
                    sx={{ mb: 2, mt: 0.5 }}
                  />
                </Box>
              </Grid>
            </Box>
            <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px" }}>
              <Grid item xs={12} lg={5.8}>
                <Box sx={{ mb: 2 }}>
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      sx={{ color: "black" }}
                    >
                      Types of Address
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          TypeOfAddress: e.target.value,
                        }))
                      }
                    >
                      <FormControlLabel
                        value="Home"
                        control={<Radio />}
                        label="Home"
                        checked={data?.TypeOfAddress === "Home"}
                      />
                      <FormControlLabel
                        value="Office/Commercial"
                        control={<Radio />}
                        label="Office/Commercial"
                        checked={data?.TypeOfAddress === "Office/Commercial"}
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Other"
                        checked={data?.TypeOfAddress === "other"}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Box>

            <Box sx={{ pr: 2, pl: 1, mb: 2 }}>
              <Grid item xs={12} lg={5.8}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={!!data?.defaultAddress} // Convert the value to a boolean
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        defaultAddress: e.target.checked ? true : false,
                      }))
                    }
                  />
                  <Typography>Make this my default address:</Typography>
                </Box>
              </Grid>
            </Box>
            <Box sx={{ pl: 2, pr: 2, mb: 2 }}>
              <Grid item xs={12} lg={5.8}>
                <Button
                  variant="contained"
                  sx={{ p: 1, width: "300px" }}
                  onClick={handleUpdateAddress}
                >
                  SUBMIT
                </Button>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdateShippingAddress;
