import { Paper } from "@mui/material";
import React from "react";
import { Carousel } from "react-carousel-minimal";

const FullBanners = ({ data }) => {
  const visibleItems = data?.themecustomizationimages?.filter(
    (item) => item.visible === true
  );

  return (
    <div style={{ marginTop: "1px" }}>
      {visibleItems.length > 0 && (
        <Carousel
          data={visibleItems}
          time={3000}
          width="100%"
          height="100%"
          automatic={true}
          dots={true}
          slideBackgroundColor="darkgrey"
          style={{
            textAlign: "center",
          }}
        />
      )}
    </div>
  );
};

export default FullBanners;
