import { Box } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";

const MidBanners = ({ data }) => {
  const navigate = useNavigate();
  const visibleImages = data?.midbannerimages?.filter(
    (item) => item.visible === true
  );

  if (!visibleImages || visibleImages.length === 0) {
    return null; // Render nothing if there are no visible images
  }

  return (
    <div>
      <Box sx={{ ml: 3, mr: 3, position: "sticky", mt: 4 }}>
        {visibleImages.length >= 2 ? (
          <Carousel sx={{ mt: 0 }}>
            {visibleImages &&
              visibleImages
                .sort((a, b) => a.sortingOrder - b.sortingOrder)
                .map((item, index) => (
                  <img
                    key={index}
                    src={item.image}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                    onClick={() => navigate(item.url)}
                  />
                ))}
          </Carousel>
        ) : (
          <img
            src={visibleImages[0].image}
            alt=""
            style={{ width: "100%", height: "100%" }}
            onClick={() => navigate(visibleImages[0].url)}
          />
        )}
      </Box>
    </div>
  );
};

export default MidBanners;
