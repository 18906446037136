import { Box, Divider, Typography } from "@mui/material";
import React from "react";

const ProductDescription = ({ singleProductData }) => {
  return (
    <div>
      <Box>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Product Description
        </Typography>
        <Divider sx={{ mt: 1 }} />
        <Typography sx={{ mt: 2 }}>{singleProductData.Description}</Typography>
      </Box>
    </div>
  );
};

export default ProductDescription;
