import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const NewAddress = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [GSTIN, setGSTIN] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [mobile, setMobile] = useState("");
  const [landmark, setLandmark] = useState("");
  const [addressType, setAddressType] = useState("Home");
  const [defaultAddress, setDefaultAddress] = useState(false);

  const navigate = useNavigate();

  const isLoggedIn = JSON.parse(localStorage.getItem("User"));
  const location = useLocation();
  const cartItems = location.state?.cartItems;

  const handleShippingAddress = async () => {
    try {
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/buyer/createAddress",
        {
          FirstName: firstName,
          LastName: lastName,
          CompanyName: companyName,
          GSTIN: GSTIN,
          Address: address,
          PostCode: postcode,
          City: city,
          State: state,
          Country: country,
          Mobile: mobile,
          LandMark: landmark,
          TypeOfAddress: addressType,
          defaultAddress: defaultAddress,
          createdBy: isLoggedIn._id,
        }
      );
      if (res) {
        toast.success("Shipping Address Created Successfully", {
          position: "top-right",
        });
        navigate("/Checkout", {
          state: { cartItems: cartItems },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePincodeChange = async (event) => {
    const postalCode = event.target.value;

    try {
      const isValidPincode = /^[1-9][0-9]{5}$/.test(postalCode);
      if (isValidPincode) {
        axios
          .get(`https://api.postalpincode.in/pincode/${postalCode}`)
          .then((response) => {
            const postOffice = response.data[0].PostOffice[0];
            const city = postOffice.Block;
            const state = postOffice.State;
            const country = postOffice.Country;
            setCity(city);
            setState(state);
            setCountry(country);
            // setError(null);
          })
          .catch((error) => {
            console.error(error);
            setCity("");
            setState("");
            setCountry("");
          });
        setPostcode(postalCode);
      } else {
        setCity("");
        setState("");
        setCountry("");
        setPostcode(postalCode);
        // setError("Invalid PIN code");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div>
        <Grid container spacing={2} sx={{ px: 3, mt: 1 }}>
          <Grid item xs={12} lg={12}>
            <Box sx={{ p: 2 }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                YOUR SHIPPING ADDRESS
              </Typography>
            </Box>
            <Card sx={{ border: "1px solid #ddd" }} elevation={0}>
              <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px", mt: 2 }}>
                <Grid item xs={12} lg={12}>
                  <Box>
                    <Typography>First Name :</Typography>
                    <TextField
                      placeholder="First Name"
                      fullWidth
                      size="small"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      sx={{ mb: 2, mt: 0.5 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Box>
                    <Typography>Last Name :</Typography>
                    <TextField
                      placeholder="Last Name"
                      fullWidth
                      size="small"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      sx={{ mb: 2, mt: 0.5 }}
                    />
                  </Box>
                </Grid>
              </Box>
              <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px" }}>
                <Grid item xs={12} lg={12}>
                  <Box>
                    <Typography>Company Name :</Typography>
                    <TextField
                      placeholder="Company Name"
                      fullWidth
                      size="small"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      sx={{ mb: 2, mt: 0.5 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Box>
                    <Typography>GSTIN :</Typography>
                    <TextField
                      placeholder="GSTIN"
                      fullWidth
                      size="small"
                      value={GSTIN}
                      onChange={(e) => setGSTIN(e.target.value)}
                      sx={{ mb: 2, mt: 0.5 }}
                    />
                  </Box>
                </Grid>
              </Box>
              <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px" }}>
                <Grid item xs={12} lg={12}>
                  <Box>
                    <Typography>Address :</Typography>
                    <TextField
                      placeholder="Address"
                      multiline
                      fullWidth
                      size="small"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      sx={{ mb: 2, mt: 0.5 }}
                    />
                  </Box>
                </Grid>
              </Box>
              <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px" }}>
                <Grid item xs={12} lg={12}>
                  <Box>
                    <Typography>PostCode :</Typography>
                    <TextField
                      placeholder="PostCode"
                      fullWidth
                      size="small"
                      value={postcode}
                      onChange={handlePincodeChange}
                      sx={{ mb: 2, mt: 0.5 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Box>
                    <Typography>City :</Typography>
                    <TextField
                      placeholder="City"
                      fullWidth
                      size="small"
                      value={city}
                      sx={{ mb: 2, mt: 0.5 }}
                    />
                  </Box>
                </Grid>
              </Box>
              <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px" }}>
                <Grid item xs={12} lg={12}>
                  <Box>
                    <Typography>State :</Typography>
                    <TextField
                      placeholder="State"
                      fullWidth
                      size="small"
                      value={state}
                      sx={{ mb: 2, mt: 0.5 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Box>
                    <Typography>Country :</Typography>
                    <TextField
                      placeholder="Country"
                      fullWidth
                      size="small"
                      value={country}
                      sx={{
                        mb: 2,
                        mt: 0.5,
                      }}
                    />
                  </Box>
                </Grid>
              </Box>
              <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px" }}>
                <Grid item xs={12} lg={12}>
                  <Box>
                    <Typography>Mobile :</Typography>
                    <TextField
                      placeholder="Mobile"
                      fullWidth
                      size="small"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      sx={{ mb: 2, mt: 0.5 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Box>
                    <Typography>LandMark :</Typography>
                    <TextField
                      placeholder="LandMark"
                      fullWidth
                      size="small"
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                      sx={{ mb: 2, mt: 0.5 }}
                    />
                  </Box>
                </Grid>
              </Box>
              <Box sx={{ pl: 2, pr: 2, display: "flex", gap: "30px" }}>
                <Grid item xs={12} lg={12}>
                  <Box sx={{ mb: 2 }}>
                    <FormControl>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        sx={{ color: "black" }}
                      >
                        Types of Address
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={addressType} // Set default value to "Home"
                        onChange={(e) => setAddressType(e.target.value)}
                      >
                        <FormControlLabel
                          value="Home"
                          control={<Radio />}
                          label="Home"
                        />
                        <FormControlLabel
                          value="Office/Commercial"
                          control={<Radio />}
                          label="Office/Commercial"
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>
              </Box>

              <Box sx={{ pr: 2, pl: 1, mb: 2 }}>
                <Grid item xs={12} lg={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      value={defaultAddress}
                      onChange={(e) => setDefaultAddress((prev) => !prev)}
                    />
                    <Typography>Make this my default address :</Typography>
                  </Box>
                </Grid>
              </Box>
              <Box sx={{ pl: 2, pr: 2, mb: 2 }}>
                <Grid item xs={12} lg={12}>
                  <Button
                    variant="contained"
                    sx={{ p: 1, width: "300px" }}
                    onClick={() => {
                      handleShippingAddress();
                    }}
                  >
                    SUBMIT
                  </Button>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default NewAddress;
