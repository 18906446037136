import { Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUserSuccess, logoutUser } from "../Redux/Cart/UserSlice";
import axios from "axios";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import { updateCartItems } from "../Redux/Cart/CartSlice";

const Login = () => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [otp, setOtp] = useState("");

  const cartItems = useSelector((state) => state.cart);

  //   console.log(data);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setError(!validateInput(value));
  };

  const handleOtpChange = (e) => {
    if (e.target.value.length === 0) {
      setOtpError("Otp is Required");
    } else if (e.target.value.length < 6) {
      setOtpError("Otp must be 6 a digit Number");
    } else {
      setOtpError("");
    }

    setOtp(e.target.value);
  };

  const handleUserRegister = async () => {
    try {
      let requestData = {};

      if (inputValue.includes("@")) {
        requestData = { email: inputValue };
      } else {
        requestData = { mobile: inputValue };
      }

      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/buyer/register",
        requestData
      );

      if (res.data.otp) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserLogin = async () => {
    try {
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/buyer/login",
        {
          emailOrMobile: inputValue,
          otp: otp,
        }
      );
      if (res.data.message) {
        if (
          res.data.user.name &&
          res.data.user.state &&
          res.data.user.city &&
          res.data.user.country &&
          res.data.user.pincode
        ) {
          navigate("/");
        } else {
          navigate(`/UserInformation/${res.data.user._id}`, { replace: true });
        }
        dispatch(loginUserSuccess(res.data.user));

        // dispatch(updateCartItems(cartItems.cartItems));
      } else {
        toast.error(res.data.error, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   const handleLogout = () => {
  //     dispatch(logoutUser());
  //   };

  const validateInput = (value) => {
    const emailRegex = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const mobileRegex = /^\d{10}$/;

    return emailRegex.test(value) || mobileRegex.test(value);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="70vh"
      >
        <Card sx={{ maxWidth: "450px", p: 2, border: "1px solid #ddd" }}>
          <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "bold", mb: 2 }}>
              Login to Skill Shop
            </Typography>
            <Box>
              <Typography>Enter Email/Mobile Number</Typography>
              <TextField
                fullWidth
                placeholder="Enter Email/Mobile Number"
                sx={{ mt: 0.5 }}
                value={inputValue}
                onChange={handleChange}
                error={error}
                helperText={error && "Enter a valid email or mobile number"}
                required
                inputProps={{
                  pattern:
                    "^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})|(\\d{10})$",
                  title: "Enter a valid email or mobile number",
                }}
              />
              {data.otp && (
                <TextField
                  type="number"
                  fullWidth
                  placeholder="Enter OTP Here"
                  sx={{ mt: 0.5 }}
                  value={otp}
                  onChange={handleOtpChange}
                  error={otpError}
                  helperText={otpError}
                  required
                />
              )}
              {!data.otp ? (
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ mt: 2, width: "450px" }}
                  onClick={handleUserRegister}
                >
                  GET OTP
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ mt: 2, width: "450px" }}
                  onClick={handleUserLogin}
                >
                  SUBMIT OTP
                </Button>
              )}
            </Box>
          </Box>
        </Card>
      </Box>
      {data.otp && (
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "bold",
            textAlign: "center",
            mt: -3,
          }}
        >
          Your Current OTP{" "}
          <strong style={{ color: "#0099CC" }}>{data.otp}</strong>
        </Typography>
      )}
    </>
  );
};

export default Login;
