import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import Sidebar from "./Sidebar";
import Products from "./Products";
import { useParams } from "react-router-dom";
import axios from "axios";
import { handleColorAttributes1 } from "../../axios";

const ProductList = ({ setCartItems }) => {
  const { name } = useParams();
  const [productList, setProductList] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedPriceRanges, setSelectedPriceRanges] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [colorAttributes, setcolorAttributes] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const handleGetProductsByMe = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/get-all-Products"
      );
      if (res) {
        // console.log(res);
        setProductList(res.data);
        setRefresh((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePriceRangeChange = (newPriceRanges) => {
    setSelectedPriceRanges(newPriceRanges);
  };

  const handleColorSelection = (color) => {
    if (selectedColors.includes(color)) {
      setSelectedColors(selectedColors.filter((c) => c !== color));
    } else {
      setSelectedColors([...selectedColors, color]);
    }
  };

  useEffect(() => {
    const filterResults = () => {
      let filtered = productList.filter(
        (item) => item.subCategory === name && item.active === true
      );

      // Filter by price range
      if (selectedPriceRanges.length > 0) {
        filtered = filtered.filter((product) => {
          let index;
          const key = product?.Colors?.flatMap((color, colorIndex) => {
            return product?.Sizes?.flatMap((size, sizeIndex) => {
              index = colorIndex * product?.Sizes.length + sizeIndex;
              return `${color}-${size}`;
            });
          });

          const price = product?.mrp?.[0]?.[key?.[0]]?.offerPrice;
          for (let range of selectedPriceRanges) {
            if (range === "20000 to Above" && price >= 20000) {
              return true;
            }
            const [min, max] = range.split(" to ");
            if (price >= Number(min) && price <= Number(max)) {
              return true;
            }
          }
          return false;
        });
      }

      // Filter by selected colors
      if (selectedColors.length > 0) {
        filtered = filtered.filter((item) =>
          item?.Colors?.some((color) => selectedColors.includes(color))
        );
      }

      setFilteredProducts(filtered);
    };

    filterResults();
  }, [productList, selectedPriceRanges, selectedColors]);

  const handleColorAttributes = async () => {
    try {
      const res = await handleColorAttributes1();
      if (res) {
        setcolorAttributes(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Promise.all([handleGetProductsByMe(), handleColorAttributes()]);
  }, []);

  // console.log(filteredProducts);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Hero />
        </Grid>
        <Grid item xs={12} lg={1.8} xl={1.8}>
          <Sidebar
            onPriceRangeChange={handlePriceRangeChange}
            onColorSelection={handleColorSelection}
            colorAttributes={colorAttributes}
            selectedColors={selectedColors}
          />
        </Grid>
        <Grid item xs={12} lg={9.7} xl={9.7}>
          <Products
            colorAttributes={colorAttributes}
            filteredProducts={filteredProducts}
            setCartItems={setCartItems}
            Loading={Loading}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductList;
