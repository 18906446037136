import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchResults from "./SearchResults";
import Sidebar from "./LeftSideBar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { handleColorAttributes1 } from "../../axios";

const Search = ({}) => {
  const [results, setResults] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedPriceRanges, setSelectedPriceRanges] = useState([]);
  const [colorAttributes, setcolorAttributes] = useState([]);

  const location = useLocation();
  const searchTerm = location.state?.searchTerm;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getSearchResults = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://rccore-backend.cstechns.com/api/buyer/search",
          {
            params: {
              term: searchTerm,
            },
          }
        );
        setResults(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getSearchResults();
  }, [searchTerm]);

  useEffect(() => {
    filterResults();
  }, [results, selectedPriceRanges, selectedColors]);

  const filterResults = () => {
    let filtered = results;

    // Filter by price range
    if (selectedPriceRanges.length > 0) {
      filtered = filtered.filter((product) => {
        const price = product.offerPrice;
        for (let range of selectedPriceRanges) {
          if (range === "20000 to Above" && price >= 20000) {
            return true;
          }
          const [min, max] = range.split(" to ");
          if (price >= Number(min) && price <= Number(max)) {
            return true;
          }
        }
        return false;
      });
    }

    // Filter by selected colors
    if (selectedColors.length > 0) {
      filtered = filtered.filter((item) =>
        item?.Colors?.some((color) => selectedColors.includes(color))
      );
    }

    setFilteredProducts(filtered);
  };

  const handlePriceRangeChange = (newPriceRanges) => {
    setSelectedPriceRanges(newPriceRanges);
  };

  const handleColorSelection = (color) => {
    if (selectedColors.includes(color)) {
      setSelectedColors(selectedColors.filter((c) => c !== color));
    } else {
      setSelectedColors([...selectedColors, color]);
    }
  };

  const handleColorAttributes = async () => {
    try {
      const res = await handleColorAttributes1();
      if (res) {
        setcolorAttributes(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Promise.all([handleColorAttributes()]);
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2.3}>
          <Sidebar
            onPriceRangeChange={handlePriceRangeChange}
            onColorSelection={handleColorSelection}
            colorAttributes={colorAttributes}
            selectedColors={selectedColors}
          />
        </Grid>
        <Grid item xs={9.7}>
          <SearchResults
            results={filteredProducts}
            loading={loading}
            colorAttributes={colorAttributes}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Search;
