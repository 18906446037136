import {
  Autocomplete,
  Box,
  Card,
  Divider,
  InputAdornment,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";
import Drawer from "./Drawer";
import HeaderDrawer from "./Drawer";
import { useSelector } from "react-redux";
import MyAccountMenu from "./MyAccountMenu";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { toast } from "react-toastify";
import { handleGetWishListItems } from "../axios";

const theme = createTheme();

const Header = ({ data, setRefresh }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [productList, setProductList] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [wishListItems, setwishListItems] = useState([]);

  console.log(searchResults);

  const isLoggedIn = JSON.parse(localStorage.getItem("User"));

  const [showMenu, setShowMenu] = useState(false);

  const handleMouseEnter = () => {
    setShowMenu(true);
  };

  const handleGetProductsByMe = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/get-all-Products"
      );
      if (res) {
        // console.log(res);
        setProductList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCartItemsByUser = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/getCartItemsByUser/${isLoggedIn._id}`
      );
      if (res) {
        // console.log(res);
        setCartItems(res.data);
        handleGetCartItemsByUser();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleWishListItems = async () => {
    try {
      const res = await handleGetWishListItems(isLoggedIn._id);

      if (res) {
        setwishListItems(res);
        handleWishListItems();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMouseLeave = () => {
    setShowMenu(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    setRefresh((refresh) => !refresh);
    toast.success("LoggedOut SuccessFully", {
      position: "top-right",
    });
    navigate("/");
  };

  // handle get all icons

  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    // Perform search logic
    const matchingResults = productList.filter(
      (result) =>
        result.Title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        result.BrandName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(matchingResults);
    // navigate(`/results?searchTerm=${encodeURIComponent(searchTerm)}`);
  };

  const handleSearch = (item) => {
    if (searchTerm.trim() !== "") {
      console.log(searchTerm);
      // Redirect to results page
      navigate(`/searchResults?searchTerm=${encodeURIComponent(searchTerm)}`, {
        state: { searchTerm: searchTerm },
      });
    }

    setSearchTerm("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const cartLength = useSelector((state) => state.cart);

  // console.log();

  useEffect(() => {
    Promise.all([
      handleGetProductsByMe(),
      handleGetCartItemsByUser(),
      handleWishListItems(),
    ]);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
        <Box sx={{}}>
          <Box
            sx={{
              height: "60px",
              boxShadow: 0,
              backgroundColor: `${
                data && data?.headersearchandcolors?.[0]?.BackgroundColor
              }`,
              mt: -1.2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box>{isMediumScreen && <HeaderDrawer />}</Box>
              <img
                src={data && data?.logo_details?.[0]?.logo}
                alt=""
                style={{
                  width: isMediumScreen ? "140px" : "140px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              />
            </Box>
            <Box>
              {isMediumScreen ? (
                ""
              ) : (
                <Autocomplete
                  id="search-autocomplete"
                  freeSolo
                  options={searchResults.map((result) => result.Title)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        data && data?.headersearchandcolors?.[0]?.SearchCaption
                      }
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      size="small"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon
                              style={{ color: "#0099cc", cursor: "pointer" }}
                              onClick={handleSearch}
                            />
                          </InputAdornment>
                        ),
                        style: {
                          backgroundColor: "#fff",
                          borderRadius: "20px",
                          width: "400px",
                        },
                      }}
                    />
                  )}
                  onInputChange={(event, newInputValue) => {
                    setSearchTerm(newInputValue);
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Typography sx={{ p: 0.5 }}>{option}</Typography>
                    </li>
                  )}
                />
              )}
            </Box>
            <Box sx={{ mr: 4 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "40px" }}>
                {data &&
                  data?.headerimages
                    ?.sort((a, b) => a.sortingOrder - b.sortingOrder)
                    .map((item, i) => {
                      if (item.visible === true) {
                        return isMediumScreen && item.altText === "My Cart" ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                            key={i}
                          >
                            <div style={{ position: "relative" }}>
                              <img
                                src={item.image}
                                alt=""
                                key={i}
                                style={{
                                  filter: "invert(100%)",
                                  cursor: "pointer",
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: -10,
                                  right: -10,
                                  backgroundColor: "#ffe500",
                                  color: "black",
                                  borderRadius: "50%",
                                  width: 20,
                                  height: 20,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: "12px",
                                }}
                              >
                                {cartLength.cartItems.length}
                                {/* Replace cartItemCount with the actual count of items in the cart */}
                              </div>
                            </div>
                          </Box>
                        ) : !isMediumScreen ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            {item.altText === "Sell on Vedson" && (
                              <Box
                                key={i}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => window.open(item.url)}
                              >
                                <img
                                  src={item.image}
                                  alt=""
                                  style={{
                                    filter: "invert(100%)",
                                    cursor: "pointer",
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "White",
                                  }}
                                >
                                  {item.altText}
                                </Typography>
                              </Box>
                            )}
                            {item.altText === "My Account" && (
                              <Box
                                key={i}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                              >
                                {isLoggedIn ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img
                                      src={item.image}
                                      alt=""
                                      style={{
                                        filter: "invert(100%)",
                                        cursor: "pointer",
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        color: "White",
                                      }}
                                    >
                                      Hi,{isLoggedIn.name}
                                    </Typography>
                                    {showMenu && (
                                      <Card
                                        sx={{
                                          width: "200px",
                                          zIndex: 99,
                                          background: "#fff",
                                          position: "absolute",
                                          top: "100%",
                                        }}
                                      >
                                        <MenuItem sx={{ p: 2 }}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "10px",
                                            }}
                                            onClick={() =>
                                              navigate("/MyAccount")
                                            }
                                          >
                                            <AccountCircleIcon
                                              sx={{ color: "gray" }}
                                            />
                                            My Account
                                          </Box>
                                        </MenuItem>

                                        <MenuItem
                                          sx={{ p: 2 }}
                                          onClick={() => handleLogout()}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "10px",
                                            }}
                                          >
                                            <LogoutIcon
                                              sx={{ color: "gray" }}
                                            />
                                            LogOut
                                          </Box>
                                        </MenuItem>
                                      </Card>
                                    )}{" "}
                                    {/* Replace MenuComponent with your actual menu component */}
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => navigate("/login")}
                                  >
                                    <img
                                      src={item.image}
                                      alt=""
                                      style={{
                                        filter: "invert(100%)",
                                        cursor: "pointer",
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        color: "White",
                                      }}
                                    >
                                      {item.altText}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            )}
                            {item.altText === "Wishlist" && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                                key={i}
                                onClick={() => navigate("/wishlist")}
                              >
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={item.image}
                                    alt=""
                                    key={i}
                                    style={{
                                      filter: "invert(100%)",
                                      cursor: "pointer",
                                    }}
                                  />

                                  <div
                                    style={{
                                      position: "absolute",
                                      top: -10,
                                      right: -10,
                                      backgroundColor: "#ffe500",
                                      color: "black",
                                      borderRadius: "50%",
                                      width: 20,
                                      height: 20,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {/* Replace cartItemCount with the actual count of items in the cart */}
                                    {wishListItems?.wishListItems?.length ||
                                      "0"}
                                  </div>
                                </div>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "White",
                                    cursor: "pointer",
                                  }}
                                >
                                  {item.altText}
                                </Typography>
                              </Box>
                            )}
                            {item.altText === "My Cart" && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                                key={i}
                                onClick={() => navigate("/cart")}
                              >
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={item.image}
                                    alt=""
                                    key={i}
                                    style={{
                                      filter: "invert(100%)",
                                      cursor: "pointer",
                                    }}
                                  />

                                  <div
                                    style={{
                                      position: "absolute",
                                      top: -10,
                                      right: -10,
                                      backgroundColor: "#ffe500",
                                      color: "black",
                                      borderRadius: "50%",
                                      width: 20,
                                      height: 20,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {/* Replace cartItemCount with the actual count of items in the cart */}
                                    {cartItems?.cartItems?.length || "0"}
                                  </div>
                                  {!isLoggedIn && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: -10,
                                        right: -10,
                                        backgroundColor: "#ffe500",
                                        color: "black",
                                        borderRadius: "50%",
                                        width: 20,
                                        height: 20,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {/* Replace cartItemCount with the actual count of items in the cart */}
                                      {cartLength?.cartItems?.length || "0"}
                                    </div>
                                  )}
                                </div>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "White",
                                    cursor: "pointer",
                                  }}
                                >
                                  {item.altText}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        ) : (
                          ""
                        );
                      }
                    })}
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default Header;
