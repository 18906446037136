import { Box, Typography, useMediaQuery, ThemeProvider } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

const Topbar = ({ data }) => {
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Check if data is undefined or doesn't have the expected structure
  const punchline = data?.punchlinedatafortopbars?.[0]?.punchline;
  const linkText = data?.punchlinedatafortopbars?.[0]?.linkText;
  const linkUrl = data?.punchlinedatafortopbars?.[0]?.linkUrl;
  const topbarImages = data?.topbarimagescustomizations;

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          boxShadow: 1,
          display: "flex",
          alignItems: "center",
          background: "#fff",
        }}
      >
        <Box flex="1">
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                ml: 4,
                display: isSmallScreen ? "none" : "block",
                textAlign: isMediumScreen ? "center" : "",
                p: isMediumScreen && 1,
              }}
            >
              {punchline}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            mr: 4,
            alignItems: "center",
            gap: "10px",
            display: isMediumScreen ? "none" : "flex",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 0.2 }}>
            <Typography
              onClick={() => navigate(linkUrl)}
              style={{
                color: "black",
                textDecoration: "none",
                fontSize: "14px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              {linkText}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
              Download Our Mobile Apps
            </Typography>
            {topbarImages?.map((item) => {
              if (item.visible) {
                return (
                  <img
                    src={item.image}
                    alt=""
                    width="100px"
                    key={item._id}
                    style={{ margin: "0 5px" }}
                    onClick={() => window.open(item.url, "_blank")}
                  />
                );
              }
              return null;
            })}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Topbar;
