import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const MyOrders = () => {
  const [selected, setSelected] = useState("All");
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [CancellationData, setCancellationData] = useState(null);

  const isLoggedIn = JSON.parse(localStorage.getItem("User"));

  const handleGetOrderByUsers = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/getAllOrderByMe/${isLoggedIn._id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelOrderByBuyer = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to cancel this order?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/buyer/CancelOrderByBuyer/${id}`,
        {
          orderBuyerCancelled: true,
        }
      );
      if (res.data.message) {
        setCancellationData(res.data.message);
        toast.success(res.data.message, {
          position: "top-right",
        });
      } else {
        toast.error(res.data.error, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetOrderByUsers();
  }, [CancellationData]);

  const getSortedProducts = () => {
    let sortedProducts = [...data];

    if (selected === "All") {
      // No filtering required for "All"
    } else if (selected === "Pending") {
      sortedProducts = sortedProducts.filter(
        (item) => item.orderStatus === "pending"
      );
    } else if (selected === "Confirmed") {
      sortedProducts = sortedProducts.filter(
        (item) => item.orderStatus === "accepted"
      );
    } else if (selected === "Ready To Ship") {
      sortedProducts = sortedProducts.filter(
        (item) => item.orderStatus === "Ready To Ship"
      );
    } else if (selected === "Shipped") {
      sortedProducts = sortedProducts.filter(
        (item) => item.orderStatus === "Shipped"
      );
    } else if (selected === "Seller Cancellation") {
      sortedProducts = sortedProducts.filter(
        (item) => item.orderSellerCancelled === true
      );
    } else if (selected === "Admin Cancellation") {
      sortedProducts = sortedProducts.filter(
        (item) => item.orderStatus === "Admincancelled  "
      );
    } else if (selected === "Buyer Cancellation") {
      sortedProducts = sortedProducts.filter(
        (item) => item.orderBuyerCancelled === true
      );
    } else if (selected === "Delivered") {
      sortedProducts = sortedProducts.filter(
        (item) => item.orderStatus === "Delivered"
      );
    }

    return sortedProducts;
  };

  const sortedProducts = getSortedProducts();

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
        >
          MY ORDERS
        </Typography>
        <Typography
          sx={{ border: "2px solid #0099cc", width: "60px", margin: "auto" }}
        ></Typography>
        <Divider />
        {!Loading ? (
          <>
            {data.length > 0 ? (
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Select
                      sx={{ width: "200px", mt: 2 }}
                      size="small"
                      value={selected}
                      onChange={(e) => setSelected(e.target.value)}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Confirmed">Confirmed</MenuItem>
                      <MenuItem value="Ready To Ship">Ready To Ship</MenuItem>
                      <MenuItem value="Shipped">Shipped</MenuItem>
                      <MenuItem value="Seller Cancellation">
                        Seller Cancellation
                      </MenuItem>
                      <MenuItem value="Admin Cancellation">
                        Admin Cancellation
                      </MenuItem>
                      <MenuItem value="Buyer Cancellation">
                        Buyer Cancellation
                      </MenuItem>
                      <MenuItem value="Delivered">Delivered</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={12}>
                  {sortedProducts && sortedProducts.length > 0 ? (
                    <Box>
                      {sortedProducts?.map((item, index) => (
                        <Card
                          sx={{ border: "1px solid #ddd", mb: 2 }}
                          elevation={0}
                        >
                          <Box
                            sx={{
                              background: "#f9f9f9",
                              p: 1,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            key={index}
                          >
                            <Box sx={{ display: "flex", gap: "50px" }}>
                              <Box>
                                <Typography sx={{ color: "gray" }}>
                                  ORDER DATE
                                </Typography>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  {item?.orderDate}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography sx={{ color: "gray" }}>
                                  TOTAL AMOUNT
                                </Typography>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  ₹ {item?.totalPrice}
                                </Typography>
                              </Box>
                            </Box>
                            <Box sx={{ display: "flex", gap: "50px" }}>
                              <Box>
                                <Typography sx={{ color: "gray" }}>
                                  ORDER NUMBER
                                </Typography>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  {item?.orderId}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography sx={{ color: "gray" }}>
                                  INVOICE NUMBER
                                </Typography>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  1003- S1087727-3
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              p: 2,
                              alignItems: "flex-start",
                            }}
                          >
                            <Box>
                              {item.orderItems.map((order) => (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",

                                      alignItems: "flex-start",
                                      gap: "10px",
                                      mb: 2,
                                    }}
                                  >
                                    <Box sx={{ width: "150px" }}>
                                      <img
                                        src={order?.productId?.MainImage}
                                        alt=""
                                        width="100%"
                                        height="100%"
                                      />
                                    </Box>
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#0099CC",
                                          mb: 1,
                                        }}
                                      >
                                        <Link
                                          to={`/product-details/${order?.productId?.mainCategory}/${order?.productId?._id}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "#0099cc",
                                          }}
                                        >
                                          {order?.productId?.Title}
                                        </Link>
                                      </Typography>
                                      <Typography sx={{ mb: 0.5 }}>
                                        This is Test Product Name Here
                                      </Typography>
                                      <Box sx={{ display: "flex", mb: 0.5 }}>
                                        <Typography
                                          sx={{
                                            color: "gray",
                                            display: "flex",
                                            alignItems: "center",
                                            minWidth: "100px",
                                          }}
                                        >
                                          SKU Code :{" "}
                                        </Typography>
                                        <Typography>
                                          {order?.productId?.SkuCode}
                                        </Typography>
                                      </Box>
                                      <Box sx={{ display: "flex", mb: 0.5 }}>
                                        <Typography
                                          sx={{
                                            color: "gray",
                                            display: "flex",
                                            alignItems: "center",
                                            minWidth: "100px",
                                          }}
                                        >
                                          Color :{" "}
                                        </Typography>
                                        <Typography>
                                          {" "}
                                          {order?.selectedColor}
                                        </Typography>
                                      </Box>
                                      <Box sx={{ display: "flex", mb: 0.5 }}>
                                        <Typography
                                          sx={{
                                            color: "gray",
                                            display: "flex",
                                            alignItems: "center",
                                            minWidth: "100px",
                                          }}
                                        >
                                          Quantity :{" "}
                                        </Typography>
                                        <Typography>
                                          {" "}
                                          {order?.quantity}
                                        </Typography>
                                      </Box>
                                      <Box sx={{ display: "flex", mb: 0.5 }}>
                                        <Typography
                                          sx={{
                                            color: "gray",
                                            display: "flex",
                                            alignItems: "center",
                                            minWidth: "100px",
                                          }}
                                        >
                                          Sold By :{" "}
                                        </Typography>
                                        <Link
                                          to={`/sellerProductList/${order?.sellerId}`}
                                          style={{
                                            color: "red",
                                            textDecoration: "none",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {order?.sellerCompanyName}
                                        </Link>
                                      </Box>
                                    </Box>
                                  </Box>
                                </>
                              ))}
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "20px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  border: "1px solid #ddd",
                                  color: "#000",
                                  width: "200px",
                                }}
                                disabled={
                                  item.orderStatus === "cancelled" ||
                                  item.orderStatus === "rejected" ||
                                  item.orderStatus === "pending"
                                }
                              >
                                TRACK ORDER
                              </Button>
                              <Button
                                variant="outlined"
                                sx={{
                                  border: "1px solid #ddd",
                                  color: "#000",
                                  width: "200px",
                                }}
                                onClick={() =>
                                  handleCancelOrderByBuyer(item._id)
                                }
                                disabled={
                                  item.orderStatus === "cancelled" ||
                                  item.orderStatus === "rejected"
                                }
                              >
                                CANCEL ORDER
                              </Button>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              p: 1,
                              display: "flex",
                              justifyContent: "space-between",
                              ml: 3,
                              mr: 3,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "bold", color: "gray" }}
                              >
                                MODE OF PAYMENT :
                              </Typography>
                              <Typography sx={{ fontWeight: "bold" }}>
                                {item?.orderPaymentMethod}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "bold", color: "gray" }}
                              >
                                PAYMENT STATUS :
                              </Typography>
                              <Typography sx={{ fontWeight: "bold" }}>
                                {item.orderPaymentStatus === "Paid"
                                  ? "Paid"
                                  : "UnPaid"}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "bold", color: "gray" }}
                              >
                                ORDER STATUS :
                              </Typography>
                              <Typography sx={{ fontWeight: "bold" }}>
                                {item?.orderStatus}
                              </Typography>
                            </Box>
                          </Box>
                        </Card>
                      ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "60vh",
                      }}
                    >
                      <Typography
                        sx={{
                          border: "1px solid #ddd",
                          minWidth: "600px",
                          p: 2,
                          textAlign: "center",
                          background: "#eee",
                          fontWeight: "bold",
                        }}
                      >
                        No Result Found
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  You dont have any Orders!!
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default MyOrders;
