import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import EditUserAddressDialog from "./EditUserAddressModal";

const MyProfile = () => {
  const IsLoggedIn = JSON.parse(localStorage.getItem("User"));
  const [data, setData] = useState({});
  const [Loading, setLoading] = useState(false);
  const handleGteUser = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/fetchUserById/${IsLoggedIn._id}`
      );
      if (res) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGteUser();
  }, []);
  return (
    <div>
      <Box>
        <Typography
          sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
        >
          MY PROFILE
        </Typography>
        <Typography
          sx={{ border: "2px solid #0099cc", width: "60px", margin: "auto" }}
        ></Typography>
        <Divider />
        {!Loading ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 5,
                  }}
                >
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          color: "gray",
                          display: "flex",
                          aligNitems: "center",
                          minWidth: "200px",
                        }}
                      >
                        Email:
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data.userEmail}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <Typography
                        sx={{
                          color: "gray",
                          display: "flex",
                          aligNitems: "center",
                          minWidth: "200px",
                        }}
                      >
                        Name:
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data.name}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <Typography
                        sx={{
                          color: "gray",
                          display: "flex",
                          aligNitems: "center",
                          minWidth: "200px",
                        }}
                      >
                        City:
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data.city}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <Typography
                        sx={{
                          color: "gray",
                          display: "flex",
                          aligNitems: "center",
                          minWidth: "200px",
                        }}
                      >
                        State:
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data.state}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          color: "gray",
                          display: "flex",
                          aligNitems: "center",
                          minWidth: "200px",
                        }}
                      >
                        Mobile:
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data.contactNumber}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <Typography
                        sx={{
                          color: "gray",
                          display: "flex",
                          aligNitems: "center",
                          minWidth: "200px",
                        }}
                      >
                        PinCode:
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data.pincode}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <Typography
                        sx={{
                          color: "gray",
                          display: "flex",
                          aligNitems: "center",
                          minWidth: "200px",
                        }}
                      >
                        Country:
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data.country}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ mt: 4 }}>
              <EditUserAddressDialog data={data} setData={setData} />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default MyProfile;
