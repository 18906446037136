import { Box, Card, Typography } from "@mui/material";
import React from "react";

const CreditBanner = ({ data }) => {
  return (
    <div>
      <Box sx={{ mt: 2 }}>
        <Card
          elevation={0}
          sx={{
            background: "#f5f5f5",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {data?.creditsbannerimages
            ?.sort((a, b) => a.sortingOrder - b.sortingOrder)
            .map((item) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                  p: 1.5,
                }}
              >
                <img src={item.image} alt="" />
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  {item.title}
                </Typography>
              </Box>
            ))}
        </Card>
      </Box>
    </div>
  );
};

export default CreditBanner;
