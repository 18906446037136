import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

const Payment = ({}) => {
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [MypaymentResponse, setPaymentResponse] = useState(null);
  const location = useLocation();
  const [sellerId1, setSellerId] = useState();
  const checkoutData = location.state?.checkoutData;
  const cartItems = location.state?.cartItems;
  const [data, setData] = useState([]);

  const handlePayments = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/paymentMethods"
      );
      if (res) {
        // console.log(res);
        setData(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(sellerId1);
  const navigate = useNavigate();

  const isLoggedIn = JSON.parse(localStorage.getItem("User"));
  console.log(MypaymentResponse);

  // console.log(cartItems);
  const totalPricePerItem = cartItems?.cartItems?.map(
    (item) =>
      item?.productId?.mrp?.[0]?.[`${item.selectedColor}-${item.selectedSize}`]
        ?.mrp * item?.quantity
  );

  const discountAmountPerItem = cartItems?.cartItems?.map(
    (item) =>
      item?.productId?.mrp?.[0]?.[`${item.selectedColor}-${item.selectedSize}`]
        ?.mrp *
        item?.quantity -
      item?.productId?.mrp?.[0]?.[`${item.selectedColor}-${item.selectedSize}`]
        ?.offerPrice *
        item?.quantity
  );

  const DelivaryCharges = 79;

  const totalDiscountAmount = discountAmountPerItem?.reduce(
    (total, discount) => total + discount,
    0
  );

  const orderTotal = cartItems?.cartItems?.reduce(
    (total, item) =>
      total +
      item?.productId?.mrp?.[0]?.[`${item.selectedColor}-${item.selectedSize}`]
        ?.offerPrice *
        item?.quantity,
    0
  );

  const totalCartPrice = totalPricePerItem?.reduce(
    (total, price) => total + price,
    0
  );

  const generateRandomNumber = () => {
    const uuid = uuidv4(); // Generate a UUID
    const randomNumberString = uuid.split("-").join("").slice(0, 12); // Remove hyphens and take the first 12 characters
    const randomNumber = parseInt(randomNumberString, 16); // Convert the string to a decimal number
    return randomNumber;
  };

  const CurrentDate = () => {
    const date = new Date();
    const options = { timeZone: "Asia/Kolkata" };
    return date.toLocaleDateString("en-IN", options);
  };

  const Redirect = () => {
    navigate("/MyAccount");
  };

  const handlePlaceOrder = async () => {
    setLoading(true);
    try {
      const cartItems1 = cartItems?.cartItems;

      // Group items by seller ID
      const itemsBySeller = {};
      cartItems1.forEach((item) => {
        const sellerId = item.productId.createdBy._id;
        if (!itemsBySeller[sellerId]) {
          itemsBySeller[sellerId] = {
            sellerId: item.productId.createdBy._id,
            sellerCompanyName: item.productId.createdBy.companyName,
            items: [],
          };
          setSellerId(item.productId.createdBy._id);
        }
        itemsBySeller[sellerId].items.push(item);
      });

      // Create orders for each group of items and calculate total price
      const orders = Object.values(itemsBySeller).map((group) => {
        const totalPrice = group.items.reduce(
          (sum, item) =>
            sum +
            item.quantity *
              item.productId.mrp?.[0]?.[
                `${item.selectedColor}-${item.selectedSize}`
              ]?.offerPrice,
          0
        );

        return {
          orderId: "SS1234 - " + generateRandomNumber(),
          orderDate: CurrentDate(),
          orderItems: group.items.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
            selectedColor: item.selectedColor,
            selectedSize: item.selectedSize,
            sellerCompanyName: item.productId.createdBy.companyName,
            sellerId: item.productId.createdBy._id,
          })),
          totalPrice: totalPrice + DelivaryCharges, // Separate total price for each order
          orderAddress: checkoutData?.[0]?.Address,
          orderPhone: checkoutData?.[0]?.Mobile,
          orderPincode: checkoutData?.[0]?.PostCode,
          orderState: checkoutData?.[0]?.State,
          orderCountry: checkoutData?.[0]?.Country,
          orderCity: checkoutData?.[0]?.City,
          orderPaymentMethod: selected,
          orderCreatedBy: isLoggedIn._id,
          productCreatedBY: sellerId1,
          fullName: `${checkoutData?.[0]?.FirstName} ${checkoutData?.[0]?.LastName}`,
          companyName: checkoutData?.[0]?.CompanyName || "",
          GSTIN: checkoutData?.[0]?.GSTIN || "",
          orderPaymentStatus: selected === "RazorPay" ? "Paid" : "Unpaid",
          orderPaymentDate: selected === "RazorPay" ? CurrentDate() : "",
        };
      });

      if (selected === "RazorPay") {
        const paymentResponse = await initiatePayment(orders);

        if (paymentResponse) {
          const { amount, orderId } = paymentResponse;
          console.log(amount);

          const options = {
            key: data?.RazorPayAPIKeyId,
            amount: amount,
            currency: "INR",
            name: "Paying to SkillShop",
            description: "Order Payment",
            order_id: orderId,
            handler: async function (response) {
              setPaymentResponse(response);
              toast.success(`Payment Successful!`, {
                position: "top-right",
              });

              await createOrders(orders); // Create orders after successful payment

              Redirect();
            },
          };

          const razorpayInstance = new window.Razorpay(options);
          razorpayInstance.open();
        } else {
          toast.error(`Failed to create payment`, {
            position: "top-right",
          });
        }
      } else {
        // For modes other than "RazorPay", directly create the order
        await createOrders(orders);
        toast.success(`Payment Successful!`, {
          position: "top-right",
        });
        Redirect();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const initiatePayment = async (orders) => {
    const razorpayOrders = orders.map((order) => {
      console.log(order);
      const totalPrice = order.totalPrice;
      const orderId = "SS1234 - " + generateRandomNumber();

      return {
        amount: Math.floor(totalPrice + DelivaryCharges),
        currency: "INR",
        orderId,
      };
    });

    const paymentData = {
      razorpayOrders,
      email: "user@example.com",
      contact: "1234567890",
    };

    const paymentResponse = await axios.post(
      "https://rccore-backend.cstechns.com/api/buyer/RazorPay",
      paymentData
    );

    console.log(paymentResponse);

    if (paymentResponse.data && paymentResponse.data.order) {
      const orderDetails = {
        amount: paymentResponse.data.order.amount,
        orderId: paymentResponse.data.order.orderId,
      };

      return orderDetails;
    } else {
      return null;
    }
  };

  const createOrders = async (orders) => {
    const createOrderRequests = orders.map((order) =>
      axios.post(
        "https://rccore-backend.cstechns.com/api/buyer/createOrder",
        order
      )
    );

    await Promise.all(createOrderRequests);
  };

  useEffect(() => {
    handlePayments();
  }, []);

  return (
    <div>
      <Box sx={{ mt: 2 }}>
        <Typography
          sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
        >
          CHOOSE PAYMENT MODE
        </Typography>
        <Typography
          sx={{ border: "2px solid #0099CC", width: "60px", margin: "auto" }}
        ></Typography>
        <Divider />

        <Grid container spacing={2} sx={{ px: 3, mt: 2 }}>
          <Grid item xs={12} lg={8}>
            <Box>
              <Box sx={{ border: "1px solid #ddd", borderRadius: "5px" }}>
                <Typography sx={{ fontWeight: "bold", p: 2 }}>
                  CHOOSE PAYENT OPTION
                </Typography>
                <Divider />

                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "100%",
                      p: 2,
                      gap: "10px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      SKILLSHOP CREDIT
                    </Typography>
                    <Typography sx={{ color: "gray" }}>
                      Total Vedson Credit Balance :₹ <strong>0</strong>
                    </Typography>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#0099CC" }}
                      disableElevation
                    >
                      Apply
                    </Button>
                  </Box>
                  <span style={{ border: "1px solid #ddd" }}></span>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "100%",
                      p: 2,
                      gap: "10px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      DISCOUNT COUPON
                    </Typography>
                    <Typography sx={{ color: "gray" }}>
                      Click To Avail Best Offer
                    </Typography>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#0099CC" }}
                      disableElevation
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                  <Typography sx={{ color: "gray" }}>
                    Pay With COD To Avail Best Offers
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Pay With
                      </FormLabel>

                      {data?.CODActive && (
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          value={selected}
                          onChange={(e) => setSelected(e.target.value)}
                        >
                          <FormControlLabel
                            value="Cash On Delivary"
                            control={<Radio />}
                            label={data?.CODpaymentModeDispayOnWebsite}
                          />
                        </RadioGroup>
                      )}
                      {data?.RazorPayActive && (
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          value={selected}
                          onChange={(e) => setSelected(e.target.value)}
                        >
                          <FormControlLabel
                            value="RazorPay"
                            control={<Radio />}
                            label={data?.RazorPaypaymentModeDispayOnWebsite}
                          />
                        </RadioGroup>
                      )}
                      {data?.StripeActive && (
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          value={selected}
                          onChange={(e) => setSelected(e.target.value)}
                        >
                          <FormControlLabel
                            value="Stripe"
                            control={<Radio />}
                            label={data?.StripepaymentModeDispayOnWebsite}
                          />
                        </RadioGroup>
                      )}
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Button
                      sx={{
                        width: "300px",
                      }}
                      variant="contained"
                      style={{ backgroundColor: "#0099CC" }}
                      disableElevation
                      onClick={handlePlaceOrder}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "#fff" }} />
                      ) : (
                        "PLACE ORDER"
                      )}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box sx={{ border: "1px solid #ddd", borderRadius: "5px" }}>
              <Box sx={{ p: 2 }}>
                <Typography sx={{ fontSize: "18px" }}>
                  SHIPPING & BILLING ADDRESS
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ p: 2 }}>
                <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                  {checkoutData?.[0]?.FirstName} {checkoutData?.[0]?.LastName}
                </Typography>
                <Typography sx={{ mb: 0.5 }}>
                  {checkoutData?.[0]?.Mobile}
                </Typography>
                <Typography sx={{ mb: 0.5 }}>
                  {checkoutData?.[0]?.Address}
                </Typography>
                <Typography sx={{ mb: 0.5 }}>
                  {checkoutData?.[0]?.LandMark}
                </Typography>
                <Typography sx={{ mb: 0.5 }}>
                  {checkoutData?.[0]?.City},{checkoutData?.[0]?.State},
                  {checkoutData?.[0]?.Country},{checkoutData?.[0]?.PostCode}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ border: "1px solid #ddd", borderRadius: "5px", mt: 2 }}>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  PRICE DETAILS
                </Typography>
                <Link
                  to="/cart"
                  style={{
                    color: "#0099CC",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Cart{" "}
                </Link>
              </Box>
              <Divider />
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mb: 1 }}>Total Items : </Typography>
                  <Typography sx={{ mb: 1 }}>
                    {cartItems?.cartItems?.length}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mb: 1 }}>Cart Total : </Typography>
                  <Typography sx={{ mb: 1 }}>₹ {totalCartPrice}.00</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mb: 1 }}>Cart Discount : </Typography>
                  <Typography sx={{ mb: 1 }}>
                    -₹ {totalDiscountAmount}.00
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mb: 1 }}>Order Total : </Typography>
                  <Typography sx={{ mb: 1 }}>₹ {orderTotal}.00</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mb: 1 }}>
                    Order Total (Without TAX) :{" "}
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    ₹ {orderTotal - (18 / 100) * orderTotal}.00
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mb: 1 }}>TAX: </Typography>
                  <Typography sx={{ mb: 1 }}>
                    ₹ {(18 / 100) * orderTotal}.00
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mb: 1 }}> Delivary Charges: </Typography>
                  <Typography sx={{ mb: 1 }}>₹ {DelivaryCharges}.00</Typography>
                </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "16px" }}>
                  TOTAL (TAX INCL.)
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  ₹ {orderTotal + DelivaryCharges}.00
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Payment;
