import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const SubFooter = ({ data }) => {
  return (
    <div>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={2} sx={{ px: 3 }}>
          {data &&
            data?.subfooterimages
              ?.sort((a, b) => a.sortingOrder - b.sortingOrder)
              .map((item, index) => {
                if (item.visible === true) {
                  return (
                    <Grid
                      item
                      xs={8}
                      xl={2.4}
                      lg={2.4}
                      sm={6}
                      md={6}
                      key={index}
                    >
                      {item.title === "100% SECURE PAYMENT" ? (
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            {item.title}
                          </Typography>
                          <img src={item.image} alt="" width="100%" />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            gap: "10px",
                          }}
                        >
                          <img src={item.image} alt="" />
                          <Box>
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",

                                lineHeight: "14.7px",
                                color: "rgba(102,102,102)",
                              }}
                            >
                              {item.subtitle}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Grid>
                  );
                }
              })}
        </Grid>
      </Box>
    </div>
  );
};

export default SubFooter;
