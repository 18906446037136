import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  ThemeProvider,
  Tooltip,
  Typography,
  setRef,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  addToCart,
  decreaseCart,
  removeFromCart,
} from "../Redux/Cart/CartSlice";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../../App.css";
import { handleColorAttributes1 } from "../../axios";

const Cart = () => {
  const cartLength = useSelector((state) => state.cart);
  const [updatedCartData1, setUpdatedCartData1] = useState(null);
  const [RemoveFromCartData, setRemoveFromCartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [colorAttributes, setcolorAttributes] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [updatedCartData, setUpdatedCartData] = useState();
  const [refresh, setRefresh] = useState(false);
  //   console.log(cartLength);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const calculateOfferPercentage = (mrp, offerPrice) => {
    const discountAmount = mrp - offerPrice;
    const offerPercentage = (discountAmount / mrp) * 100;
    return Math.round(offerPercentage);
  };

  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const totalPricePerItem = cartItems?.cartItems
    ? cartItems?.cartItems?.map(
        (item) =>
          item?.productId?.mrp?.[0]?.[
            `${item.selectedColor}-${item.selectedSize}`
          ]?.mrp * item?.quantity
      )
    : cartLength.cartItems.map(
        (item) =>
          item.mrp?.[0]?.[`${item.selectedColor}-${item.selectedSize}`]?.mrp *
          item.cartQuantity
      );

  const discountAmountPerItem = cartItems?.cartItems
    ? cartItems?.cartItems?.map(
        (item) =>
          item?.productId?.mrp?.[0]?.[
            `${item.selectedColor}-${item.selectedSize}`
          ]?.mrp *
            item.quantity -
          item?.productId?.mrp?.[0]?.[
            `${item.selectedColor}-${item.selectedSize}`
          ]?.offerPrice *
            item.quantity
      )
    : cartLength.cartItems.map(
        (item) =>
          item.mrp?.[0]?.[`${item.selectedColor}-${item.selectedSize}`]?.mrp *
            item.cartQuantity -
          item.mrp?.[0]?.[`${item.selectedColor}-${item.selectedSize}`]
            ?.offerPrice *
            item.cartQuantity
      );

  const DelivaryCharges = 79;

  const totalDiscountAmount = discountAmountPerItem.reduce(
    (total, discount) => total + discount,
    0
  );

  const orderTotal = cartItems?.cartItems
    ? cartItems?.cartItems.reduce(
        (total, item) =>
          total +
          item?.productId?.mrp?.[0]?.[
            `${item.selectedColor}-${item.selectedSize}`
          ]?.offerPrice *
            item?.quantity,
        0
      )
    : cartLength.cartItems.reduce(
        (total, item) =>
          total +
          item.mrp?.[0]?.[`${item.selectedColor}-${item.selectedSize}`]
            ?.offerPrice *
            item.cartQuantity,
        0
      );

  const totalCartPrice = totalPricePerItem.reduce(
    (total, price) => total + price,
    0
  );

  const isLoggedIn = JSON.parse(localStorage.getItem("User"));

  const handleColorAttributes = async () => {
    try {
      const res = await handleColorAttributes1();
      if (res) {
        setcolorAttributes(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCartItemsByUser = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/getCartItemsByUser/${isLoggedIn._id}`
      );
      if (res) {
        // console.log(res);
        setCartItems(res.data);
        // handleGetCartItemsByUser();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetCartItemsByUser1 = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/getCartItemsByUser/${isLoggedIn._id}`
      );
      if (res) {
        // console.log(res);
        setCartItems(res.data);
        // handleGetCartItemsByUser();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateCartItemQuantityAPI = async (item) => {
    try {
      const updateCart = await axios.put(
        `https://rccore-backend.cstechns.com/api/buyer/updateCartItemQuantity/${item?.productId?.uniqueId}/${item?.selectedColor}/${isLoggedIn._id}`,
        {
          quantity: item.quantity + 1,
        }
      );
      if (updateCart) {
        // console.log((updateCart));
        setUpdatedCartData(updateCart);
        toast.info("Item Increased SuccessFully", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCartItemQuantityDecreaseAPI = async (item) => {
    try {
      if (item.quantity >= 2) {
        const updateCart = await axios.put(
          `https://rccore-backend.cstechns.com/api/buyer/updateCartItemQuantity/${item?.productId?.uniqueId}/${item?.selectedColor}/${isLoggedIn._id}`,
          {
            quantity: item.quantity - 1,
          }
        );
        if (updateCart) {
          // console.log((updateCart));
          setUpdatedCartData1(updateCart);
          toast.info("Item Decreased SuccessFully", {
            position: "top-right",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveItemFromCart = async (cartItem) => {
    if (isLoggedIn) {
      try {
        const res = await axios.delete(
          `https://rccore-backend.cstechns.com/api/buyer/removeFromCart/${cartItem._id}`
        );
        if (res) {
          // console.log(res);
          setRemoveFromCartData(res);
        }
      } catch (error) {
        console.log(error);
      }
    }
    dispatch(removeFromCart(cartItem));
  };

  const handleAddToWishList = async (item) => {
    try {
      const confirmed = window.confirm(
        "Are You Sure You Want To Move Item To WishList"
      );
      if (!confirmed) {
        return;
      }

      if (isLoggedIn) {
        const res = await axios.post(
          `https://rccore-backend.cstechns.com/api/buyer/AddToWishList`,
          {
            productId: item.productId,
            userId: isLoggedIn,
          }
        );
        if (res.data.error) {
          toast.info("Item Already in Your WishList", {
            position: "top-right",
          });
        } else {
          toast.success("Item added to WishList SuccessFuly", {
            position: "top-right",
          });

          const res = await axios.delete(
            `https://rccore-backend.cstechns.com/api/buyer/removeFromCart/${item._id}`
          );
          if (res) {
            toast.success("Item Removed From Cart successfully", {
              position: "top-right",
            });
          }

          navigate("/wishlist");
        }
      } else {
        toast.error("User Must be loggedIn", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDecrement = (item) => {
    dispatch(decreaseCart(item));
    setRefresh((prev) => !prev);
  };

  const handleIncrement = (item) => {
    dispatch(addToCart(item));
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    Promise.all([handleGetCartItemsByUser1()]);
  }, []);

  useEffect(() => {
    Promise.all([handleColorAttributes(), handleGetCartItemsByUser()]);
  }, [refresh, updatedCartData, updatedCartData1, RemoveFromCartData]);

  return (
    <div>
      {!loading ? (
        <ThemeProvider theme={theme}>
          {!isLoggedIn ? (
            <Box sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={11.7} lg={11.9}>
                  <Box sx={{ backgroundColor: "#eee", p: 2, ml: 1 }}>
                    <Typography>
                      ({cartLength && cartLength?.cartItems?.length}) Items in
                      the Cart
                    </Typography>
                  </Box>
                </Grid>
                {cartLength && cartLength?.cartItems?.length > 0 ? (
                  <>
                    <Grid item xs={12} lg={8.5}>
                      <Box>
                        {cartLength?.cartItems?.map((item) => {
                          console.log(item);
                          const key = item?.Colors?.flatMap(
                            (color, colorIndex) => {
                              return item?.Sizes?.flatMap((size, sizeIndex) => {
                                return `${color}-${size}`;
                              });
                            }
                          );
                          return (
                            <Box
                              sx={{
                                border: "1px solid #ddd",
                                p: 2,
                                mt: 1,
                                mx: 1,
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} lg={4}>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: "200px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={item.MainImage}
                                      alt=""
                                      style={{
                                        maxHeight: "100%",
                                        maxWidth: "100%",
                                      }}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        color: "#0099CC",
                                      }}
                                    >
                                      <Link to={`/product-details/`}>
                                        {item.Title}
                                      </Link>
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: "14px", mt: 1 }}
                                    >
                                      This is the Test DescriptionThis is the
                                      Test
                                    </Typography>
                                    <Box
                                      sx={{
                                        mt: 3,
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      <Typography sx={{ color: "gray" }}>
                                        Color:
                                      </Typography>
                                      {/* {UserSelectedcolor} */}
                                      {colorAttributes.map((color) => {
                                        if (color.name === item.selectedColor) {
                                          return (
                                            <Box
                                              //   key={index}
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                border: "2px solid #ddd",
                                                borderRadius: "50%",
                                                padding: "3px",
                                              }}
                                            >
                                              <Tooltip title={color.name}>
                                                <img
                                                  src={color.image}
                                                  alt=""
                                                  width="20px"
                                                  style={{
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              </Tooltip>
                                            </Box>
                                          );
                                        }
                                      })}
                                    </Box>
                                    <Box
                                      sx={{
                                        mt: 2,
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      <Typography sx={{ color: "gray" }}>
                                        Size:
                                      </Typography>
                                      <Typography>
                                        {item.selectedSize}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        mt: 3,
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      <Typography sx={{ color: "gray" }}>
                                        QTY:
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <Box sx={{ border: "1px solid #ddd" }}>
                                          <Button
                                            disableRipple
                                            onClick={() =>
                                              handleDecrement(item)
                                            }
                                          >
                                            -
                                          </Button>
                                        </Box>
                                        <Box sx={{ border: "1px solid #ddd" }}>
                                          <Button
                                            disableElevation
                                            disableRipple
                                            disableFocusRipple
                                          >
                                            {item.cartQuantity}
                                          </Button>
                                        </Box>
                                        <Box sx={{ border: "1px solid #ddd" }}>
                                          <Button
                                            disableRipple
                                            onClick={() =>
                                              handleIncrement(item)
                                            }
                                          >
                                            +
                                          </Button>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box
                                      sx={{
                                        mt: 2,
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      <Typography sx={{ color: "gray" }}>
                                        Sold By:
                                      </Typography>
                                      <Typography sx={{ color: "red" }}>
                                        {item.createdBy.companyName}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                  <Box>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      ₹{" "}
                                      {
                                        item.mrp?.[0]?.[
                                          `${item.selectedColor}-${item.selectedSize}`
                                        ]?.offerPrice
                                      }
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: "20px",
                                        mt: 0.5,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "16px",
                                          textDecoration: "line-through",
                                          color: "gray",
                                        }}
                                      >
                                        ₹{" "}
                                        {
                                          item.mrp?.[0]?.[
                                            `${item.selectedColor}-${item.selectedSize}`
                                          ]?.mrp
                                        }
                                      </Typography>
                                      <Typography
                                        sx={{
                                          color: "#0099CC",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        ({" "}
                                        {calculateOfferPercentage(
                                          item.mrp?.[0]?.[
                                            `${item.selectedColor}-${item.selectedSize}`
                                          ]?.mrp,
                                          item.mrp?.[0]?.[
                                            `${item.selectedColor}-${item.selectedSize}`
                                          ]?.offerPrice
                                        )}{" "}
                                        ) %
                                      </Typography>
                                    </Box>
                                    <Typography
                                      sx={{ color: "green", mt: 0.5 }}
                                    >
                                      Saving ₹{" "}
                                      {item.mrp?.[0]?.[
                                        `${item.selectedColor}-${item.selectedSize}`
                                      ]?.mrp -
                                        item.mrp?.[0]?.[
                                          `${item.selectedColor}-${item.selectedSize}`
                                        ]?.offerPrice}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Divider sx={{ mt: 1 }} />
                              <Box
                                sx={{
                                  mt: 1,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "20px",
                                }}
                              >
                                <Button
                                  sx={{ color: "gray" }}
                                  onClick={() => {
                                    handleRemoveItemFromCart(item);
                                    handleGetCartItemsByUser();
                                  }}
                                >
                                  REMOVE
                                </Button>
                                <Typography sx={{ color: "gray" }}>
                                  |
                                </Typography>
                                <Button
                                  sx={{ color: "gray" }}
                                  // onClick={() => handleAddToWishList(item)}
                                >
                                  MOVE TO WISHLIST
                                </Button>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                      <Grid item xs={12} lg={12}>
                        <Box
                          sx={{
                            mt: 2,
                            ml: 1,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          <Grid item xs={11.7} lg={8.5}>
                            <Box
                              sx={{
                                border: "1px solid #ddd",
                                p: 1.5,
                                display: "flex",
                                gap: "10px",
                              }}
                            >
                              <FavoriteIcon sx={{ color: "#0099CC" }} />
                              <Typography>Add More From WishList</Typography>
                            </Box>
                          </Grid>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#000" }}
                            sx={{
                              p: 1.5,
                              fontWeight: "bold",
                              width: isMediumScreen ? "100%" : "27.5%",
                            }}
                            onClick={() => navigate("/")}
                            disableElevation
                          >
                            CONTINUE SHOPPING
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={3.3}>
                      <Box sx={{ border: "1px solid #ddd", p: 2, mt: 1 }}>
                        <Typography sx={{ color: "#0099CC" }}>
                          PLEASE PROCEED TO CHECKOUT TO AVAIL BEST COUPONS
                          AVAILABLE FOR YOU
                        </Typography>
                      </Box>
                      <Box sx={{ border: "1px solid #ddd", p: 2, mt: 1 }}>
                        <Typography>PRICE DETAILS</Typography>
                        <Divider sx={{ mt: 1 }} />
                        <Box>
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={{ fontSize: "16px" }}>
                              Cart Total
                            </Typography>
                            <Typography sx={{ fontSize: "16px" }}>
                              ₹{totalCartPrice}.00
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={{ fontSize: "16px" }}>
                              Cart Discount
                            </Typography>
                            <Typography sx={{ fontSize: "16px" }}>
                              - ₹{totalDiscountAmount}.00
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={{ fontSize: "16px" }}>
                              Order Total
                            </Typography>
                            <Typography sx={{ fontSize: "16px" }}>
                              ₹{orderTotal}.00
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={{ fontSize: "16px" }}>
                              Delivary Charges
                            </Typography>
                            <Typography sx={{ fontSize: "16px" }}>
                              ₹{DelivaryCharges}.00
                            </Typography>
                          </Box>
                          <Divider sx={{ mt: 1 }} />
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={{ fontSize: "16px" }}>
                              TOTAL (TAX INCL.)
                            </Typography>
                            <Typography sx={{ fontSize: "16px" }}>
                              ₹{orderTotal + DelivaryCharges}.00
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#0099CC" }}
                        sx={{ mt: 2, p: 2, fontWeight: "bold" }}
                        fullWidth
                        disableElevation
                        onClick={() => navigate("/login")}
                      >
                        PROCEED TO CHECKOUT
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item lg={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="100vh"
                    >
                      <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                        Your Cart Is Empty
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
              {/* <Grid container spacing={2}></Grid> */}
            </Box>
          ) : (
            <>
              {!loading ? (
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={11.7} lg={11.9}>
                      <Box sx={{ backgroundColor: "#eee", p: 2, ml: 1 }}>
                        <Typography>
                          ({cartItems && cartItems?.cartItems?.length}) Items in
                          the Cart
                        </Typography>
                      </Box>
                    </Grid>
                    {cartItems && cartItems?.cartItems?.length > 0 ? (
                      <>
                        <Grid item xs={12} lg={8.5}>
                          <Box>
                            {cartItems &&
                              cartItems?.cartItems?.map((item) => {
                                console.log(item);
                                return (
                                  <Box
                                    sx={{
                                      border: "1px solid #ddd",
                                      p: 2,
                                      mt: 1,
                                      mx: 1,
                                    }}
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} lg={4}>
                                        <Box
                                          sx={{
                                            width: "100%",
                                            height: "200px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <img
                                            src={item?.productId?.MainImage}
                                            alt=""
                                            style={{
                                              maxHeight: "100%",
                                              maxWidth: "100%",
                                            }}
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} lg={6}>
                                        <Box>
                                          <Typography
                                            sx={{
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                              color: "#0099CC",
                                            }}
                                          >
                                            <Link
                                              to={`/product-details/${item?.productId?.mainCategory}/${item.productId?._id}`}
                                              style={{
                                                textDecoration: "none",
                                                color: "#0099cc",
                                              }}
                                              className="CartTitle"
                                            >
                                              {item?.productId?.Title}
                                            </Link>
                                          </Typography>
                                          <Typography
                                            sx={{ fontSize: "14px", mt: 1 }}
                                          >
                                            This is the Test DescriptionThis is
                                            the Test
                                          </Typography>
                                          <Box
                                            sx={{
                                              mt: 3,
                                              display: "flex",
                                              gap: "20px",
                                            }}
                                          >
                                            <Typography sx={{ color: "gray" }}>
                                              Color:
                                            </Typography>
                                            {/* {UserSelectedcolor} */}
                                            {colorAttributes.map((color) => {
                                              if (
                                                color.name ===
                                                item?.selectedColor
                                              ) {
                                                return (
                                                  <Box
                                                    //   key={index}
                                                    sx={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      border: "2px solid #ddd",
                                                      borderRadius: "50%",
                                                      padding: "3px",
                                                    }}
                                                  >
                                                    <Tooltip title={color.name}>
                                                      <img
                                                        src={color.image}
                                                        alt=""
                                                        width="20px"
                                                        style={{
                                                          borderRadius: "50%",
                                                        }}
                                                      />
                                                    </Tooltip>
                                                  </Box>
                                                );
                                              }
                                            })}
                                          </Box>
                                          <Box
                                            sx={{
                                              mt: 2,
                                              display: "flex",
                                              gap: "20px",
                                            }}
                                          >
                                            <Typography sx={{ color: "gray" }}>
                                              Size:
                                            </Typography>
                                            <Typography>
                                              {item.selectedSize}
                                            </Typography>
                                          </Box>
                                          <Box
                                            sx={{
                                              mt: 3,
                                              display: "flex",
                                              gap: "20px",
                                            }}
                                          >
                                            <Typography sx={{ color: "gray" }}>
                                              QTY:
                                            </Typography>
                                            <Box
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  border: "1px solid #ddd",
                                                }}
                                              >
                                                <Button
                                                  disableRipple
                                                  onClick={() =>
                                                    updateCartItemQuantityDecreaseAPI(
                                                      item
                                                    )
                                                  }
                                                >
                                                  -
                                                </Button>
                                              </Box>
                                              <Box
                                                sx={{
                                                  border: "1px solid #ddd",
                                                }}
                                              >
                                                <Button
                                                  disableElevation
                                                  disableRipple
                                                  disableFocusRipple
                                                >
                                                  {item?.quantity}
                                                </Button>
                                              </Box>
                                              <Box
                                                sx={{
                                                  border: "1px solid #ddd",
                                                }}
                                              >
                                                <Button
                                                  disableRipple
                                                  onClick={() => {
                                                    updateCartItemQuantityAPI(
                                                      item
                                                    );
                                                  }}
                                                >
                                                  +
                                                </Button>
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            sx={{
                                              mt: 2,
                                              display: "flex",
                                              gap: "20px",
                                            }}
                                          >
                                            <Typography sx={{ color: "gray" }}>
                                              Sold By:
                                            </Typography>
                                            <Typography
                                              sx={{
                                                color: "red",
                                                cursor: "pointer",
                                              }}
                                              className="createdBy"
                                              onClick={() =>
                                                navigate(
                                                  `/sellerProductList/${item?.productId?.createdBy?._id}`
                                                )
                                              }
                                            >
                                              {
                                                item?.productId?.createdBy
                                                  ?.companyName
                                              }
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} lg={2}>
                                        <Box>
                                          <Typography sx={{ fontSize: "16px" }}>
                                            ₹{" "}
                                            {
                                              item?.productId?.mrp?.[0]?.[
                                                `${item.selectedColor}-${item.selectedSize}`
                                              ]?.offerPrice
                                            }
                                          </Typography>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              gap: "20px",
                                              mt: 0.5,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "15px",
                                                textDecoration: "line-through",
                                                color: "gray",
                                              }}
                                            >
                                              ₹{" "}
                                              {
                                                item?.productId?.mrp?.[0]?.[
                                                  `${item.selectedColor}-${item.selectedSize}`
                                                ]?.mrp
                                              }
                                            </Typography>
                                            <Typography
                                              sx={{
                                                color: "#0099CC",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              ({" "}
                                              {calculateOfferPercentage(
                                                item?.productId?.mrp?.[0]?.[
                                                  `${item.selectedColor}-${item.selectedSize}`
                                                ]?.mrp,
                                                item?.productId?.mrp?.[0]?.[
                                                  `${item.selectedColor}-${item.selectedSize}`
                                                ]?.offerPrice
                                              )}{" "}
                                              ) %
                                            </Typography>
                                          </Box>
                                          <Typography
                                            sx={{ color: "green", mt: 0.5 }}
                                          >
                                            Saving ₹{" "}
                                            {item?.productId?.mrp?.[0]?.[
                                              `${item.selectedColor}-${item.selectedSize}`
                                            ]?.mrp -
                                              item?.productId?.mrp?.[0]?.[
                                                `${item.selectedColor}-${item.selectedSize}`
                                              ]?.offerPrice}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 1 }} />
                                    <Box
                                      sx={{
                                        mt: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "20px",
                                      }}
                                    >
                                      <Button
                                        sx={{ color: "gray" }}
                                        onClick={() =>
                                          handleRemoveItemFromCart(item)
                                        }
                                      >
                                        REMOVE
                                      </Button>
                                      <Typography sx={{ color: "gray" }}>
                                        |
                                      </Typography>
                                      <Button
                                        sx={{ color: "gray" }}
                                        onClick={() =>
                                          handleAddToWishList(item)
                                        }
                                      >
                                        MOVE TO WISHLIST
                                      </Button>
                                    </Box>
                                  </Box>
                                );
                              })}
                          </Box>
                          <Grid item xs={12} lg={12}>
                            <Box
                              sx={{
                                mt: 2,
                                ml: 1,
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                gap: "10px",
                              }}
                            >
                              <Grid item xs={11.7} lg={8.5}>
                                <Box
                                  sx={{
                                    border: "1px solid #ddd",
                                    p: 1.5,
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <FavoriteIcon sx={{ color: "#0099CC" }} />
                                  <Typography>
                                    Add More From WishList
                                  </Typography>
                                </Box>
                              </Grid>
                              <Button
                                variant="contained"
                                style={{ backgroundColor: "#000" }}
                                sx={{
                                  p: 1.5,
                                  fontWeight: "bold",
                                  width: isMediumScreen ? "100%" : "27.5%",
                                }}
                                onClick={() => navigate("/")}
                                disableElevation
                              >
                                CONTINUE SHOPPING
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} lg={3.3}>
                          <Box sx={{ border: "1px solid #ddd", p: 2, mt: 1 }}>
                            <Typography sx={{ color: "#0099CC" }}>
                              PLEASE PROCEED TO CHECKOUT TO AVAIL BEST COUPONS
                              AVAILABLE FOR YOU
                            </Typography>
                          </Box>
                          <Box sx={{ border: "1px solid #ddd", p: 2, mt: 1 }}>
                            <Typography>PRICE DETAILS</Typography>
                            <Divider sx={{ mt: 1 }} />
                            <Box>
                              <Box
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography sx={{ fontSize: "16px" }}>
                                  Cart Total
                                </Typography>
                                <Typography sx={{ fontSize: "16px" }}>
                                  ₹{totalCartPrice}.00
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography sx={{ fontSize: "16px" }}>
                                  Cart Discount
                                </Typography>
                                <Typography sx={{ fontSize: "16px" }}>
                                  - ₹{totalDiscountAmount}.00
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography sx={{ fontSize: "16px" }}>
                                  Order Total
                                </Typography>
                                <Typography sx={{ fontSize: "16px" }}>
                                  ₹{orderTotal}.00
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography sx={{ fontSize: "16px" }}>
                                  Delivary Charges
                                </Typography>
                                <Typography sx={{ fontSize: "16px" }}>
                                  ₹{DelivaryCharges}.00
                                </Typography>
                              </Box>
                              <Divider sx={{ mt: 1 }} />
                              <Box
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography sx={{ fontSize: "16px" }}>
                                  TOTAL (TAX INCL.)
                                </Typography>
                                <Typography sx={{ fontSize: "16px" }}>
                                  ₹{orderTotal + DelivaryCharges}.00
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#0099CC" }}
                            sx={{ mt: 2, p: 2, fontWeight: "bold" }}
                            fullWidth
                            disableElevation
                            onClick={() =>
                              navigate("/Checkout", {
                                state: { cartItems: cartItems },
                              })
                            }
                          >
                            PROCEED TO CHECKOUT
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <Grid item lg={12}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          minHeight="100vh"
                        >
                          <Typography
                            sx={{ fontSize: "18px", fontWeight: "bold" }}
                          >
                            Your Cart Is Empty
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                  {/* <Grid container spacing={2}></Grid> */}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100vh",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </>
          )}
        </ThemeProvider>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default Cart;
