import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const PreFooter = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        mt: 7,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => navigate("/")}
    >
      {data?.prefooterimages?.[0]?.visible === true && (
        <img
          src={data && data?.prefooterimages?.[0]?.image}
          alt="logo"
          width="170px"
        />
      )}
    </Box>
  );
};

export default PreFooter;
