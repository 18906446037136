import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import "../../App.css";
import axios from "axios";
import { toast } from "react-toastify";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const AddressesList = ({ data, setData }) => {
  const [selected, setSelected] = useState(data && data?.[0]?.TypeOfAddress);

  const location = useLocation();

  const cartItems = location.state?.cartItems;

  // console.log(cartItems);

  const navigate = useNavigate();

  //   console.log(selected);

  useEffect(() => {
    setSelected(data?.[0]?.TypeOfAddress);
  }, []);

  const handleRemoveAddress = async (id) => {
    try {
      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/buyer/RemoveAddress/${id}`
      );
      if (res.data.message) {
        toast.success(res.data.message, {
          position: "top-right",
        });
        setData(data?.filter((address) => address._id !== id));
      } else {
        toast.error(res.data.error, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = () => {
    const selectedAddress = data.find(
      (item) => item.TypeOfAddress === selected
    );
    if (selectedAddress) {
      navigate("/payment", {
        state: { checkoutData: [selectedAddress], cartItems: cartItems },
      });
    } else {
      toast.error("Please Select Atleat one address", {
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7}>
            <Box>
              <Box>
                <Box>
                  {data?.map((item) => (
                    <>
                      <Box
                        key={item.TypeOfAddress}
                        sx={{
                          border: "1px solid #ddd",
                          mb: 2,
                          borderRadius: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            p: 2,
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={selected}
                                onChange={(e) => setSelected(e.target.value)}
                              >
                                <FormControlLabel
                                  value={item.TypeOfAddress}
                                  control={<Radio sx={{ mt: -0.5 }} />}
                                />
                              </RadioGroup>
                            </FormControl>
                            <Box>
                              <Typography
                                sx={{ fontSize: "16px", fontWeight: "bold" }}
                              >
                                {item.FirstName} {item.LastName}
                              </Typography>
                              <Typography>{item.Address}</Typography>
                              <Typography>{item.LandMark}</Typography>
                              <Typography>
                                {item.City} - {item.PostCode}
                              </Typography>
                              <Typography>
                                {item.State}, {item.Country}
                              </Typography>
                              <Typography>Mobile : {item.Mobile}</Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              border: "1px solid #ddd",
                              p: 0.5,

                              borderRadius: "50px",
                              background: "#eee",
                            }}
                          >
                            <Typography
                              sx={{
                                textAlign: "center",
                                fontSize: "14px",
                                ml: 1,
                                mr: 1,
                              }}
                            >
                              {" "}
                              {item.TypeOfAddress}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                        <Box
                          sx={{
                            p: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            sx={{ color: "gray" }}
                            onClick={() => handleRemoveAddress(item._id)}
                          >
                            REMOVE
                          </Button>
                          <Button
                            sx={{ color: "gray" }}
                            onClick={() =>
                              navigate(`/updateShippingAddress/${item._id}`)
                            }
                          >
                            EDIT
                          </Button>
                        </Box>
                      </Box>
                    </>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Box>
              <Box
                sx={{
                  border: "1px solid #ddd",
                  p: 2,
                  borderRadius: "5px",
                  height: "200px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "100%",
                  }}
                  onClick={() =>
                    navigate("/new-Address", {
                      state: { cartItems: cartItems },
                    })
                  }
                >
                  <IconButton>
                    <AddCircleIcon sx={{ fontSize: "30px" }} />
                  </IconButton>
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    className="createdBy"
                  >
                    Add New Address
                  </Link>
                </Box>
              </Box>
              <Button
                variant="conatsined"
                style={{ backgroundColor: "#0099CC" }}
                fullWidth
                sx={{ mt: 2, color: "#fff", fontWeight: "bold" }}
                onClick={handleButtonClick}
              >
                PROCEED TO CHECKOUT
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AddressesList;
