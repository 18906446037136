import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addToCart } from "../Redux/Cart/CartSlice";
import { useDispatch } from "react-redux";
import { handleColorAttributes1, handleGetWishListItems } from "../../axios";

const WishList = ({}) => {
  //   console.log(wishListItems);

  const isLoggedIn = JSON.parse(localStorage.getItem("User"));

  const [data, setData] = useState(null);
  const [removeItem, setRemoveItem] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedId, setSelectedId] = useState();
  const [colorAttributes, setcolorAttributes] = useState([]);
  const [Loading, setLoading] = useState(false);

  const [wishListItems, setwishListItems] = useState([]);

  const handleColorAttributes = async () => {
    try {
      const res = await handleColorAttributes1();
      if (res) {
        setcolorAttributes(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleWishListItems = async () => {
    setLoading(true);
    try {
      const res = await handleGetWishListItems(isLoggedIn._id);

      if (res) {
        setwishListItems(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const RemoveItemFromWishList = async (id) => {
    try {
      // Display confirmation prompt
      const confirmed = window.confirm(
        "Are you sure you want to remove this item?"
      );

      if (!confirmed) {
        return;
      }

      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/buyer/RemoveItemFromWishList/${id}`
      );

      if (res.data.message) {
        toast.success(res.data.message, {
          position: "top-right",
        });
        setRemoveItem(res.data);
      } else {
        toast.error(res.data.error, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setDialogOpen(true);
    setSelectedColor("");
    setSelectedSize("");
  };

  const handleGetCartItemsByUser = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/getCartItemsByUser/${isLoggedIn._id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddToCart = (item) => {
    setSelectedItem(item);
    // setSelectedId(item._id);
    handleOpenDialog();
  };

  const handleDialogOK = async (id) => {
    if (selectedColor && selectedItem) {
      const product = {
        ...selectedItem,
        selectedColor: selectedColor,
        selectedSize: selectedSize,
      };

      if (isLoggedIn) {
        try {
          let updatedCartItem = null;

          // Check if the product already exists in the cart
          for (const item of data.cartItems) {
            if (
              item.productId._id === product._id &&
              item.selectedColor === product.selectedColor &&
              item.userId === isLoggedIn._id &&
              item.selectedSize === product.selectedSize
            ) {
              // Send a PUT request to update the quantity of the existing item
              const response = await axios.put(
                `https://rccore-backend.cstechns.com/api/buyer/updateCartItemQuantity/${item.productId.uniqueId}/${item.selectedColor}/${isLoggedIn._id}`,
                {
                  quantity: item.quantity + 1,
                }
              );

              updatedCartItem = response.data; // Assuming the updated cart item is returned from the API

              // Display a success toast message for quantity update
              toast.info("Item Increased Successfully", {
                position: "top-right",
              });

              break;
            }
          }

          // If the product does not exist in the cart, add it
          if (!updatedCartItem) {
            const response = await axios.post(
              "https://rccore-backend.cstechns.com/api/buyer/addToCart",
              {
                productId: product,
                userId: isLoggedIn._id,
                quantity: 1,
                selectedColor: product.selectedColor,
                selectedSize: product.selectedSize,
                uniqueId: product.uniqueId,
              }
            );

            updatedCartItem = response.data; // Assuming the added cart item is returned from the API

            // console.log(response);

            // Display a success toast message for item addition
            toast.success("Item Added to Cart Successfully", {
              position: "top-right",
            });

            const res = await axios.delete(
              `https://rccore-backend.cstechns.com/api/buyer/RemoveItemFromWishList/${id}`
            );
            if (res) {
              toast.success("Item Removed Fro WhishList SuccessFully", {
                position: "top-right",
              });
            }
          }

          // Dispatch the updated cart item to the store
        } catch (error) {
          console.error(error);
          // Handle the error, display an error message, or perform necessary actions
        }
      }
      dispatch(addToCart(product));
      setDialogOpen(false);
      navigate("/cart");
    } else {
      toast.error("Please Select Color And Size", {
        position: "top-right",
      });
    }
  };

  const handleColorClick = (item) => {
    setSelectedColor(item);
  };
  const handleSizeClick = (item) => {
    setSelectedSize(item);
  };

  useEffect(() => {
    handleGetCartItemsByUser();
  }, []);

  useEffect(() => {
    Promise.all([handleColorAttributes(), handleWishListItems()]);
  }, [removeItem]);

  return (
    <div>
      {!Loading ? (
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
          >
            MY WISHLIST
          </Typography>
          <Typography
            style={{
              border: "2px solid #0099CC",
              width: "60px",
              margin: "auto",
            }}
          ></Typography>
          <Divider />
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2} sx={{ px: 3 }}>
              {wishListItems?.wishListItems?.length === 0 ? (
                <Grid item xs={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "90vh",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",

                        textAlign: "center",
                      }}
                    >
                      Your WishList is Empty
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                wishListItems?.wishListItems?.map((item) => {
                  let index;
                  const key = item?.productId?.Colors?.flatMap(
                    (color, colorIndex) => {
                      return item?.productId?.Sizes?.flatMap(
                        (size, sizeIndex) => {
                          index =
                            colorIndex * item?.productId?.Sizes.length +
                            sizeIndex;
                          return `${color}-${size}`;
                        }
                      );
                    }
                  );

                  return (
                    <Grid item xs={12} lg={3} key={item.id}>
                      <Box>
                        <Card sx={{ maxWidth: 305, border: "1px solid #ddd" }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={item?.productId?.MainImage}
                              alt=""
                              style={{ maxHeight: "250px" }}
                              className="cardimg"
                              onClick={() => {
                                navigate(
                                  `/product-details/${item.productId?.subCategory}/${item.productId?._id}`
                                );
                              }}
                            />
                          </Box>
                          <CardContent>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: "600", color: "#0099cc" }}
                            >
                              {item?.productId?.Title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Lizards are a widespread group of squamate
                              reptiles...
                            </Typography>
                            <Box sx={{ display: "flex", gap: "80px", mt: 1 }}>
                              <Typography
                                sx={{ fontWeight: "600", fontSize: "16px" }}
                              >
                                ₹{" "}
                                {
                                  item?.productId?.mrp?.[0]?.[key?.[0]]
                                    ?.offerPrice
                                }
                              </Typography>
                              <Typography
                                sx={{
                                  color: "gray",
                                  textDecoration: "line-through",
                                }}
                              >
                                ₹ {item?.productId?.mrp?.[0]?.[key?.[0]]?.mrp}
                              </Typography>
                            </Box>
                          </CardContent>
                          <CardActions
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              disableElevation
                              sx={{ width: "150px" }}
                              style={{ backgroundColor: "#000" }}
                              onClick={() => RemoveItemFromWishList(item._id)}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                Remove
                              </Box>
                            </Button>
                            <Button
                              size="small"
                              variant="contained"
                              disableElevation
                              sx={{ width: "150px" }}
                              onClick={() => handleAddToCart(item.productId)}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                Move To Cart
                              </Box>
                            </Button>
                          </CardActions>
                        </Card>
                      </Box>
                      <Dialog
                        open={dialogOpen}
                        onClose={() => setDialogOpen(false)}
                        fullWidth
                      >
                        <DialogTitle>Select Color and Size</DialogTitle>
                        <DialogContent>
                          <Typography>Color:</Typography>
                          <Box sx={{ display: "flex", gap: "20px" }}>
                            {selectedItem?.Colors.map((item, index) => {
                              const color = colorAttributes.find(
                                (color) => color.name === item
                              );
                              if (color) {
                                return (
                                  <Box
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      border:
                                        selectedColor === color?.name
                                          ? "2px solid #000"
                                          : "2px solid #ddd",
                                      borderRadius: "50%",
                                      padding: "3px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleColorClick(color.name)}
                                  >
                                    <Tooltip title={color.name}>
                                      <img
                                        src={color.image}
                                        alt=""
                                        width="20px"
                                        style={{ borderRadius: "50%" }}
                                      />
                                    </Tooltip>
                                  </Box>
                                );
                              }
                              return null;
                            })}
                          </Box>
                          <Typography sx={{ mt: 2 }}>Size:</Typography>
                          <Box sx={{ display: "flex", gap: "20px", mt: 1 }}>
                            {selectedItem?.data?.Sizes.map((item, index) => {
                              return (
                                <Box
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    border:
                                      selectedSize === item
                                        ? "2px solid #000"
                                        : "2px solid #ddd",

                                    padding: "3px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleSizeClick(item)}
                                >
                                  <Typography>{item}</Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setDialogOpen(false)}>
                            Cancel
                          </Button>
                          <Button onClick={() => handleDialogOK(item._id)}>
                            OK
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  );
                })
              )}
            </Grid>
            {/* {filteredProducts.length === 0 && (
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "center",
                mt: 3,
              }}
            >
              No Data Available
            </Typography>
          )} */}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default WishList;
