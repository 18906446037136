import { createSlice } from "@reduxjs/toolkit";
import { IncrementViewCountOfProduct } from "../../../axios";

const initialState = {};

const viewCountSlice = createSlice({
  name: "viewCount",
  initialState,
  reducers: {
    incrementViewCount(state, action) {
      const { productId, userId } = action.payload;

      if (!state[productId]) {
        state[productId] = [];
      }

      const userView = state[productId].find((view) => view.userId === userId);

      if (userView) {
        userView.count++;
      } else {
        state[productId].push({ userId, count: 1 });
      }

      // Persist the updated view count in localStorage
      localStorage.setItem("viewedProducts", JSON.stringify(state));
    },
  },
});

export const { incrementViewCount } = viewCountSlice.actions;

export default viewCountSlice.reducer;
