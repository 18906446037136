import { Grid } from "@mui/material";
import React from "react";
import MyAccountTabs from "./MyAccountTabs";

const MyAccount = () => {
  return (
    <div>
      <Grid container xs={12} lg={12}>
        <Grid item xs={12} lg={12}>
          <MyAccountTabs />
        </Grid>
      </Grid>
    </div>
  );
};

export default MyAccount;
