import React, { useState } from "react";
import "./ImageMagnifier.css"; // CSS file for styling
import SideBySideMagnifier from "@vanyapr/react-image-magnifiers/dist/SideBySideMagnifier";
import { Box } from "@mui/material";

const ImageMagnifier = ({ image }) => {
  return (
    <>
      <Box>
        <SideBySideMagnifier
          imageSrc={image}
          overlayBackgroundColor="rgba(0,0,0,0.5)"
          overlayBoxColor="#fff"
          overlayBoxOpacity={0.8}
          overlayBoxImage="none"
          zoomContainerBorder="1px solid #ddd"
          zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,0.5)"
          zoomContainerWidth={200}
          zoomContainerHeight={200}
          zoomContainerBorderRadius={4}
          transitionSpeed={0.4}
          style={{ objectFit: "cover" }}
        />
      </Box>
    </>
  );
};

export default ImageMagnifier;
