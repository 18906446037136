import { Box } from "@mui/material";
import React from "react";

const Hero = () => {
  return (
    <div>
      <Box>
        <img
          src="https://res.cloudinary.com/bunny1705/image/upload/v1684152868/jkjczsm2fdfyzzhnpcu8.jpg"
          alt=""
          width="100%"
        />
      </Box>
    </div>
  );
};

export default Hero;
