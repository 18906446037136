import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Typography } from "@mui/material";
import "../App.css";
import { useNavigate } from "react-router-dom";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function ActiveLastBreadcrumb({ singeProductData }) {
  const navigate = useNavigate();
  return (
    <div>
      <Breadcrumbs>
        <Typography
          sx={{ color: "gray", cursor: "pointer" }}
          className="breadCrumb"
          onClick={() => navigate(`/`)}
        >
          Home
        </Typography>
        <Typography
          sx={{ color: "black", cursor: "pointer" }}
          className="breadCrumb"
          onClick={() => navigate(`/list/${singeProductData?.subCategory}`)}
        >
          {singeProductData?.subCategory}
        </Typography>
        <Typography sx={{ color: "black" }}>
          {singeProductData?.Title}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}
