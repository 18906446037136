import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";
import ImageMagnifier from "../Magnifier/ImageMagnifier";
import DialogToShowImages from "./DialogToShowImages";

const MainProductData = ({ singeProductData, selectedColor }) => {
  const [open, setOpen] = React.useState(false);
  const theme = createTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedImage, setSelectedImage] = useState(null);
  // const colorThumbnailsRef = useRef(null);

  // const handleScroll = (scrollDirection) => {
  //   const container = colorThumbnailsRef.current;

  //   if (container) {
  //     const scrollAmount = scrollDirection === "up" ? -100 : 100;
  //     container.scrollBy({ top: scrollAmount, behavior: "smooth" });
  //   }
  // };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  useEffect(() => {
    setSelectedImage(singeProductData?.ProductImages?.[selectedColor]?.[0]);
  }, [selectedColor]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} lg={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  mt: 2,
                  overflowY:
                    singeProductData?.ProductImages?.[selectedColor]?.length < 5
                      ? "scroll"
                      : "initial",
                  maxHeight: "300px",
                  // overflowX: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide the scrollbar
                  },
                  // Adjust the max height as needed
                }}
              >
                {singeProductData?.Colors?.map((color, i) => (
                  <Box key={color}>
                    {color === selectedColor &&
                      singeProductData?.ProductImages?.[color]?.map(
                        (item, index) => (
                          <React.Fragment key={item}>
                            {!isMediumScreen && (
                              <Box
                                sx={{
                                  width: "80px",
                                  height: "80px",
                                  border:
                                    selectedImage === item
                                      ? "1px solid #000"
                                      : "1px solid #ddd",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "5px",
                                  cursor: "pointer",
                                }}
                                className={
                                  selectedImage === item
                                    ? "selected product-img"
                                    : "product-img"
                                }
                                onClick={() => handleImageClick(item)}
                              >
                                <LazyLoadImage
                                  src={item}
                                  alt=""
                                  style={{
                                    maxWidth: "80%",
                                    maxHeight: "80%",
                                    objectFit: "contain",
                                  }}
                                />
                              </Box>
                            )}
                          </React.Fragment>
                        )
                      )}
                    {/* <Box sx={{ height: "80px", width: "80px" }}>
                      {singeProductData.videoUrls.map((item) => (
                        <ReactPlayer url={item} width="100%" height="100%" />
                      ))}
                    </Box> */}
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} lg={8}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "70vh",
                  width: "100%",
                }}
              >
                {selectedImage &&
                singeProductData?.ProductImages?.[selectedColor] ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                    }}
                  >
                    <DialogToShowImages
                      image={selectedImage}
                      selectedColor={selectedColor}
                      singeProductData={singeProductData}
                      selectedImage={selectedImage}
                      setSelectedImage={setSelectedImage}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                    }}
                  >
                    {singeProductData?.ProductImages?.[selectedColor]?.length >
                    0 ? (
                      <DialogToShowImages
                        image={
                          singeProductData?.ProductImages?.[selectedColor]?.[0]
                        }
                        singeProductData={singeProductData}
                      />
                    ) : (
                      <DialogToShowImages
                        image={singeProductData?.MainImage}
                        singeProductData={singeProductData}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default MainProductData;
