import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const CHeckoutProductDetails = ({ cartItems }) => {
  const calculateOfferPercentage = (mrp, offerPrice) => {
    const discountAmount = mrp - offerPrice;
    const offerPercentage = (discountAmount / mrp) * 100;
    return Math.round(offerPercentage);
  };

  const totalPricePerItem = cartItems?.cartItems?.map(
    (item) =>
      item?.productId?.mrp?.[0]?.[`${item.selectedColor}-${item.selectedSize}`]
        ?.mrp * item?.quantity
  );

  const discountAmountPerItem = cartItems?.cartItems?.map(
    (item) =>
      item?.productId?.mrp?.[0]?.[`${item.selectedColor}-${item.selectedSize}`]
        ?.mrp *
        item?.quantity -
      item?.productId?.mrp?.[0]?.[`${item.selectedColor}-${item.selectedSize}`]
        ?.offerPrice *
        item?.quantity
  );

  const DelivaryCharges = 79;

  const totalDiscountAmount = discountAmountPerItem?.reduce(
    (total, discount) => total + discount,
    0
  );

  const orderTotal = cartItems?.cartItems?.reduce(
    (total, item) =>
      total +
      item?.productId?.mrp?.[0]?.[`${item.selectedColor}-${item.selectedSize}`]
        ?.offerPrice *
        item?.quantity,
    0
  );

  const totalCartPrice = totalPricePerItem?.reduce(
    (total, price) => total + price,
    0
  );

  const navigate = useNavigate();
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Box sx={{ border: "1px solid #ddd", p: 2, borderRadius: "5px" }}>
            <Typography sx={{ color: "#0099CC" }}>
              PLEASE PROCEED TO CHECKOUT TO AVAIL BEST COUPONS AVAILABLE FOR YOU
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Box sx={{ border: "1px solid #ddd", borderRadius: "5px" }}>
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  ORDER SUMMARY ({cartItems?.cartItems?.length} items)
                </Typography>
                <Link
                  to="/cart"
                  style={{
                    color: "#0099CC",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Cart
                </Link>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  PRODUCT
                </Typography>
                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  PRICE
                </Typography>
              </Box>
            </Box>

            <Divider />
            <Box>
              {cartItems?.cartItems?.map((item, index) => (
                <>
                  <Box sx={{ p: 2 }}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box
                        sx={{
                          maxWidth: "80px",
                        }}
                        key={index}
                      >
                        <img
                          src={item?.productId?.MainImage}
                          alt=""
                          width="100%"
                          height="100%"
                        />
                      </Box>
                      <Box>
                        <Typography>
                          ₹{" "}
                          {
                            item?.productId?.mrp?.[0]?.[
                              `${item.selectedColor}-${item.selectedSize}`
                            ]?.offerPrice
                          }
                        </Typography>
                        <Typography
                          sx={{ textDecoration: "line-through", color: "gray" }}
                        >
                          {
                            item?.productId?.mrp?.[0]?.[
                              `${item.selectedColor}-${item.selectedSize}`
                            ]?.mrp
                          }
                        </Typography>
                        <Typography sx={{ color: "#0099CC" }}>
                          {" "}
                          (
                          {calculateOfferPercentage(
                            item?.productId?.mrp?.[0]?.[
                              `${item.selectedColor}-${item.selectedSize}`
                            ]?.mrp,
                            item?.productId?.mrp?.[0]?.[
                              `${item.selectedColor}-${item.selectedSize}`
                            ]?.offerPrice
                          )}
                          ) %
                        </Typography>
                        <Typography sx={{ color: "green" }}>
                          Save ₹{" "}
                          {item?.productId?.mrp?.[0]?.[
                            `${item.selectedColor}-${item.selectedSize}`
                          ]?.mrp -
                            item?.productId?.mrp?.[0]?.[
                              `${item.selectedColor}-${item.selectedSize}`
                            ]?.offerPrice}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography>{item?.productId?.Title}</Typography>
                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <Typography>Brand : </Typography>
                        <Typography sx={{ color: "#0099CC" }}>
                          {item?.productId?.BrandName}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <Typography>Color : </Typography>
                        <Typography>{item?.selectedColor}</Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <Typography>Size : </Typography>
                        <Typography>{item?.selectedSize}</Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <Typography>Qty : </Typography>
                        <Typography>{item?.quantity}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                </>
              ))}
            </Box>
            <Box>
              <Box sx={{ p: 2 }}>
                <Box>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px" }}>
                        Cart Total
                      </Typography>
                      <Typography sx={{ fontSize: "16px" }}>
                        ₹{totalCartPrice}.00
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px" }}>
                        Cart Discount
                      </Typography>
                      <Typography sx={{ fontSize: "16px" }}>
                        - ₹{totalDiscountAmount}.00
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px" }}>
                        Order Total
                      </Typography>
                      <Typography sx={{ fontSize: "16px" }}>
                        ₹{orderTotal}.00
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px" }}>
                        Order Total (Without TAX) :
                      </Typography>
                      <Typography sx={{ fontSize: "16px" }}>
                        ₹{orderTotal - (18 / 100) * orderTotal}.00
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px" }}>TAX :</Typography>
                      <Typography sx={{ fontSize: "16px" }}>
                        ₹{(18 / 100) * orderTotal}.00
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px" }}>
                        Delivary Charges
                      </Typography>
                      <Typography sx={{ fontSize: "16px" }}>
                        ₹{DelivaryCharges}.00
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ mt: 1 }} />
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "16px" }}>
                  TOTAL (TAX INCL.)
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  ₹{orderTotal + DelivaryCharges}.00
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default CHeckoutProductDetails;
