import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, updateCartItemQuantityAPI } from "../Redux/Cart/CartSlice";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../App.css";
import {
  addToRecentlyViewed,
  updateRecentlyViewed,
} from "../Redux/Cart/RecentlyViewed";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { v4 as uuidv4 } from "uuid";
import { addToWishlist } from "../Redux/Cart/WishListSlice";
import { toast } from "react-toastify";
import { useRef } from "react";

const Products = ({ colorAttributes, filteredProducts }) => {
  console.log(filteredProducts);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState();
  // const [cartItems, setCartItems] = useState({});

  const { name } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const isLoggedIn = JSON.parse(localStorage.getItem("User"));

  // console.log(isLoggedIn);

  const scrollContainerRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleScrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -250, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 250, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    setIsScrollable(container.scrollWidth > container.clientWidth);
    setIsAtBeginning(container.scrollLeft === 0);
    setIsAtEnd(
      container.scrollLeft + container.clientWidth >= container.scrollWidth
    );
  }, []);

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    setIsAtBeginning(container.scrollLeft === 0);
    setIsAtEnd(
      container.scrollLeft + container.clientWidth >= container.scrollWidth
    );
  };

  const handleGetCartItemsByUser = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/getCartItemsByUser/${isLoggedIn._id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
    setSelectedColor("");
    setSelectedSize("");
  };

  const handleAddToCart = (item) => {
    setSelectedItem(item);
    handleOpenDialog();
  };

  const handleDialogOK = async () => {
    if (selectedColor && selectedItem && selectedSize) {
      const product = {
        ...selectedItem,
        selectedColor: selectedColor,
        selectedSize: selectedSize,
      };

      if (isLoggedIn) {
        try {
          let updatedCartItem = null;
          let updatedCartItems = [...data.cartItems]; // Create a copy of the existing cart items

          // Check if the product already exists in the cart
          for (const item of updatedCartItems) {
            if (
              item.productId._id === product._id &&
              item.selectedColor === product.selectedColor &&
              item.userId === isLoggedIn._id &&
              item.selectedSize === product.selectedSize
            ) {
              // Send a PUT request to update the quantity of the existing item
              const response = await axios.put(
                `https://rccore-backend.cstechns.com/api/buyer/updateCartItemQuantity/${item.productId.uniqueId}/${item.selectedColor}/${isLoggedIn._id}`,
                {
                  quantity: item.quantity + 1,
                }
              );

              updatedCartItem = response.data; // Assuming the updated cart item is returned from the API

              // Display a success toast message for quantity update
              toast.info("Item Increased Successfully", {
                position: "top-right",
              });

              break;
            }
          }

          // If the product does not exist in the cart, add it
          if (!updatedCartItem) {
            const response = await axios.post(
              "https://rccore-backend.cstechns.com/api/buyer/addToCart",
              {
                productId: product,
                userId: isLoggedIn._id,
                quantity: 1,
                selectedColor: product.selectedColor,
                selectedSize: product.selectedSize,
                uniqueId: product.uniqueId,
              }
            );

            updatedCartItem = response.data; // Assuming the added cart item is returned from the API

            // Display a success toast message for item addition
            toast.success("Item Added to Cart Successfully", {
              position: "top-right",
            });
          }

          // Update the cart items in the state
          updatedCartItems.push(updatedCartItem);
          setData({
            ...data,
            cartItems: updatedCartItems,
          });

          // Dispatch the updated cart item to the store
        } catch (error) {
          console.error(error);
          // Handle the error, display an error message, or perform necessary actions
        }
      }
      dispatch(addToCart(product));
      setDialogOpen(false);
      navigate("/cart");
    } else {
      toast.error("Please Select Color And Size", {
        position: "top-right",
      });
    }
  };

  const handleColorClick = (item) => {
    setSelectedColor(item);
  };
  const handleSizeClick = (item) => {
    // console.log(item);
    setSelectedSize(item);
  };

  const handleAddTORecentlyViewed = (item) => {
    dispatch(addToRecentlyViewed(item));
  };

  const handleAddToWishList = async (item) => {
    try {
      if (isLoggedIn) {
        const res = await axios.post(
          `https://rccore-backend.cstechns.com/api/buyer/AddToWishList`,
          {
            productId: item,
            userId: isLoggedIn,
          }
        );
        if (res.data.error) {
          toast.info("Item Already in Your WishList", {
            position: "top-right",
          });
        } else {
          toast.success("Item added to WishList SuccessFuly", {
            position: "top-right",
          });
          navigate("/wishlist");
        }
      } else {
        toast.error("User Must be loggedIn", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetCartItemsByUser();
  }, []);

  // console.log(selectedColo);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {filteredProducts.length > 4 && (
            <IconButton
              aria-label="Scroll Left"
              onClick={handleScrollLeft}
              disabled={isAtBeginning}
            >
              <ArrowBackIosIcon />
            </IconButton>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              overflowX: "auto",
              "&::-webkit-scrollbar": {
                display: "none", // Hide the scrollbar
              },
            }}
            ref={scrollContainerRef}
            onScroll={handleScroll}
          >
            {filteredProducts.map((item) => (
              <>
                {item.active === true ? (
                  <Box>
                    <Card
                      sx={{
                        maxWidth: 345,
                        border: "1px solid #ddd",
                        ml: 3,
                        mr: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          // gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={item.MainImage}
                          alt=""
                          style={{ maxHeight: "180px", padding: "5px" }}
                          className="cardimg"
                          onClick={() => {
                            navigate(
                              `/product-details/${item.subCategory}/${item._id}`
                            );
                            handleAddTORecentlyViewed(item);
                          }}
                        />
                      </Box>
                      <CardContent>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: "600", color: "#0099cc" }}
                        >
                          {item.Title.slice(0, 20)}....
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {item.Description.slice(0, 25)}...
                        </Typography>
                        <Box sx={{ display: "flex", gap: "80px", mt: 1 }}>
                          <Typography
                            sx={{ fontWeight: "600", fontSize: "16px" }}
                          >
                            ₹ {item?.offerPrice}
                          </Typography>
                          <Typography
                            sx={{
                              color: "gray",
                              textDecoration: "line-through",
                            }}
                          >
                            ₹ {item?.mrp}
                          </Typography>
                        </Box>
                      </CardContent>
                      <CardActions
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          disableElevation
                          sx={{ width: "150px" }}
                          onClick={() => handleAddToWishList(item)}
                        >
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <FavoriteIcon sx={{ fontSize: "24px", mr: 0.5 }} />
                            WishList
                          </Box>
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          disableElevation
                          sx={{ width: "150px" }}
                          onClick={() => handleAddToCart(item)}
                        >
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <ShoppingCartIcon
                              sx={{ fontSize: "24px", mr: 0.5 }}
                            />
                            Add To Cart
                          </Box>
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                ) : (
                  <Box sx={{ marginLeft: "auto" }}>
                    <Typography sx={{ textAlign: "center", ml: 2 }}>
                      No Similar Items Found
                    </Typography>
                  </Box>
                )}
              </>
            ))}
          </Box>
          {filteredProducts.length > 4 && (
            <IconButton
              aria-label="Scroll Right"
              onClick={handleScrollRight}
              disabled={isAtEnd}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </Box>
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          fullWidth
        >
          <DialogTitle>Select Color and Size</DialogTitle>
          <DialogContent>
            <Typography>Color:</Typography>
            <Box sx={{ display: "flex", gap: "20px" }}>
              {selectedItem?.Colors.map((item, index) => {
                const color = colorAttributes.find(
                  (color) => color.name === item
                );
                if (color) {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border:
                          selectedColor === color?.name
                            ? "2px solid #000"
                            : "2px solid #ddd",
                        borderRadius: "50%",
                        padding: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleColorClick(color.name)}
                    >
                      <Tooltip title={color.name}>
                        <img
                          src={color.image}
                          alt=""
                          width="20px"
                          style={{ borderRadius: "50%" }}
                        />
                      </Tooltip>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
            <Typography sx={{ mt: 2 }}>Size:</Typography>
            <Box sx={{ display: "flex", gap: "20px", mt: 1 }}>
              {selectedItem?.data?.Sizes.map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border:
                        selectedSize === item
                          ? "2px solid #000"
                          : "2px solid #ddd",

                      padding: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSizeClick(item)}
                  >
                    <Typography>{item}</Typography>
                  </Box>
                );
              })}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleDialogOK}>OK</Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default Products;
