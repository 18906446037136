import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ImageMagnifier from "../Magnifier/ImageMagnifier";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Magnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "@vanyapr/react-image-magnifiers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogToShowImages({
  image,
  singeProductData,
  selectedColor,
  selectedImage,
  setSelectedImage,
}) {
  const [open, setOpen] = React.useState(false);
  const [selected1, setSelected1] = React.useState();

  React.useEffect(() => {
    setSelected1(selectedImage);
  }, [selectedImage]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleImageClick = (item) => {
    setSelected1(item);
  };

  return (
    <div>
      <Box onClick={handleClickOpen}>
        <ImageMagnifier image={image} />
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={10} xl={10}>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            maxWidth="xl"
            fullWidth
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 3,
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ p: 2, fontSize: "20px", ml: 2 }}>
                {singeProductData?.Title}
              </Typography>
              <IconButton sx={{ mr: 4 }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              {singeProductData?.ProductImages?.[selectedColor] && (
                <Box sx={{ p: 2 }}>
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      gap: "40px",
                      width: "300px",
                      flexWrap: "wrap",
                    }}
                  >
                    {singeProductData?.ProductImages?.[selectedColor]?.map(
                      (item, index) => (
                        <Box
                          key={index}
                          sx={{ width: "60px", cursor: "pointer" }}
                          onClick={() => handleImageClick(item)}
                        >
                          <img
                            src={item}
                            width="100%"
                            height="100%"
                            style={{
                              objectFit: "contain",
                              border:
                                selected1 === item
                                  ? "2px solid #0099cc"
                                  : "1px solid #ddd",
                              padding: "3px",
                            }}
                          />
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
              )}
              <Box sx={{ margin: "auto" }}>
                {selected1 &&
                singeProductData?.ProductImages?.[selectedColor] ? (
                  <Box
                    sx={{
                      width: "400px",
                    }}
                  >
                    <Magnifier
                      imageSrc={selected1}
                      mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK}
                      touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "400px",
                    }}
                  >
                    <img
                      src={singeProductData?.MainImage}
                      width="100%"
                      height="100%"
                      style={{ objectFit: "contain" }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Dialog>
        </Grid>
      </Grid>
    </div>
  );
}
