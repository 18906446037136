import axios from "axios";
import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import Topbar from "./Topbar";
import Header from "./Header";
import MyCarousel from "./FullBanners";
import FullBanners from "./FullBanners";
import Banner16Images from "./Banner16Images";
import FeaturedCategories from "./FeaturedCategories";
import FeaturedBrands from "./FeaturedBrands";
import MidBanners from "./MidBanners";
import FeaturedBanners from "./FeaturedBanners";
import EndBanners from "./EndBanners";
import FooterBanners from "./FooterBanners";
import PreFooter from "./PreFooter";

const HomePage = ({ data, isLoading, modules }) => {
  return (
    <div>
      {!isLoading ? (
        <Box>
          {modules.map((module) => {
            if (module.moduleType === "Full Banner") {
              return <FullBanners key={module._id} data={data} />;
            }
            if (module.moduleType === "16 images under main banner") {
              return <Banner16Images key={module._id} data={data} />;
            }
            if (module.moduleType === "Featured Categories") {
              return <FeaturedCategories key={module._id} data={data} />;
            }
            if (module.moduleName === "Featured Brands") {
              return <FeaturedBrands key={module._id} data={data} />;
            }
            if (module.moduleName === "Mid Banner") {
              return <MidBanners key={module._id} data={data} />;
            }
            if (module.moduleName === "Featured Banners") {
              return <FeaturedBanners key={module._id} data={data} />;
            }
            if (module.moduleName === "End Banner") {
              return <EndBanners key={module._id} data={data} />;
            }
            if (module.moduleName === "Footer Banners") {
              return <FooterBanners key={module._id} data={data} />;
            }
          })}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default HomePage;
