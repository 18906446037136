import axios from "axios";

import { BASE_URL_BUYER, BASE_URL, BASE_URL_SELLER } from "./BaseUrl";

axios.defaults.baseURL = BASE_URL;

export const handleGetJosnDataForHomePage1 = async () => {
  try {
    const res = await axios.get(`/handlePostHomepgeJsonData`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};
export const handleMeagaMenuJsonData = async () => {
  try {
    const res = await axios.get(`/handleMeagaMenuJsonData`);
    if (res) {
      // console.log(res);
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleGetAllHomepageModules1 = async () => {
  try {
    const res = await axios.get("/handleGetAllHomepageModules");
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const handleGetAllCategory = async () => {
  try {
    const res = await axios.get("/getAllSubCategoriesForRoot");
    if (res) {
      return res.data[0].subcategories;
      // console.log(res.data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleColorAttributes1 = async () => {
  try {
    const res = await axios.get("/getAllColorAttributes");
    if (res) {
      // console.log(res.data);
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleGetWishListItems = async (id) => {
  try {
    const wishlist = await axios.get(
      `https://rccore-backend.cstechns.com/api/buyer/getWishListItemsByUser/${id}`
    );
    if (wishlist) {
      return wishlist.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// Ratings and Reviews

export const createNewRating = async (
  Name,
  Email,
  Description,
  Quality,
  valueForMoney,
  Performence,
  ProductDetails,
  userDetails,
  Date
) => {
  try {
    const res = await axios.post("/createNewRating", {
      Name,
      Email,
      Description,
      Quality,
      valueForMoney,
      Performence,
      ProductDetails,
      userDetails,
      Date,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllReviewsFOrProductById = async (id) => {
  try {
    const res = await axios.get(`getReviewsByProductId/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// all Navbar items

export const getAllNavbarItems = async () => {
  try {
    const res = await axios.get(`/getAllNavbarItems`);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

//Questions by Buyers

export const QuestionByBuyer = async (Buyer, product, Question, sellerId) => {
  try {
    const res = await axios.post(`${BASE_URL_BUYER}/QuestionByBuyer`, {
      Buyer,
      product,
      Question,
      sellerId,
    });
    if (res) {
      return res.data.message;
    } else {
      return res.data.error;
    }
  } catch (error) {
    console.log(error);
  }
};

export const QuestionsForParticularProduct = async (id) => {
  try {
    const res = await axios.get(
      `${BASE_URL_BUYER}/QuestionsForParticularProduct/${id}`
    );
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// return policy

export const getAllPolicy = async () => {
  try {
    const res = await axios.get(`/getAllPolicy`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// view count of product by particular user

// here user can view the product as many times as he want but the count will increment only once from the redux state

export const IncrementViewCountOfProduct = async (productId, userId, data) => {
  try {
    const res = await axios.put(
      `${BASE_URL_SELLER}/Products/ViewProductCount/${productId}/${userId}`
    );
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};
