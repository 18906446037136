import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductRating from "./Rating";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import MainProductData from "./MainProductData";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../Redux/Cart/CartSlice";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "../../App.css";
import { getAllPolicy, getAllReviewsFOrProductById } from "../../axios";

const ProductDetailsData = ({
  singeProductData,
  setSelectedColor,
  selectedColor,
  policyDescription,
}) => {
  const [colorAttributes, setColorAttributes] = useState([]);
  const [data, setData] = useState([]);
  const [reviews, setReviews] = useState([]);

  // console.log(singeProductData);

  const [selectedSize, setSelectedSize] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSizeSelect = (item) => {
    setSelectedSize(item);
  };

  const handleBrandAttributes = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllColorAttributes"
      );
      if (res) {
        // console.log(res.data);
        setColorAttributes(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateOfferPercentage = (mrp, offerPrice) => {
    const discountAmount = mrp - offerPrice;
    const offerPercentage = (discountAmount / mrp) * 100;
    return Math.round(offerPercentage);
  };

  const [value, setValue] = useState(1);
  const [counter, setCounter] = useState(1);

  const isLoggedIn = JSON.parse(localStorage.getItem("User"));

  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  const handleDecrement = () => {
    if (counter < 2) {
      setCounter(1);
    } else {
      setCounter(counter - 1);
    }
  };

  const handleGetCartItemsByUser = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/getCartItemsByUser/${isLoggedIn._id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddToWishList = async (item) => {
    try {
      if (isLoggedIn) {
        const res = await axios.post(
          `https://rccore-backend.cstechns.com/api/buyer/AddToWishList`,
          {
            productId: item,
            userId: isLoggedIn,
          }
        );
        if (res.data.error) {
          toast.info("Item Already in Your WishList", {
            position: "top-right",
          });
        } else {
          toast.success("Item added to WishList SuccessFuly", {
            position: "top-right",
          });
          navigate("/wishlist");
        }
      } else {
        toast.error("User Must be loggedIn", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddToCart = async (item) => {
    // dispatchEvent()
    if (selectedColor) {
      const product = {
        ...item,
        selectedColor: selectedColor,
        selectedSize: selectedSize,
      };
      try {
        let updatedCartItem = null;

        // Check if the product already exists in the cart
        for (const item of data.cartItems) {
          if (
            item.productId._id === product._id &&
            item.selectedColor === product.selectedColor &&
            item.userId === isLoggedIn._id &&
            item.selectedSize === product.selectedSize
          ) {
            // Send a PUT request to update the quantity of the existing item
            const response = await axios.put(
              `https://rccore-backend.cstechns.com/api/buyer/updateCartItemQuantity/${item.productId.uniqueId}/${item.selectedColor}/${isLoggedIn._id}`,
              {
                quantity: item.quantity + 1,
              }
            );

            updatedCartItem = response.data; // Assuming the updated cart item is returned from the API

            // Display a success toast message for quantity update
            toast.info("Item Increased Successfully", {
              position: "top-right",
            });

            break;
          }
        }

        // If the product does not exist in the cart, add it
        if (!updatedCartItem) {
          const response = await axios.post(
            "https://rccore-backend.cstechns.com/api/buyer/addToCart",
            {
              productId: product,
              userId: isLoggedIn._id,
              quantity: counter,
              selectedColor: product.selectedColor,
              selectedSize: selectedSize,
              uniqueId: product.uniqueId,
            }
          );

          updatedCartItem = response.data; // Assuming the added cart item is returned from the API

          // Display a success toast message for item addition
          toast.success("Item Added to Cart Successfully", {
            position: "top-right",
          });
        }

        // Dispatch the updated cart item to the store
      } catch (error) {
        console.error(error);
        // Handle the error, display an error message, or perform necessary actions
      }
      dispatch(addToCart(product));
      navigate("/cart");
    } else {
      toast.error("Please Select atleast One Color");
    }
  };

  const onHandleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setSelectedColor(singeProductData?.Colors && singeProductData.Colors[0]);
    setSelectedSize(singeProductData?.Sizes && singeProductData?.Sizes[0]);
    handleGetCartItemsByUser();
  }, [singeProductData]);

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  useEffect(() => {
    handleBrandAttributes();
  }, []);

  const { id } = useParams();

  const handleGetReviews = async () => {
    try {
      const res = await getAllReviewsFOrProductById(id);
      if (res) {
        setReviews(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetReviews();
  }, [id]);

  const activeReviews = reviews.filter((item) => item.active === true);

  const totalQuality = activeReviews.reduce((acc, row) => acc + row.Quality, 0);
  const totalValueForMoney = activeReviews.reduce(
    (acc, row) => acc + row.valueForMoney,
    0
  );
  const totalPerformence = activeReviews.reduce(
    (acc, row) => acc + row.Performence,
    0
  );

  const averageQuality =
    activeReviews.length > 0 ? totalQuality / activeReviews.length : 0;
  const averageValueForMoney =
    activeReviews.length > 0 ? totalValueForMoney / activeReviews.length : 0;
  const averagePerformence =
    activeReviews.length > 0 ? totalPerformence / activeReviews.length : 0;

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
      padding: 15,
      fontSize: 14,
    },
  });

  return (
    <div>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <MainProductData
              singeProductData={singeProductData}
              selectedColor={selectedColor}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box>
              <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                Retaxis
              </Typography>
              <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
                {singeProductData.Title}
              </Typography>
            </Box>
            <Divider sx={{ mt: 0.5 }} />
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                  ₹{" "}
                  {
                    singeProductData?.mrp?.[0]?.[
                      `${selectedColor}-${selectedSize}`
                    ]?.offerPrice
                  }
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "gray" }}>
                  Incl. GST Tax
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "18px",

                    color: "gray",
                    textDecoration: "line-through",
                  }}
                >
                  ₹{" "}
                  {
                    singeProductData?.mrp?.[0]?.[
                      `${selectedColor}-${selectedSize}`
                    ]?.mrp
                  }
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#d73818",
                  }}
                >
                  ({" "}
                  {calculateOfferPercentage(
                    singeProductData?.mrp?.[0]?.[
                      `${selectedColor}-${selectedSize}`
                    ]?.mrp,
                    singeProductData?.mrp?.[0]?.[
                      `${selectedColor}-${selectedSize}`
                    ]?.offerPrice
                  )}{" "}
                  ) %
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#00A100",
                  }}
                >
                  Save ₹{" "}
                  {singeProductData?.mrp?.[0]?.[
                    `${selectedColor}-${selectedSize}`
                  ]?.mrp -
                    singeProductData?.mrp?.[0]?.[
                      `${selectedColor}-${selectedSize}`
                    ]?.offerPrice}
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    Rating
                  </Typography>
                  <ProductRating
                    value={(
                      (averageQuality +
                        averageValueForMoney +
                        averagePerformence) /
                      3
                    ).toFixed(1)}
                    readOnly
                  />
                </Box>
                <Typography sx={{ fontSize: "14px" }}>
                  {activeReviews.length} Ratings & Reviews
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ mt: 3 }} />
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <Typography
                sx={{ fontSize: "16px", fontWeight: "550", color: "gray" }}
              >
                Color:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  flexWrap: "wrap",
                }}
              >
                {singeProductData?.Colors?.map((item, index) => {
                  return colorAttributes.map((color) => {
                    if (item === color.name) {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "2px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleColorClick(item)}
                        >
                          <Tooltip title={item} arrow>
                            <img
                              src={color.image}
                              alt=""
                              width="22px"
                              style={{
                                borderRadius: "50px",
                                border:
                                  selectedColor === item
                                    ? "2px solid black"
                                    : "1px solid #ddd",
                                padding: "2px",

                                objectFit: "contain",
                              }}
                            />
                          </Tooltip>
                        </Box>
                      );
                    }
                    return null;
                  });
                })}
              </Box>

              <Box>
                <Typography
                  sx={{ fontSize: "15px", fontWeight: "400", color: "gray" }}
                >
                  Quantity :{" "}
                </Typography>
                <Box sx={{ border: "2px solid #ddd", display: "flex" }}>
                  <Box sx={{ borderRight: "2px solid #ddd" }}>
                    <Button
                      variant="outined"
                      disableRipple
                      onClick={handleDecrement}
                    >
                      -
                    </Button>
                  </Box>
                  <Box sx={{ borderRight: "2px solid #ddd" }}>
                    <Button
                      variant="outined"
                      disableElevation
                      disableRipple
                      disableFocusRipple
                    >
                      {counter}
                    </Button>
                  </Box>
                  <Button
                    variant="outined"
                    disableRipple
                    onClick={handleIncrement}
                  >
                    +
                  </Button>
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    border: "1px solid #1ca9ef",
                    width: "120px",
                    mt: 1,
                    p: 0.2,
                    textAlign: "center",
                    color: "#1ca9ef",
                  }}
                >
                  In stock
                </Typography>
              </Box>
            </Box>
            {singeProductData?.Sizes?.length > 0 && (
              <Box sx={{ display: "flex", mt: 2, gap: "20px" }}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "550", color: "gray" }}
                >
                  Size:
                </Typography>
                <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                  {singeProductData.Sizes.map((item) => (
                    <Box
                      key={item}
                      sx={{
                        border:
                          selectedSize === item
                            ? "2px solid #000"
                            : "1px solid #ddd",
                        pl: 2,
                        pr: 2,
                        mb: 2,
                        cursor: "pointer",
                      }}
                      onClick={() => handleSizeSelect(item)}
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                        }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            <Divider sx={{ mt: 2 }} />
            <Box sx={{ mt: 2 }}>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <CheckCircleIcon sx={{ color: "#cc0000", fontSize: "30px" }} />
                <Box>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Fulfilled By{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#0099cc",
                      }}
                    >
                      SkillShop
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider sx={{ mt: 2 }} />
            <Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  gap: "30px",
                }}
              >
                <Button
                  variant="contained"
                  style={{ width: "180px", height: "50px", fontWeight: "bold" }}
                  disableElevation
                  onClick={() =>
                    navigate("/Checkout", {
                      state: {
                        cartItems: {
                          cartItems: [
                            {
                              productId: singeProductData,
                              selectedColor,
                              selectedSize,
                              quantity: counter,
                            },
                          ],
                        },
                      },
                    })
                  }
                >
                  <ShoppingCartOutlinedIcon sx={{ mr: 1 }} />
                  BUY NOW
                </Button>
                <Button
                  variant="contained"
                  style={{
                    width: "180px",
                    height: "50px",
                    backgroundColor: "#232f3e",
                    fontWeight: "bold",
                  }}
                  disableElevation
                  onClick={() => handleAddToCart(singeProductData)}
                >
                  <ShoppingBagOutlinedIcon sx={{ mr: 1 }} />
                  ADD TO CART
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    width: "180px",
                    height: "50px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  disableElevation
                  onClick={() => handleAddToWishList(singeProductData)}
                >
                  <FavoriteBorderOutlinedIcon sx={{ mr: 1 }} />
                  WISHLIST
                </Button>
              </Box>
              <Box>
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography sx={{ fontSize: "16px" }}>Delivary : </Typography>
                  <TextField size="small" placeholder="Enter Pincode" />
                </Box>
              </Box>
            </Box>
            <Divider sx={{ mt: 2 }} />
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Return & Replacement Policy
              </Typography>
              <Box sx={{ display: "flex", alignItems: "flex-end", gap: "6px" }}>
                <Typography
                  sx={{
                    fontSize: "17px",
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  {singeProductData?.returnPolicy}
                </Typography>
                <CustomWidthTooltip title={policyDescription} arrow>
                  <QuestionMarkOutlinedIcon
                    sx={{
                      background: "#0099cc",
                      borderRadius: "50%",
                      p: 0.1,
                      cursor: "pointer",
                    }}
                  />
                </CustomWidthTooltip>
              </Box>
            </Box>
            <Divider sx={{ mt: 2 }} />
            <Box
              sx={{ display: "flex", alignItems: "center", gap: "20px", mt: 2 }}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                Sold By :
              </Typography>
              <Typography
                className="createdBy"
                sx={{ fontSize: "16px", color: "#d60000", cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    `/sellerProductList/${singeProductData?.createdBy?._id}`
                  )
                }
              >
                {singeProductData?.createdBy?.companyName}
              </Typography>
            </Box>
            <Typography sx={{ mt: 1, textAlign: "end" }}>
              <Button
                variant="contained"
                style={{
                  width: "180px",
                  height: "50px",
                  backgroundColor: "#232f3e",
                  //   fontWeight: "bold",
                }}
                disableElevation
              >
                Report Product
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ProductDetailsData;
