import React from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { QuestionByBuyer, QuestionsForParticularProduct } from "../../axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";

const QuestionsAboutProduct = ({ sellerId }) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [Question, setQuestion] = useState("");
  const { user } = useSelector((state) => state.user);
  const [data, setData] = useState([]);

  const { id } = useParams();

  const handleSubmitQuestion = async () => {
    try {
      const res = await QuestionByBuyer(user._id, id, Question, sellerId);
      if (res) {
        toast.success(res, {
          position: "top-right",
        });
        setQuestion("");
      }
    } catch (error) {
      toast.error("Error occured");
    }
  };

  const hadleGetAllQuestions = async () => {
    try {
      const res = await QuestionsForParticularProduct(id);
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const convertToIST = (utcTime) => {
    const utcDate = new Date(utcTime);
    const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds

    const istDate = new Date(utcDate.getTime() + istOffset);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const istTimeString = istDate.toLocaleDateString("en-IN", options);

    return istTimeString;
  };
  useEffect(() => {
    hadleGetAllQuestions();
  }, [id]);
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center" gutterBottom>
            QUESTIONS ABOUT THIS PRODUCT
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              border: "2px solid #00a5d7",
              width: "60px",
              margin: "0 auto",
            }}
          ></div>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: isMediumScreen && "10px",
            }}
          >
            <TextField
              placeholder="Eg: What is the material of the Product?"
              value={Question}
              onChange={(e) => setQuestion(e.target.value)}
              sx={{ width: "600px" }}
              size="small"
            />
            <Button
              style={{ backgroundColor: "#cc0000", color: "#fff" }}
              onClick={handleSubmitQuestion}
            >
              ASK QUESTION
            </Button>
          </Box>
          {data.length > 0 && (
            <Box sx={{ background: "#eee", mt: 3, p: 2 }}>
              {data.map((item, index) => {
                if (
                  item.Answer !== "" &&
                  !item.QuestionDeactivated &&
                  !item.AnswerDeactivated
                ) {
                  return (
                    <Box key={index}>
                      <Box sx={{ display: "flex", p: 1 }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                            minWidth: "100px",
                          }}
                        >
                          Question :
                        </Typography>
                        <Typography>{item.Question}</Typography>
                      </Box>
                      <Box sx={{ display: "flex", p: 1 }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                            minWidth: "100px",
                          }}
                        >
                          Answer :
                        </Typography>
                        <Typography>{item.Answer}</Typography>
                      </Box>
                      <Box sx={{ display: "flex", pt: 0.5, pl: 1, pb: 1 }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                            minWidth: "100px",
                          }}
                        ></Typography>
                        <Typography>
                          By{" "}
                          <span style={{ color: "#0099cc" }}>
                            {item.Buyer.name}
                          </span>{" "}
                          on{" "}
                          <span style={{ color: "#0099cc" }}>
                            {convertToIST(item.createdAt)}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  );
                }
              })}
            </Box>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default QuestionsAboutProduct;
