import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";

const FeaturedCategories = ({ data }) => {
  console.log(data?.featuredcategoriesdatas);
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "20px" }}>
      <Box
        sx={{
          ml: 3,
          mb: 2,
          mr: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          {data && data?.featuredcategoriesdatas?.[0]?.Heading}
        </Typography>
        <Button
          variant="contained"
          sx={{ fontWeight: "bold" }}
          onClick={() =>
            navigate(data?.featuredcategoriesdatas?.[0]?.linktoButton)
          }
        >
          {data && data?.featuredcategoriesdatas?.[0]?.ButtonText}
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ px: 3 }}>
        {data?.featuredcategoriesimages
          ?.sort((a, b) => a.sortingOrder - b.sortingOrder)
          .map((item, index) => {
            if (item.visible === true) {
              return (
                <Grid item xs={6} sm={3} md={6} lg={2} key={index}>
                  <Card sx={{ height: "100%", width: "100%" }}>
                    <img
                      src={item.image}
                      alt=""
                      className="cardimg"
                      height="100%"
                      width="100%"
                      onClick={() => navigate(item.url)}
                    />
                  </Card>
                </Grid>
              );
            }
          })}
      </Grid>
    </div>
  );
};

export default FeaturedCategories;
